import "./index.scss";

import React, { useEffect, useRef } from "react";

const GaugeChart = ({
  titleText,
  sections,
  valueSections,
  currentValue,
  valueUnit,
  valueColor,
  styles
}) => {
  const { width, height } = styles;
  const chartCanvasRef = useRef();

  const drawArc = (ctx, x, y, radiusX, radiusY, start, end, strokeWidth, color) => {
    const rotation = 0;

    ctx.beginPath();

    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = color;

    ctx.ellipse(x, y, radiusX, radiusY, rotation, start, end, false);

    ctx.stroke();
  };

  const drawGauge = (ctx, sections, arcOptions) => {
    const { strokeWidth = 2, radiusX = 50, radiusY = 60 } = arcOptions || {};

    const angle = Math.PI;

    let prevAngle = -1 * Math.PI;

    sections.forEach((sectionObj) => {
      const { percent, color } = sectionObj;

      const currentAngle = angle * percent / 100;

      drawArc(
        ctx,
        ctx.canvas.width / 2,
        ctx.canvas.height * 0.9,
        radiusX,
        radiusY,
        prevAngle,
        prevAngle + currentAngle,
        strokeWidth,
        color
      );

      prevAngle = prevAngle + currentAngle;
    });
  };

  useEffect(() => {
    const currentCanvas = chartCanvasRef.current;

    if (!currentCanvas) {
      return;
    }

    const ctx = currentCanvas.getContext('2d');

    drawGauge(ctx, sections, { radiusX: 100, radiusY: 110, strokeWidth: 3 });
    drawGauge(ctx, valueSections, { radiusX: 85, radiusY: 95, strokeWidth: 17 });
  }, [chartCanvasRef.current, sections, valueSections]);

  return (
    <div className="chart chart-gauge">
      <div className="title-wrap">
        {titleText}
      </div>
      <canvas ref={chartCanvasRef} width={width} height={height} />

      <div className="chart-value" style={{ color: valueColor }}>
        <span style={{ fontSize: "28px" }}>{currentValue.toFixed(2)} </span>
        <span style={{ fontSize: "24px" }}>{valueUnit || ""}</span>
      </div>
    </div>
  );
};

export default GaugeChart;
