import React from "react";
import "./index.scss";

const Grid = ({
  items,
  className,
  renderItem,
}) => {
  const gridItems = items.map((item, idx) => {
    return (
      <React.Fragment key={idx.toString()}>
        {renderItem(item, idx)}
      </React.Fragment>
    );
  });

  return (
    <ul className={`grid-container ${className}`}>
      {gridItems}
    </ul>
  );
};

export default Grid;
