import "./index.scss";

import { useState } from "react";
import DefaultLayout from "../../components/layouts/default";
import BlockUi from "react-block-ui";
import { Alert, Button } from "react-bootstrap";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import CrossIcon from "../../assets/icons/crossIcon.svg";
import RightTick from "../../assets/icons/rightTick.svg";
import BreadCrumbs from "../../components/breadcrumbs";
import { titleCase } from "../../utils";
import { useEffect } from "react";
import List from "../../components/list";
import { useSelector } from "react-redux";
import { fetchUnassignedDevicesAction, saveDeviceAction } from "../../actions/devices/api";
import { fetchOrgsAction, fetchSitesAction, saveSystemAction } from "../../actions";
import AddEditSystemModal from "../system-list/add-edit-system-modal";

const DEFAULT_NEW_SYSTEM = {
  name: "",
  brand: "",
  modelNumber: "",
  latitude: 0,
  longitude: 0,
  files: [],
  installedOn: "",
  size: 7.5,
};

const transformDevices = (items) => {
  return items.map((item) => {
    return {
      ...item,
      status: titleCase(item.status),
      health: item.status === "online" ? 1 : 0,
    };
  });
};

const UnassignedDevices = () => {
  const [unassignedDevices, setUnassignedDevices] = useState([]);
  const [isUnassignedDevicesReqLoading, setIsUnassignedDevicesReqLoading] = useState(false);
  const [newSystem, setNewSystem] = useState(DEFAULT_NEW_SYSTEM);
  const [addSystemFormErrors, setAddSystemFormErrors] = useState([]);
  const [isAddSystemFormVisible, setIsAddSystemFormVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [orgs, setOrgs] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isOrgsReqLoading, setIsOrgsReqLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [isSystemSaveReqLoading, setIsSystemSaveReqLoading] = useState(false);
  const [isDeviceSaveReqLoading, setIsDeviceSaveReqLoading] = useState(false);
  const [isSiteListLoading, setIsSiteListLoading] = useState(false);
  const [assignedDeviceId, setAssignedDeviceId] = useState(null);

  const userState = useSelector((state) => state.user);
  const { data: userStateData } = userState;
  const currentUserOrgId = userStateData.organizationId;

  const fetchUnassignedDevices = () => {
    const onSuccess = resp => {
      setErrorMsg("");
      setUnassignedDevices(transformDevices(resp));
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch the devices");
    };

    const onFinally = () => {
      setIsUnassignedDevicesReqLoading(false);
    };

    fetchUnassignedDevicesAction(onSuccess, onError, onFinally);
  };

  const saveDevice = (systemId, payload) => {
    setIsDeviceSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to save device");
    };

    const onFinally = () => {
      setIsDeviceSaveReqLoading(false);
    };

    saveDeviceAction(systemId, payload, onSuccess, onError, onFinally);
  };

  const saveSystem = () => {
    setIsSystemSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const idx = unassignedDevices.findIndex(it => it.deviceId === newSystem.deviceId);
      const updatedDevices = [
        ...unassignedDevices.slice(0, idx),
        ...unassignedDevices.slice(idx + 1),
      ];

      const { id, name } = resp;
      const devicePayload = {
        name,
        unitType: 0,
        externalId: assignedDeviceId,
      };

      saveDevice(id, devicePayload);

      setUnassignedDevices(updatedDevices);
      setNewSystem(DEFAULT_NEW_SYSTEM);
      setIsAddSystemFormVisible(false);
      setAssignedDeviceId(null);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to save system");
    };

    const onFinally = () => {
      setIsSystemSaveReqLoading(false);
    };

    saveSystemAction(selectedSiteId, { ...newSystem }, onSuccess, onError, onFinally);
  };

  const fetchOrganizations = () => {
    setIsOrgsReqLoading(true);

    const onSuccess = resp => {
      setErrorMsg("");
      setOrgs(resp);
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch organizations");
    };

    const onFinally = () => {
      setIsOrgsReqLoading(false);
    };

    fetchOrgsAction(onSuccess, onError, onFinally);
  };

  const fetchSites = () => {
    setIsSiteListLoading(true);

    const orgId = selectedOrgId ? selectedOrgId : currentUserOrgId;

    const onSuccess = resp => {
      setErrorMsg("");
      setSites(resp);
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch sites");
    };

    const onFinally = () => {
      setIsSiteListLoading(false);
    };

    fetchSitesAction(orgId, onSuccess, onError, onFinally);
  };

  useEffect(() => {
    fetchUnassignedDevices();
  }, []);

  useEffect(() => {
    if (!selectedOrgId) {
      return;
    }

    fetchSites();
  }, [selectedOrgId]);

  const handleUpdateNewSystem = (field, val) => {
    setNewSystem({ ...newSystem, [field]: val });
  };

  const handleAssignDevice = (deviceId) => {
    setAssignedDeviceId(deviceId);
    setNewSystem({ ...DEFAULT_NEW_SYSTEM });
    setIsAddSystemFormVisible(true);
    fetchOrganizations();
  };

  const handleRenderItem = (item, columnId) => {
    const { deviceId } = item;

    if (columnId === "actions") {
      return (
        <div className="actions-column" key={`${deviceId}-actions`}>
          <Button onClick={() => handleAssignDevice(deviceId)}>
            Assign +
          </Button>
        </div>
      );
    }

    if (columnId === "health") {
      return (
        <div
          className={`list-body-cell health-column ${item[columnId] === 1 ? "healthy" : "unhealthy"}`}
          key={`${deviceId}-${columnId}`}>
          {item[columnId] === 0 ? <img src={CrossIcon} /> : <img src={RightTick} />}
        </div>
      );
    }

    return (
      <div className={`list-body-cell ${columnId}-column ${columnId === "status" ? `${item[columnId]}` : ""}`} key={`${deviceId}-${columnId}`}>
        {item[columnId]}
      </div>
    );
  };

  const handleAddEditSystemModalClose = () => {
    setAssignedDeviceId(null);
    setAddSystemFormErrors([]);
    setNewSystem(DEFAULT_NEW_SYSTEM);
    setIsAddSystemFormVisible(false);
  };

  const unassignedDeviceListColumns = [
    {
      id: "deviceId",
      label: "Device ID",
      canSort: false,
    },
    {
      id: "status",
      label: "Status",
      canSort: false,
    },
    {
      id: "health",
      label: "Health",
      canSort: false,
    },
    {
      id: "actions",
      label: "",
      canSort: false,
    }
  ];
  const breadCrumbOptions = [
    {
      id: 1,
      title: "Unassigned Devices"
    },
  ];
  const isLoading = (
    isUnassignedDevicesReqLoading ||
    isSystemSaveReqLoading ||
    isDeviceSaveReqLoading ||
    isOrgsReqLoading ||
    isSiteListLoading
  );

  return (
    <DefaultLayout pageTitle={"Unassigned Devices"}>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
        <div className="main-content">
          <div className="unassigned-devices-header">
            <BreadCrumbs homeIcon={HomeIcon} progressIcon={RightArrow} options={breadCrumbOptions}/>
          </div>

          <div className="unassigned-device-list">
            <List
              canSelectMultipleRows={false}
              columns={unassignedDeviceListColumns}
              items={unassignedDevices}
              renderItem={handleRenderItem} />
          </div>
        </div>

        <AddEditSystemModal
          isVisible={isAddSystemFormVisible}
          newSystem={newSystem}
          addSystemFormErrors={addSystemFormErrors}
          orgs={orgs}
          sites={sites}
          selectedOrgId={selectedOrgId}
          selectedSiteId={selectedSiteId}
          onChangeOrgId={setSelectedOrgId}
          onChangeSiteId={setSelectedSiteId}
          onClose={handleAddEditSystemModalClose}
          onSaveSystem={saveSystem}
          onUpdateNewSystem={handleUpdateNewSystem}
          onSetNewSystem={setNewSystem} />
      </BlockUi>
    </DefaultLayout>
  );
};

export default UnassignedDevices;
