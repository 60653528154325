import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import GaugeChart from "../../components/charts/gauge";
import { getSelectedDatesKey, processData } from "../../chart-utils.js";
import BlockUi from "react-block-ui";

const RSSI_GAUGE_RANGES = [
  {
    min: -100,
    max: -80,
  },
  {
    min: -80,
    max: 0,
  },
];

const ENCL_HUMIDITY_GAUGE_RANGES = [
  {
    min: 0,
    max: 80,
  },
  {
    min: 80,
    max: 100,
  },
];

const ENCL_TEMPERATURE_GAUGE_RANGES = [
  {
    min: -4,
    max: 32,
  },
  {
    min: 32,
    max: 104,
  },
  {
    min: 104,
    max: 140,
  },
  {
    min: 140,
    max: 158,
  }
];

const RSSI_GAUGE_CHART_COLORS = ["#de425b", "#488f31"];
const ENCL_HUMIDITY_GAUGE_CHART_COLORS = ["#488f31", "#de425b"];
const ENCL_TEMPERATURE_GAUGE_CHART_COLORS = ["#003f5c", "#488f31", "#ffa600", "#de425b"];

const GaugeCharts = ({ selectedDates, measureNameGroup, visibleMetricNames, onFetchMetrics }) => {
  const timeHistoryState = useSelector(state => state.timeHistory);
  const { data: deviceTimeHistory, loadingIds } = timeHistoryState;

  const isLoading = loadingIds.indexOf(measureNameGroup.id) !== -1;

  const selectedDatesKey = getSelectedDatesKey(selectedDates);
  const { timestamps = [], metrics = {} } = useMemo(
    () => (deviceTimeHistory[selectedDatesKey] || []).find(it => it.id === "gauge")?.results || {},
    [selectedDates, deviceTimeHistory]
  );
  const rssi = useMemo(() => processData(timestamps, metrics, "rssi", "int", false), [metrics]);
  const enclosureHumidity = useMemo(() => processData(timestamps, metrics, "Enclosure_Humidity", "float", false), [metrics]);
  const enclosureTemperature = useMemo(() => processData(timestamps, metrics, "Enclosure_Temp", "float", false), [metrics]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const selectedDatesKey = getSelectedDatesKey(selectedDates);

    if (!deviceTimeHistory[selectedDatesKey] || !deviceTimeHistory[selectedDatesKey].find(it => it.id === "gauge")) {
      onFetchMetrics(measureNameGroup);
    }
  }, [selectedDates, deviceTimeHistory]);

  const rssiChartMaxValue = 100;
  const rssiValuePercent = -1 * rssi / rssiChartMaxValue * 100;
  const rssiColor = RSSI_GAUGE_CHART_COLORS[
    RSSI_GAUGE_RANGES.findIndex(
      range => rssi >= range.min && rssi <= range.max
    )
  ];

  const rssiGaugeChartSections = RSSI_GAUGE_RANGES.reduce((sections, range, idx) => {
    return [
      ...sections,
      {
        percent: (range.max - range.min) / rssiChartMaxValue * 100,
        color: RSSI_GAUGE_CHART_COLORS[idx]
      }
    ];
  }, []);

  const rssiValueSections = [
    {
      percent: rssiValuePercent,
      color: rssiColor
    },
    {
      percent: 100 - rssiValuePercent,
      color: "#2021241A"
    }
  ];

  const enclosureHumidityChartMaxValue = ENCL_HUMIDITY_GAUGE_RANGES[ENCL_HUMIDITY_GAUGE_RANGES.length - 1].max;
  const enclosureHumidityValuePercent = enclosureHumidity / enclosureHumidityChartMaxValue * 100;
  const enclosureHumidityColor = ENCL_HUMIDITY_GAUGE_CHART_COLORS[
    ENCL_HUMIDITY_GAUGE_RANGES.findIndex(
      range => enclosureHumidity >= range.min && enclosureHumidity <= range.max
    )
  ];

  const enclosureHumidityGaugeChartSections = ENCL_HUMIDITY_GAUGE_RANGES.reduce((sections, range, idx) => {
    return [
      ...sections,
      {
        percent: (range.max - range.min) / enclosureHumidityChartMaxValue * 100,
        color: ENCL_HUMIDITY_GAUGE_CHART_COLORS[idx]
      }
    ];
  }, []);

  const enclosureHumidityValueSections = [
    {
      percent: enclosureHumidityValuePercent,
      color: enclosureHumidityColor
    },
    {
      percent: 100 - enclosureHumidityValuePercent,
      color: "#2021241A"
    }
  ];

  const enclosureTemperatureChartMaxValue = 162;
  const enclosureTemperatureValuePercent = enclosureTemperature / enclosureTemperatureChartMaxValue * 100;
  const enclosureTemperatureColor = ENCL_TEMPERATURE_GAUGE_CHART_COLORS[
    ENCL_TEMPERATURE_GAUGE_RANGES.findIndex(
      range => enclosureTemperature >= range.min && enclosureTemperature <= range.max
    )
  ];

  const enclosureTemperatureGaugeChartSections = ENCL_TEMPERATURE_GAUGE_RANGES.reduce((sections, range, idx) => {
    return [
      ...sections,
      {
        percent: (range.max - range.min) / enclosureTemperatureChartMaxValue * 100,
        color: ENCL_TEMPERATURE_GAUGE_CHART_COLORS[idx]
      }
    ];
  }, []);

  const enclosureTemperatureValueSections = [
    {
      percent: enclosureTemperatureValuePercent,
      color: enclosureTemperatureColor
    },
    {
      percent: 100 - enclosureTemperatureValuePercent,
      color: "#2021241A"
    }
  ];

  return (
    <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
      <div className="chart-row-gauge" style={{ margin: "40px 0", minHeight: "170px" }}>
        {visibleMetricNames.indexOf("rssi") !== -1 && (
          <GaugeChart
            titleText={
              <span>
                WiFi Signal Strength
              </span>
            }
            sections={rssiGaugeChartSections}
            valueSections={rssiValueSections}
            currentValue={rssi}
            valueUnit={"dB"}
            valueColor={rssiColor}
            styles={{ width: "300px", height: "150px" }} />
        )}

        {visibleMetricNames.indexOf("Enclosure_Humidity") !== -1 && (
          <GaugeChart
            titleText={
              <span>
                Enclosure Humidity
              </span>
            }
            sections={enclosureHumidityGaugeChartSections}
            valueSections={enclosureHumidityValueSections}
            currentValue={enclosureHumidity}
            valueUnit={"%H"}
            valueColor={enclosureHumidityColor}
            styles={{ width: "300px", height: "150px" }} />
        )}

        {visibleMetricNames.indexOf("Enclosure_Temp") !== -1 && (
          <GaugeChart
            titleText={
              <span>
                Enclosure Temperature
              </span>
            }
            sections={enclosureTemperatureGaugeChartSections}
            valueSections={enclosureTemperatureValueSections}
            currentValue={enclosureTemperature}
            valueUnit={"\u00b0F"}
            valueColor={enclosureTemperatureColor}
            styles={{ width: "300px", height: "150px" }} />
        )}
      </div>
    </BlockUi>
  );
};

export default React.memo(GaugeCharts);
