import { useNavigate } from "react-router-dom";
import "./index.scss";

import { Image } from "react-bootstrap";

const BreadCrumbs = ({homeIcon, progressIcon, options}) => {
    const navigate = useNavigate();

    const handleNavigateToHome = () => {
        navigate("/");
    };

    const handleCrumbClick = (option) => {
        if (option.onClick) {
            option.onClick();
        }
    };

    return (
        <div className="breadcrumb-wrapper">
            <div className="breadcrumb-container">
                <Image src={homeIcon} alt="Home Icon" className="breadcrumb-home" onClick={handleNavigateToHome} />
                {options.map((option) => {
                    const isLink = option.id !== options.length;
                    const breadcrumbTextClassName = `breadcrumb-text ${isLink ? 'breadcrumb-link' : ''}`;

                    return (
                        <div key={option.id} className="breadcrumb-text-wrap">
                            <Image src={progressIcon} alt="progress Icon" className="breadcrumb-progress" />

                            <div
                                className={breadcrumbTextClassName}
                                onClick={() => handleCrumbClick(option)}>
                                {option.title}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default BreadCrumbs;