import "./header-nav-item.scss";

import React from "react";

const HeaderNavItem = ({ id, label, icon, onClick }) => {
  return (
    <div className="header-nav-item" onClick={() => onClick(id)}>
      <img className="icon" src={icon} alt="logo" />
      <span className="header-nav-item-label">{label}</span>
    </div>
  );
};

export default HeaderNavItem;
