import { Modal } from "react-bootstrap";
import GoogleMap from "../maps";
import { DEFAULT_MAP_ZOOM } from "../../constants";
import MapMarker from "../map-marker";

const MapLocationModal = ({ isVisible, mapCoords, title, onClose }) => {
  return (
    <Modal
      centered
      show={isVisible}
      className="map-location-modal">
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="map-container">
          <div className="map">
            <GoogleMap
              lat={mapCoords.latitude || 0}
              lng={mapCoords.longitude || 0 }
              zoom={DEFAULT_MAP_ZOOM}
              MapMarkerComponent={MapMarker}>
            </GoogleMap>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MapLocationModal;
