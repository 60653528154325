import { Modal } from "react-bootstrap";

const AddSensorTypeModal = ({
  title,
  isVisible,
  sensorType,
  onUpdateSensorType,
  onSaveSensorType,
  onClose,
}) => {
  return (
    <Modal centered show={isVisible} className="add-sensor-type-modal">
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="add-sensor-type-modal-wrapper">
          <div className="input">
            <div className="name-wrapper">
              <div className="text-wrapper">
                Name <span style={{color:"#BF2137"}}>*</span>
              </div>

              <input
                type="text"
                placeholder="Name"
                className="sensor-type-name-input"
                value={sensorType.name}
                onChange={(event) => onUpdateSensorType("name", event.target.value)} />
            </div>
          </div>

          <div className="bottom-controller">
            <div className="cancel-modal" onClick={onClose}>
              Cancel
            </div>

            <div className="submit-modal" onClick={() => onSaveSensorType()}>
              Save
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddSensorTypeModal;
