import "./index.scss";

import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { sortItems, transformItemFiles } from "../../utils";
import DefaultLayout from "../../components/layouts/default";
import { useSearchParams } from "react-router-dom";
import BlockUi from "react-block-ui";
import { useSelector } from "react-redux";
import { ADMIN, ORG_ADMIN, SUCCESS } from "../../constants";
import ConfirmationPopup from "../../components/popup/confirmation";
import Grid from "../../components/grid";
import SiteGridItem from "./site-grid-item";
import AddEditSiteModal from "./add-edit-site-modal";
import OrgsDropdown from "./orgs-dropdown";
import UnassignedDevicesAlert from "./unassigned-devices-alert";
import MapLocationModal from "../../components/map-location-modal";
import { deleteSiteAction, fetchSitesAction, saveSiteAction } from "../../actions";

const DEFAULT_NEW_SITE = {
    name: "",
    latitude: 0,
    longitude: 0,
    address: "",
    files: [],
    organizationId: null,
};

const Dashboard = () => {
    const [searchParams, _] = useSearchParams();

    const userState = useSelector(state => state.user);
    const { type: userStateType, data: userStateData } = userState;
    const isUserAdmin = userStateData.role === ADMIN;
    const isOrgAdmin = userStateData.role === ORG_ADMIN;
    const isUserReqSuccess = userStateType === SUCCESS;
    const currentUserOrgId = userStateData.organizationId;

    const [sites, setSites] = useState([]);
    const [visibleSites, setVisibleSites] = useState([]);
    const [isSiteListLoading, setIsSiteListLoading] = useState(false);
    const [isSiteSaveReqLoading, setIsSiteSaveReqLoading] = useState(false);
    const [isDeleteSiteReqLoading, setIsDeleteSiteReqLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedOrgId, setSelectedOrgId] = useState(null);
    const [isAddSiteFormVisible, setIsAddSiteFormVisible] = useState(false);
    const [addSiteFormErrors, setAddSiteFormErrors] = useState([]);
    const [newSite, setNewSite] = useState(DEFAULT_NEW_SITE);
    const [tempSite, setTempSite] = useState(DEFAULT_NEW_SITE);
    const [isConfirmDeleteSiteVisible, setIsConfirmDeleteSiteVisible] = useState(false);
    const [mapCoords, setMapCoords] = useState({});
    const [isSiteMapOpen, setIsSiteMapOpen] = useState(false);

    const fetchSites = () => {
        setIsSiteListLoading(true);

        const orgId = selectedOrgId ? selectedOrgId : currentUserOrgId;

        const onSuccess = resp => {
            const transformedSites = resp.map(it => transformItemFiles(it));
            setErrorMsg("");
            setSites(transformedSites);
            setVisibleSites(sortItems(transformedSites));
        };

        const onError = err => {
            console.log(err);
            setErrorMsg("Failed to fetch sites");
        };

        const onFinally = () => {
            setIsSiteListLoading(false);
        };

        fetchSitesAction(orgId, onSuccess, onError, onFinally);
    };

    const saveSite = (siteObj) => {
        setIsSiteSaveReqLoading(true);

        const onSuccess = resp => {
            setErrorMsg("");

            const transformedSite = transformItemFiles(resp);

            if (!siteObj.id && siteObj.organizationId === selectedOrgId) {
                setSites([transformedSite, ...sites]);
                setVisibleSites(sortItems([transformedSite, ...sites]));
            } else if (siteObj.id) {
                const siteIdx = sites.findIndex(it => it.id === siteObj.id);
                let updatedSites = [...sites];

                if (siteObj.organizationId === selectedOrgId) {
                    updatedSites = [
                        ...sites.slice(0, siteIdx),
                        { ...sites[siteIdx], ...transformedSite },
                        ...sites.slice(siteIdx + 1)
                    ];
                } else {
                    updatedSites = [
                        ...sites.slice(0, siteIdx),
                        ...sites.slice(siteIdx + 1)
                    ];
                }

                setSites(updatedSites);
                setVisibleSites(sortItems(updatedSites));
            }

            setIsAddSiteFormVisible(false);
            setNewSite(DEFAULT_NEW_SITE);
            setAddSiteFormErrors([]);
        };

        const onError = err => {
            console.log(err);
            setAddSiteFormErrors([err.data.message]);
        };

        const onFinally = () => {
            setIsSiteSaveReqLoading(false);
        };

        saveSiteAction(
            siteObj,
            onSuccess,
            onError,
            onFinally
        );
    };

    const deleteSite = () => {
        setIsDeleteSiteReqLoading(true);

        const siteIds = [tempSite.id];

        const onSuccess = () => {
            setErrorMsg("");
    
            const updatedSites = sites.filter(it => siteIds.indexOf(it.id) === -1);
    
            setSites(updatedSites);
            setVisibleSites(sortItems(updatedSites));
        };

        const onError = err => {
            console.log(err);
            setErrorMsg(["Failed to delete site"]);
        };

        const onFinally = () => {
            setIsDeleteSiteReqLoading(false);
            setTempSite(DEFAULT_NEW_SITE);
        };

        deleteSiteAction(siteIds, onSuccess, onError, onFinally);
    };

    useEffect(() => {
        if (!isUserReqSuccess) {
            return;
        }

        if (!isUserAdmin) {
            fetchSites();
        }
    }, [isUserReqSuccess]);

    useEffect(() => {
        if (searchTerm) {
            setVisibleSites(sortItems(
                sites.filter(it => it.name.toLowerCase().includes(searchTerm.toLowerCase()))
            ));
        } else {
            setVisibleSites(sortItems(sites));
        }
    }, [searchTerm]);

    useEffect(() => {
        if (!selectedOrgId) {
            return;
        }

        fetchSites();
    }, [selectedOrgId]);

    useEffect(() => {
        const orgId = searchParams.get("org_id");
        setSelectedOrgId(parseInt(orgId, 10));
    }, [searchParams]);

    const handleSearch = (searchInput) => {
        setSearchTerm(searchInput);
    };

    const handleEditSite = (site) => {
        setNewSite({ ...site, organizationId: parseInt(site.organizationId, 10) });
        setIsAddSiteFormVisible(true);
    };

    const handleCloneSite = (site) => {
        const cloneSite = { ...site, name: `${site.name} (Clone)` };
        delete cloneSite.id;

        saveSite(cloneSite);
    };

    const handleDeleteSite = (site) => {
        setTempSite(site);
        setIsConfirmDeleteSiteVisible(true);
    };

    const handleOpenMap = (coords) => {
        setMapCoords(coords);
        setIsSiteMapOpen(true);
    };

    const handleRenderSiteGridItem = (site, index) => {
        return (
            <SiteGridItem
                idx={index}
                site={site}
                onCloneSite={handleCloneSite}
                onEditSite={handleEditSite}
                onDeleteSite={handleDeleteSite}
                onOpenMap={handleOpenMap} />
        );
    };

    const handleAddEditSiteModalClose = () => {
        setAddSiteFormErrors([]);
        setNewSite(DEFAULT_NEW_SITE);
        setIsAddSiteFormVisible(false);
    };

    const isLoading = (
        isSiteListLoading ||
        isDeleteSiteReqLoading ||
        isSiteSaveReqLoading
    );

    return (
        <DefaultLayout
            pageTitle={"All sites"}
            showSearchBar
            onSearch={(searchText) => handleSearch(searchText)}>

            {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}


            <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
                <div className="main-content">
                    <UnassignedDevicesAlert />

                    {(isUserAdmin || isOrgAdmin) && (
                        <div className="list-actions">
                            {isUserAdmin && (
                                <OrgsDropdown selectedOrgId={selectedOrgId} />
                            )}

                            <Button
                                className="add-site-btn"
                                variant="secondary"
                                onClick={() => {
                                    setNewSite({ ...newSite, organizationId: selectedOrgId });
                                    setIsAddSiteFormVisible(true);
                                }}>
                                New Site
                            </Button>
                        </div>
                    )}

                    <div className="site-list-wrap">
                        <div className="site-list">
                            {visibleSites.length === 0 && (
                                <div className="no-sites">
                                    No sites found
                                </div>
                            )}

                            {visibleSites.length > 0 && (
                                <Grid
                                    items={visibleSites}
                                    className={"site-grid"}
                                    renderItem={handleRenderSiteGridItem} />
                            )}
                        </div>
                    </div>

                    <ConfirmationPopup
                        show={isConfirmDeleteSiteVisible}
                        title={"Wait, wait, wait..."}
                        content={"This will permanently delete the site. Continue?"}
                        onNo={() => {
                            setIsConfirmDeleteSiteVisible(false);
                            setTempSite(DEFAULT_NEW_SITE);
                        }}
                        onYes={() => {
                            setIsConfirmDeleteSiteVisible(false);
                            deleteSite();
                        }} />

                    <AddEditSiteModal
                        isVisible={isAddSiteFormVisible}
                        newSite={newSite}
                        addSiteFormErrors={addSiteFormErrors}
                        onClose={handleAddEditSiteModalClose}
                        onUpdateNewSite={setNewSite}
                        onSaveSite={() => saveSite(newSite)} />

                    <MapLocationModal
                        title={"Site Location"}
                        isVisible={isSiteMapOpen}
                        mapCoords={mapCoords}
                        onClose={() => setIsSiteMapOpen(false)} />
                </div>
            </BlockUi>
        </DefaultLayout>
    );
};

export default Dashboard;
