import "./index.scss";

import { Alert, Button, Dropdown, Form, Image } from "react-bootstrap";
import DefaultLayout from "../../components/layouts/default";
import BlockUi from "react-block-ui";
import { useEffect, useRef, useState } from "react";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import DropDownIcon from "../../assets/icons/dropDownArrow.svg";
import List from "../../components/list";
import {
  fetchChannelsAction,
  fetchLoggerSensorConfigAction,
  fetchSensorTypesAction,
  saveLoggerSensorConfigAction,
  saveSensorTypeAction,
} from "../../actions";
import { useNavigate, useParams } from "react-router-dom";
import { sortItems } from "../../utils";
import AddSensorTypeModal from "./add-sensor-type-modal";
import useOutsideAlerter from "../../hooks/outside-alerter";

const NONE_DROPDOWN_OPTION = { id: null, name: "None" };

const DEFAULT_SENSOR_TYPE = {
  name: "",
};

const LoggerManagementDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const sensorTypeDropdownRef = useRef();
  const channelDropdownRef = useRef();

  const [errorMsg, setErrorMsg] = useState("");
  const [deviceSensors, setDeviceSensors] = useState([]);
  const [isDeviceSensorListLoading, setIsDeviceSensorListLoading] =
    useState(false);
  const [isSensorConfigSaveReqLoading, setIsSensorConfigSaveReqLoading] =
    useState(false);
  const [channels, setChannels] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [isAddSensorTypeFormVisible, setIsAddSensorTypeFormVisible] =
    useState(false);
  const [newSensorType, setNewSensorType] = useState(DEFAULT_SENSOR_TYPE);
  const [isSensorTypeSaveReqLoading, setIsSensorTypeSaveReqLoading] =
    useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isSensorTypeDropdownVisible, setIsSensorTypeDropdownVisible] =
    useState(false);
  const [isChannelDropdownVisible, setIsChannelDropdownVisible] =
    useState(false);
  const [tempDeviceSensors, setTempDeviceSensors] = useState([]);

  useOutsideAlerter(sensorTypeDropdownRef, (event) => {
    if (
      sensorTypeDropdownRef.current &&
      !sensorTypeDropdownRef.current.contains(event.target) &&
      isSensorTypeDropdownVisible
    ) {
      setIsSensorTypeDropdownVisible(false);
    }
  });

  useOutsideAlerter(channelDropdownRef, (event) => {
    if (
      channelDropdownRef.current &&
      !channelDropdownRef.current.contains(event.target) &&
      isChannelDropdownVisible
    ) {
      setIsChannelDropdownVisible(false);
    }
  });

  const fetchChannels = () => {
    if (channels.length) {
      return;
    }

    const onSuccess = (resp) => {
      setErrorMsg("");

      setChannels(resp);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch channels");
    };

    const onFinally = () => {};

    fetchChannelsAction(onSuccess, onError, onFinally);
  };

  const fetchSensorTypes = () => {
    if (sensorTypes.length) {
      return;
    }

    const onSuccess = (resp) => {
      setErrorMsg("");

      setSensorTypes(resp);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch sensor types");
    };

    const onFinally = () => {};

    fetchSensorTypesAction(onSuccess, onError, onFinally);
  };

  const fetchDeviceSensors = () => {
    setIsDeviceSensorListLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      setDeviceSensors(sortItems(resp, "id"));
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch sensor config");
    };

    const onFinally = () => {
      setIsDeviceSensorListLoading(false);
    };

    fetchLoggerSensorConfigAction(id, false, onSuccess, onError, onFinally);
  };

  const handleRenderItem = (item, columnId) => {
    const { id } = item;

    return (
      <div
        key={`${id}-${columnId}`}
        className={`list-body-cell ${columnId}-column`}
      >
        {item[columnId] || "-"}
      </div>
    );
  };

  const handleRenderEditModeItem = (item, columnId) => {
    const { id, sensorTypeId, channelId, sensorName } = item;

    if (columnId === "sensorTypeName" && !sensorName.startsWith("OPT")) {
      const sensorTypeDropdownItems = [
        NONE_DROPDOWN_OPTION,
        ...sensorTypes,
      ].map((sensorTypeOption) => {
        const isChecked = sensorTypeOption.id === sensorTypeId;

        return (
          <Dropdown.Item
            key={sensorTypeOption.id}
            onClick={() => {
              const tempSensorConfigIdx = tempDeviceSensors.findIndex(
                (it) => it.id === id
              );
              const tempSensorConfig = {
                ...tempDeviceSensors[tempSensorConfigIdx],
                sensorTypeName: sensorTypeOption.name,
                sensorTypeId: sensorTypeOption.id,
              };

              setTempDeviceSensors([
                ...tempDeviceSensors.slice(0, tempSensorConfigIdx),
                tempSensorConfig,
                ...tempDeviceSensors.slice(tempSensorConfigIdx + 1),
              ]);
            }}
          >
            <Form.Check
              checked={isChecked}
              type="checkbox"
              label={sensorTypeOption.name}
              readOnly
            />
          </Dropdown.Item>
        );
      });

      const selectedSensorType = sensorTypes.find(
        (it) => it.id === sensorTypeId
      );

      return (
        <div
          key={`${id}-${columnId}`}
          className={`list-body-cell ${columnId}-column`}
        >
          <Dropdown
            className="header-dropdown"
            onClick={() =>
              setIsSensorTypeDropdownVisible(!isSensorTypeDropdownVisible)
            }
            ref={sensorTypeDropdownRef}
          >
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <div className="dropdown-selected-option-text">
                {selectedSensorType ? selectedSensorType.name : "None"}
              </div>

              <Image
                src={DropDownIcon}
                style={{
                  transform: isSensorTypeDropdownVisible
                    ? "rotate(180deg)"
                    : "",
                }}
                width={20}
                height={10}
                alt="DropDown Icon"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>{sensorTypeDropdownItems}</Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }

    if (columnId === "channelName") {
      const channelDropdownItems = [NONE_DROPDOWN_OPTION, ...channels].map(
        (channelOption) => {
          const isChecked = channelOption.id === channelId;

          return (
            <Dropdown.Item
              key={channelOption.id}
              onClick={() => {
                const tempSensorConfigIdx = tempDeviceSensors.findIndex(
                  (it) => it.id === id
                );
                const tempSensorConfig = {
                  ...tempDeviceSensors[tempSensorConfigIdx],
                  channelName: channelOption.name,
                  channelId: channelOption.id,
                };

                setTempDeviceSensors([
                  ...tempDeviceSensors.slice(0, tempSensorConfigIdx),
                  tempSensorConfig,
                  ...tempDeviceSensors.slice(tempSensorConfigIdx + 1),
                ]);
              }}
            >
              <Form.Check
                checked={isChecked}
                type="checkbox"
                label={channelOption.name}
                readOnly
              />
            </Dropdown.Item>
          );
        }
      );

      const selectedChannel = channels.find((it) => it.id === channelId);

      return (
        <div
          key={`${id}-${columnId}`}
          className={`list-body-cell ${columnId}-column`}
        >
          <Dropdown
            className="header-dropdown"
            onClick={() =>
              setIsChannelDropdownVisible(!isChannelDropdownVisible)
            }
            ref={channelDropdownRef}
          >
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              <div className="dropdown-selected-option-text">
                {selectedChannel ? selectedChannel.name : "None"}
              </div>

              <Image
                src={DropDownIcon}
                style={{
                  transform: isChannelDropdownVisible ? "rotate(180deg)" : "",
                }}
                width={20}
                height={10}
                alt="DropDown Icon"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>{channelDropdownItems}</Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }

    return (
      <div
        key={`${id}-${columnId}`}
        className={`list-body-cell ${columnId}-column`}
      >
        {item[columnId] || "-"}
      </div>
    );
  };

  const handleSaveSensorConfig = () => {
    setIsSensorConfigSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
      setDeviceSensors(resp);
      setEditMode(false);
    };

    const onError = (err) => {
      console.error(err);
      setErrorMsg("Failed to save sensor config");
    };

    const onFinally = () => {
      setIsSensorConfigSaveReqLoading(false);
    };

    const transformedSensorConfigs = tempDeviceSensors.map((sensorConfig) => ({
      id: sensorConfig.id,
      channelId: parseInt(sensorConfig.channelId, 10),
      sensorTypeId: parseInt(sensorConfig.sensorTypeId, 10),
    }));
    saveLoggerSensorConfigAction(
      transformedSensorConfigs,
      onSuccess,
      onError,
      onFinally
    );
  };

  const handleNavigateToLoggerMgmt = () => {
    navigate("/logger_management");
  };

  const handleUpdateSensorType = (key, value) => {
    const updatedSensorType = {
      ...newSensorType,
      [key]: value,
    };

    setNewSensorType(updatedSensorType);
  };

  const handleSaveSensorType = () => {
    setIsSensorTypeSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
      setSensorTypes([...sensorTypes, resp]);
      setIsAddSensorTypeFormVisible(false);
    };

    const onError = (err) => {
      setErrorMsg("Failed to save sensor type");
    };

    const onFinally = () => {
      setIsSensorTypeSaveReqLoading(false);
      setNewSensorType(DEFAULT_SENSOR_TYPE);
    };

    saveSensorTypeAction(newSensorType, onSuccess, onError, onFinally);
  };

  const handleCloseSensorTypeModal = () => {
    setIsAddSensorTypeFormVisible(false);
    setNewSensorType(DEFAULT_SENSOR_TYPE);
  };

  useEffect(() => {
    fetchChannels();
    fetchSensorTypes();
    fetchDeviceSensors();
  }, []);

  const listColumns = [
    {
      id: "sensorName",
      label: "Sensor Name",
      canSort: false,
    },
    {
      id: "plugLetter",
      label: "Plug Letter",
      canSort: false,
    },
    {
      id: "pinNumber",
      label: "Pin Number",
      canSort: false,
    },
    {
      id: "sensorTypeName",
      label: "Sensor Type",
      canSort: false,
    },
    {
      id: "channelName",
      label: "Channel Name",
      canSort: false,
    },
  ];

  const isLoading =
    isDeviceSensorListLoading ||
    isSensorConfigSaveReqLoading ||
    isSensorTypeSaveReqLoading;

  const breadCrumbOptions = [
    {
      id: 1,
      title: "Logger Management",
      onClick: handleNavigateToLoggerMgmt,
    },
    {
      id: 2,
      title: deviceSensors.length ? deviceSensors[0].unitName : "...",
    },
  ];

  return (
    <DefaultLayout>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
        <div className="main-content">
          <div className="site-detail-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions}
            />

            <div className="site-detail-header-options">
              <Button
                className="edit-sensor-config-btn site-detail-header-btn"
                variant="secondary"
                onClick={() => {
                  if (!editMode) {
                    setEditMode(true);
                    setTempDeviceSensors(deviceSensors);
                  } else {
                    handleSaveSensorConfig();
                  }
                }}
              >
                {!editMode ? "Edit Config" : "Save Config"}
              </Button>

              {editMode && (
                <Button
                  className="cancel-edit-sensor-config-btn site-detail-header-btn"
                  variant="secondary"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              )}

              <Button
                className="add-sensor-type-btn site-detail-header-btn"
                variant="secondary"
                onClick={() => setIsAddSensorTypeFormVisible(true)}
              >
                New Sensor Type
              </Button>
            </div>
          </div>

          <div className="device-config-list-wrap">
            <List
              canSelectMultipleRows={false}
              columns={listColumns}
              items={!editMode ? deviceSensors : tempDeviceSensors}
              renderItem={
                editMode ? handleRenderEditModeItem : handleRenderItem
              }
            />
          </div>
        </div>

        <AddSensorTypeModal
          title={"Add sensor type"}
          isVisible={isAddSensorTypeFormVisible}
          sensorType={newSensorType}
          onUpdateSensorType={handleUpdateSensorType}
          onSaveSensorType={handleSaveSensorType}
          onClose={handleCloseSensorTypeModal}
        />
      </BlockUi>
    </DefaultLayout>
  );
};

export default LoggerManagementDetail;
