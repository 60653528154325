import React, { useEffect } from "react";

const useOutsideAlerter = (ref, callback, callbackParams = []) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event, ...callbackParams);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, callback, callbackParams]);
};

export default useOutsideAlerter;
