import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { getLineChartData, getLineChartOptions, getSelectedDatesKey, processData } from "../../chart-utils.js";
import { useSelector } from "react-redux";
import BlockUi from "react-block-ui";

const CompressorCharts = ({ selectedDates, measureNameGroup }) => {
  const timeHistoryState = useSelector(state => state.timeHistory);
  const { data: deviceTimeHistory, loadingIds } = timeHistoryState;

  const isLoading = loadingIds.indexOf(measureNameGroup.id) !== -1;

  const selectedDatesKey = getSelectedDatesKey(selectedDates);
  const { timestamps = [], metrics = {} } = useMemo(
    () => (deviceTimeHistory[selectedDatesKey] || []).find(it => it.id === "duty_cycle")?.results || {},
    [selectedDates, deviceTimeHistory]
  );

  const compressorDemandSeries = useMemo(
    () => processData(timestamps, metrics, "Demand_Signal", "int", true),
    [metrics]
  );

  return (
    <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
      <div className="chart-row">
        <div className="chart-line">
          <Line
            width={"100%"}
            height={"400px"}
            data={getLineChartData(["Compressor Demand"], [compressorDemandSeries])}
            options={getLineChartOptions("Compressor Demand (On/Off)", "", 0, 1, 1)} />
        </div>
      </div>
    </BlockUi>
  );
};

export default React.memo(CompressorCharts);
