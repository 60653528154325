import React from "react";
import { useSelector } from "react-redux";
import { getLineChartData, getLineChartOptions, getSelectedDatesKey } from "../../chart-utils.js";
import { Line } from "react-chartjs-2";
import { useEffect, useMemo } from "react";
import BlockUi from "react-block-ui";

const RunTimeChart = ({ selectedDates, measureNameGroup, visibleMetricNames, onFetchMetrics }) => {
    const timeHistoryState = useSelector(state => state.timeHistory);
    const { data: deviceTimeHistory, loadingIds } = timeHistoryState;

    const selectedDatesKey = getSelectedDatesKey(selectedDates);
    const { timestamps = [], metrics = {} } = useMemo(
        () => (
            deviceTimeHistory[selectedDatesKey] || []
        ).find(it => it.id === "duty_cycle")?.results || {},
    [selectedDates, deviceTimeHistory]);

    const isLoading = loadingIds.indexOf(measureNameGroup.id) !== -1;

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const selectedDatesKey = getSelectedDatesKey(selectedDates);
    
        if (!deviceTimeHistory[selectedDatesKey] || !deviceTimeHistory[selectedDatesKey].find(it => it.id === "duty_cycle")) {
          onFetchMetrics(measureNameGroup);
        }
    }, [selectedDates, deviceTimeHistory]);

    const demandSignalRunTimeSeries = useMemo(() => {
        return timestamps?.map(
            (timestamp, index) => [
                new Date(parseInt(timestamp)),
                (metrics["Demand_Signal_runTime"]?.[index] / 3600).toFixed(2)
            ]
        );
    }, [metrics]);
    const compressorCurrentRunTimeSeries = useMemo(() => {
        return timestamps?.map(
            (timestamp, index) => [
                new Date(parseInt(timestamp)),
                (metrics["Compressor_Current_runTime"]?.[index] / 3600).toFixed(2)
            ]
        );
    }, [metrics]);
    const compressorCurrent2RunTimeSeries = useMemo(() => {
        return timestamps?.map(
            (timestamp, index) => [
                new Date(parseInt(timestamp)),
                (metrics["Compressor_Current2_runTime"]?.[index] / 3600).toFixed(2)
            ]
        );
    }, [metrics]);

    const chartTitles = [];
    const chartSeries = [];

    if (visibleMetricNames.indexOf("Demand_Signal") !== -1) {
        chartTitles.push("Demand Signal");
        chartSeries.push(demandSignalRunTimeSeries);
    }

    if (visibleMetricNames.indexOf("Compressor_Current") !== -1) {
        chartTitles.push("Compressor Current");
        chartSeries.push(compressorCurrentRunTimeSeries);
    }

    if (visibleMetricNames.indexOf("Compressor_Current2") !== -1) {
        chartTitles.push("Compressor Current 2");
        chartSeries.push(compressorCurrent2RunTimeSeries);
    }

    return (
        <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
            <div className="chart-row">
                <Line
                    width={"100%"}
                    height={"400px"}
                    data={getLineChartData(chartTitles, chartSeries)}
                    options={getLineChartOptions("Run time (hours)", "h", 0, 24, 6)} />
            </div>
        </BlockUi>
    );
};

export default React.memo(RunTimeChart);
