import "./index.scss";

import DefaultLayout from "../../components/layouts/default";
import { Alert, Button, Image } from "react-bootstrap";
import BlockUi from "react-block-ui";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import EditIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import { useEffect, useState } from "react";
import List from "../../components/list";
import { deleteDeviceAction, fetchDevicesAction, saveDeviceAction } from "../../actions";
import ConfirmationPopup from "../../components/popup/confirmation";
import { DEVICE_TYPE_OPTIONS } from "../../constants";
import AddEditDeviceModal from "../device-list/add-edit-device-modal";
import { useNavigate } from "react-router-dom";

const DEFAULT_THERMOSTAT = {
  name: "",
  externalId: "",
  thermostatBrand: "",
  thermostatModelNumber: "",
  unitType: DEVICE_TYPE_OPTIONS[1].id,  // thermostat type is currently at 2nd in the list
};

const ThermostatManagement = () => {
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");
  const [thermostats, setThermostats] = useState([]);
  const [toDeleteThermostat, setToDeleteThermostat] = useState(null);
  const [isConfirmDeleteThermostatVisible, setIsConfirmDeleteThermostatVisible] = useState(false);
  const [isThermostatListReqLoading, setIsThermostatListReqLoading] = useState(false);
  const [isThermostatSaveReqLoading, setIsThermostatSaveReqLoading] = useState(false);
  const [isDeleteThermostatReqLoading, setIsDeleteThermostatReqLoading] = useState(false);
  const [isAddThermostatFormVisible, setIsAddThermostatFormVisible] =
    useState(false);
  const [newThermostat, setNewThermostat] = useState(DEFAULT_THERMOSTAT);

  const fetchThermostats = () => {
    setIsThermostatListReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
      setThermostats(resp);
    };

    const onError = (err) => {
      setErrorMsg("Failed to fetch thermostats");
    };

    const onFinally = () => {
      setIsThermostatListReqLoading(false);
    };

    fetchDevicesAction("all", DEVICE_TYPE_OPTIONS[1].id, onSuccess, onError, onFinally);
  };

  const saveThermostat = (updatedThermostat) => {
    setIsThermostatSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const updatedThermostatIdx = thermostats.findIndex(
        it => parseInt(it.id, 10) === parseInt(updatedThermostat.id, 10)
      );

      if (updatedThermostatIdx === -1) {
        setThermostats([updatedThermostat, ...thermostats]);
      } else {
        setThermostats([
          ...thermostats.slice(0, updatedThermostatIdx),
          updatedThermostat,
          ...thermostats.slice(updatedThermostatIdx + 1)
        ]);
      }

      setIsAddThermostatFormVisible(false);
    };

    const onError = (err) => {
      setErrorMsg("Failed to save thermostat");
    };

    const onFinally = () => {
      setIsThermostatSaveReqLoading(false);
    };

    saveDeviceAction(updatedThermostat.systemId, updatedThermostat, onSuccess, onError, onFinally);
  };

  const deleteThermostat = () => {
    setIsDeleteThermostatReqLoading(true);

    const onSuccess = () => {
      const deletedThermostatIdx = thermostats.findIndex(it => it.id === toDeleteThermostat.id);
      setThermostats([
        ...thermostats.slice(0, deletedThermostatIdx),
        ...thermostats.slice(deletedThermostatIdx + 1),
      ]);

      setIsConfirmDeleteThermostatVisible(false);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to delete thermostat");
    };

    const onFinally = () => {
      setIsDeleteThermostatReqLoading(false);
    };

    deleteDeviceAction([toDeleteThermostat.id], onSuccess, onError, onFinally);
  };

  const handleNavigateToDeviceDetail = (deviceId) => {
    navigate(`/devices/${deviceId}`);
  };

  useEffect(() => {
    fetchThermostats();
  }, []);

  const handleRenderItem = (item, columnId) => {
    const { id } = item;

    if (columnId === 'actions') {
      return (
        <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column input`}>
          <div
            className="btn btn-light clone-system-btn"
            onClick={(e) => handleCloneThermostat(e, item)}>
            <div className="btn-text">Clone</div>
          </div>

          <div
            className="btn btn-light edit-thermostat-btn"
            onClick={() => {
              setNewThermostat(item);
              setIsAddThermostatFormVisible(true);
            }}>
            <Image src={EditIcon} alt="Icon" />
            <div className="btn-text">Edit</div>
          </div>

          <div
            className="btn btn-light delete-thermostat-btn"
            onClick={() => {
              setToDeleteThermostat(item);
              setIsConfirmDeleteThermostatVisible(true);
            }}>
            <Image src={DeleteIcon} alt="Icon" />
            <div className="btn-text">Delete</div>
          </div>
        </div>
      );
    }

    if (columnId === "name") {
      return (
        <div className={`list-body-cell ${columnId}-column`} key={`${item.id}-${columnId}`}>
          <div className="thermostat-name" onClick={() => handleNavigateToDeviceDetail(item.id)}>
            {item[columnId] || ""}
          </div>

          <div className="thermostat-device-name">
            {item.thermostatDeviceName || "-"}
          </div>
        </div>
      );
    }

    if (["actualTemp", "heatSetpoint", "coolSetpoint"].indexOf(columnId) !== -1) {
      return (
        <div className={`list-body-cell ${columnId}-column`} key={`${item.id}-${columnId}`}>
          <div className="thermostat-temp">
            {item[columnId] ? `${item[columnId]}\u00B0F` : "-"}
          </div>
        </div>
      )
    }

    return (
      <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column`}>
        {item[columnId] || "-"}
      </div>
    );
  };

  const handleUpdateThermostat = (updatedThermostat) => {
    setNewThermostat(updatedThermostat);
  };

  const handleCloseThermostatModal = () => {
    setIsAddThermostatFormVisible(false);
    setNewThermostat(DEFAULT_THERMOSTAT);
  };

  const handleCloneThermostat = (event, thermostatDevice) => {
    event.stopPropagation();

    const clonedThermostatDevice = { ...thermostatDevice, name: `${thermostatDevice.name} (Clone)`, externalId: "" };
    delete clonedThermostatDevice.id;

    saveThermostat(clonedThermostatDevice);
  };

  const listColumns = [
    {
      id: "name",
      label: "Thermostat Name",
    },
    {
      id: "thermostatBrand",
      label: "Brand",
    },
    {
      id: "thermostatModelNumber",
      label: "Model",
    },
    {
      id: "organizationName",
      label: "Organization",
    },
    {
      id: "siteName",
      label: "Site",
    },
    {
      id: "heatSetpoint",
      label: "Heat Setpoint",
    },
    {
      id: "actualTemp",
      label: "Current Temp",
    },
    {
      id: "coolSetpoint",
      label: "Cool Setpoint",
    },
    {
      id: "actions",
      label: "Actions",
    }
  ];

  const breadCrumbOptions = [
    {
      id: 1,
      title: "Thermostat Management"
    },
  ];

  const isLoading = (
    isThermostatListReqLoading ||
    isThermostatSaveReqLoading ||
    isDeleteThermostatReqLoading
  );

  return (
    <DefaultLayout>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait">
        <div className="main-content">
          <div className="thermostat-list-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions} />

            <Button
              className="add-thermostat-btn site-detail-header-btn"
              variant="secondary"
              onClick={() => setIsAddThermostatFormVisible(true)}
            >
              New Thermostat
            </Button>
          </div>

          <div className="thermostat-list-wrap">
            <List
              canSelectMultipleRows={false}
              columns={listColumns}
              items={thermostats}
              renderItem={handleRenderItem} />
          </div>

          <ConfirmationPopup
            show={isConfirmDeleteThermostatVisible}
            title={"Wait, wait, wait..."}
            content={"This will permanently delete the thermostat. Continue?"}
            onNo={() => {
              setIsConfirmDeleteThermostatVisible(false);
              setToDeleteThermostat(null);
            }}
            onYes={() => {
              setIsConfirmDeleteThermostatVisible(false);
              deleteThermostat();
            }} />
        </div>

        <AddEditDeviceModal
          isVisible={isAddThermostatFormVisible}
          newDevice={newThermostat}
          addDeviceFormErrors={[]}
          onUpdateNewDevice={handleUpdateThermostat}
          onSaveDevice={() => saveThermostat(newThermostat)}
          onClose={handleCloseThermostatModal} />
      </BlockUi>
    </DefaultLayout>
  );
};

export default ThermostatManagement;
