import "./index.scss";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Image } from "react-bootstrap";
import OnIcon from "../../assets/icons/onButton.svg";
import OffIcon from "../../assets/icons/offButton.svg";
import BottomArrow from "../../assets/icons/bottomBlueArrow.svg";
import UpArrow from "../../assets/icons/upBlueArrow.svg";
import GaugeChart from "../../components/charts/gauge";
import { fetchLoggerSensorConfigAction } from "../../actions";
import MetricDetailCard from "./metric-detail-card";

const PRESSURE_GAUGE_RANGES = [
  {
    min: 0,
    max: 100,
  },
  {
    min: 100,
    max: 200,
  },
  {
    min: 200,
    max: 300,
  },
  {
    min: 300,
    max: 400,
  }
];

const PRESSURE_GAUGE_CHART_COLORS = ["#003f5c", "#488f31", "#ffa600", "#de425b"];

const LoggerDetail = ({ device, system }) => {
  const { id: deviceId } = useParams();

  const [visibleMetricNames, setVisibleMetricNames] = useState([]);

  const { metrics } = device;

  const {
    Temperature_LS = 32.0,
    Temperature_HS = 32.0,
    Compressor_Current = 0.0,
    Fan_Current = 0.0,
    Pressure_HS = 0.0,
    Pressure_LS = 0.0,
    runTime = {},
    dutyCycle = {}
  } = metrics || {};

  const fetchLoggerConfig = () => {
    const onSuccess = (resp) => {
      const metricNames = resp.map(it => it.channelName).filter(it => it);
      setVisibleMetricNames(metricNames);
    };

    const onError = () => {};

    const onFinally = () => {};

    fetchLoggerSensorConfigAction(deviceId, true, onSuccess, onError, onFinally);
  };

  useEffect(() => {
    fetchLoggerConfig();
  }, []);

  const pressureGaugeChartMaxValue = PRESSURE_GAUGE_RANGES[PRESSURE_GAUGE_RANGES.length - 1].max;
  const pressureGaugeChartSections = PRESSURE_GAUGE_RANGES.reduce((sections, range, idx) => {
    return [
      ...sections,
      {
        percent: (range.max - range.min) / pressureGaugeChartMaxValue * 100,
        color: PRESSURE_GAUGE_CHART_COLORS[idx]
      }
    ];
  }, []);

  const pressureLSValuePercent = Pressure_LS / pressureGaugeChartMaxValue * 100;
  const pressureLSColor = PRESSURE_GAUGE_CHART_COLORS[
    PRESSURE_GAUGE_RANGES.findIndex(
      range => Pressure_LS >= range.min && Pressure_LS <= range.max
    )
  ];

  const pressureHSValuePercent = Pressure_HS / pressureGaugeChartMaxValue * 100;
  const pressureHSColor = PRESSURE_GAUGE_CHART_COLORS[
    PRESSURE_GAUGE_RANGES.findIndex(
      range => Pressure_HS >= range.min && Pressure_HS <= range.max
    )
  ];

  const pressureLSValueSections = [
    {
      percent: pressureLSValuePercent,
      color: pressureLSColor
    },
    {
      percent: 100 - pressureLSValuePercent,
      color: "#2021241A"
    }
  ];

  const pressureHSValueSections = [
    {
      percent: pressureHSValuePercent,
      color: pressureHSColor
    },
    {
      percent: 100 - pressureHSValuePercent,
      color: "#2021241A"
    }
  ];

  const metricsConfig = [
    {
      metricId: "Demand_Signal",
      name: "Compressor Demand Run Time",
      value: runTime["Demand_Signal"] || 0,
      unit: "Hour",
    },
    {
      metricId: "Demand_Signal",
      name: "Compressor Demand Duty Cycle",
      value: dutyCycle["Demand_Signal"] || 0,
      unit: "%",
    },
    {
      metricId: "Compressor_Current",
      name: "Compressor 1 Run Time",
      value: runTime["Compressor_Current"] || 0,
      unit: "Hour",
    },
    {
      metricId: "Compressor_Current",
      name: "Compressor 1 Duty Cycle",
      value: dutyCycle["Compressor_Current"] || 0,
      unit: "%",
    },
    {
      metricId: "Compressor_Current2",
      name: "Compressor 2 Run Time",
      value: runTime["Compressor_Current2"] || 0,
      unit: "Hour",
    },
    {
      metricId: "Compressor_Current2",
      name: "Compressor 2 Duty Cycle",
      value: dutyCycle["Compressor_Current2"] || 0,
      unit: "%",
    },
    {
      metricId: "Fan_Current",
      name: "Fan Current",
      value: parseFloat(Fan_Current || "0").toFixed(2) || "0",
      unit: "Amps",
    },
    {
      metricId: "Compressor_Current",
      name: "Compressor Current",
      value: parseFloat(Compressor_Current || "0").toFixed(2) || "0",
      unit: "Amps",
    }
  ];
  const metricItems = metricsConfig
    .filter((metricConfig) => {
      const { metricId } = metricConfig;

      return visibleMetricNames.indexOf(metricId) !== -1;
    })
    .map((metricConfig) => {
      return (
        <MetricDetailCard key={metricConfig.name} metricConfig={metricConfig} />
      );
    });

  return (
    <>
      <div className="detail-card-wrapper">
        <div className="detail-card greenTag">
          <div className="detail-card-internal">
            <div className="detail-card-text greenColor">
              Thermostat Demand
            </div>
            <Image src={OnIcon} alt="on" className="detail-card-img" />
          </div>
        </div>

        <div className="detail-card redTag">
          <div className="detail-card-internal">
            <div className="detail-card-text redColor">
              Reversing Valve State
            </div>
            <Image src={OffIcon} alt="on" className="detail-card-img" />
          </div>
        </div>
      </div>

      <div className="metric-detail-card-wrapper">
        {metricItems}
      </div>

      <div className="bottom-detail-wrapper">
        {(
          visibleMetricNames.indexOf("Temperature_LS") !== -1 ||
          visibleMetricNames.indexOf("Temperature_HS") !== -1
        ) && (
          <div className="bottom-temp-wrapper">
            <div className="temp-text">Line Temperatures (&deg;F)</div>
            <div className="temp-value-wrapper">
              {visibleMetricNames.indexOf("Temperature_LS") !== -1 && (
                <div className="temp-value">
                  <Image src={BottomArrow} alt="bottom arrow" />
                  <div className="temp-value-text">
                    {(Temperature_LS || 32).toFixed(0) || "0"} &deg;
                  </div>
                </div>
              )}

              {visibleMetricNames.indexOf("Temperature_HS") !== -1 && (
                <div className="temp-value">
                  <Image src={UpArrow} alt="Up arrow" />
                  <div className="temp-value-text">
                    {(Temperature_HS || 32).toFixed(0) || "0"} &deg;
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {(
          visibleMetricNames.indexOf("Pressure_LS") !== -1 ||
          visibleMetricNames.indexOf("Pressure_HS") !== -1
        ) && (
          <div className="bottom-pressure-wrapper">
            <div className="pressure-text">Line Pressure (PSI)</div>

            <div className="gauge-chart-wrapper">
              {visibleMetricNames.indexOf("Pressure_LS") !== -1 && (
                <GaugeChart
                  titleText={
                    <span>
                      <span style={{ color: "#478CD1" }}>LS</span> Pressure
                    </span>
                  }
                  sections={pressureGaugeChartSections}
                  valueSections={pressureLSValueSections}
                  currentValue={Pressure_LS}
                  valueUnit={"psi"}
                  valueColor={pressureLSColor}
                  styles={{ width: "300px", height: "150px" }} />
              )}

              <div className="pressue-detail-text-wrapper">
                <div className="pressure-detail-text">Refrigerant Type</div>
                <div className="pressure-detail-subtext">{system?.refrigerantType}</div>
              </div>

              {visibleMetricNames.indexOf("Pressure_HS") !== -1 && (
                <GaugeChart
                  titleText={
                    <span>
                      <span style={{ color: "#de425b" }}>HS</span> Pressure
                    </span>
                  }
                  sections={pressureGaugeChartSections}
                  valueSections={pressureHSValueSections}
                  currentValue={Pressure_HS}
                  valueUnit={"psi"}
                  valueColor={pressureHSColor}
                  styles={{ width: "300px", height: "150px" }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LoggerDetail;
