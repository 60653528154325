import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { React, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { add } from 'date-fns';
import { fetchUserFailure, fetchUserLoading, fetchUserSuccess } from './actions/user/state';
import { MAX_INACTIVITY_SECONDS } from './constants';
import { getLSItem, setLSItem } from './services/local-storage';
import { fetchUserAction } from './actions/user/api';

const BaseComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showLogoutCancellation, setShowLogoutCancellation] = useState(false);
  const [_, setLogoutCancellationStartTime] = useState(null);
  const [logoutCancellationEndTime, setLogoutCancellationEndTime] = useState(null);

  const isLoading = false;

  useEffect(() => {
    document.addEventListener('mousemove', () => {
      setLSItem('lastActivity', new Date());
    });

    document.addEventListener('click', () => {
      setLSItem('lastActivity', new Date());
    });
  }, []);

  useEffect(() => {
    setInterval(() => {
      const lastActivity = getLSItem('lastActivity');
      const diffMs = Math.abs(new Date(lastActivity) - new Date());
      const numSeconds = Math.floor(diffMs / 1000);

      if (numSeconds === MAX_INACTIVITY_SECONDS) {
        setShowLogoutCancellation(true);

        const now = new Date();
        setLogoutCancellationEndTime(add(now, { seconds: 15 }));
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (!logoutCancellationEndTime) {
      return;
    }

    if (logoutCancellationEndTime - new Date() <= 0) {
      navigate(`/logout?next=${location.pathname}`);
      setShowLogoutCancellation(false);
      setLSItem('lastActivity', new Date());
      setLogoutCancellationEndTime(null);
    } else {
      setLogoutCancellationStartTime(new Date());
    }
  });

  const fetchUser = () => {
    dispatch(fetchUserLoading());

    const onSuccess = (resp) => {
      dispatch(fetchUserSuccess(resp));
    };

    const onError = (err) => {
      console.log(err);
      dispatch(fetchUserFailure(['Failed to fetch user']));
    };

    fetchUserAction(onSuccess, onError);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleCancelLogout = () => {
    setLogoutCancellationEndTime(null);
    setShowLogoutCancellation(false);
    setLSItem('lastActivity', new Date());
  };

  const timeRemainingBeforeLogout = Math.floor((logoutCancellationEndTime - new Date()) / 1000);

  return (
    <div className="App">
      <div className="app-content">
        {!isLoading && <Outlet />}

        <Modal
          show={showLogoutCancellation}>
          <Modal.Header closeButton onClick={handleCancelLogout}>
            <Modal.Title>
              Auto logout due to inactivity
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            Logging out in {timeRemainingBeforeLogout} seconds.
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelLogout}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default BaseComponent;
