const MetricDetailCard = ({ metricConfig, className }) => {
  const { name, value, unit } = metricConfig;

  return (
    <div className={`metric-detail-card ${className}`}>
      <div className="metric-detail-card-name">{name}</div>

      <div className="metric-detail-card-body">
        <span>{value}</span>
        <sup style={{ fontSize: "16px", top: "1em" }}>{unit}</sup>
      </div>
    </div>
  );
};

MetricDetailCard.defaultProps = {
  className: '',
};

export default MetricDetailCard;
