import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "./index.scss";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Legend
} from 'chart.js';
import { subDays, subSeconds } from 'date-fns';
import 'chartjs-adapter-date-fns';
import { useEffect, useRef, useState } from "react";
import { Alert, Form, Image } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../components/layouts/default";
import { ADMIN, DEVICE_TYPE_OPTIONS, ORG_ADMIN, SUCCESS } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns-tz';
import { fetchMiniDeviceAction, fetchSystemAction, fetchTimeHistoryInitial } from '../../actions';
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import ClockIcon from "../../assets/icons/clock.svg";
import BreadCrumbs from '../../components/breadcrumbs';
import AutoSizer from 'react-virtualized-auto-sizer';
import useOutsideAlerter from '../../hooks/outside-alerter';
import { DateRangePicker } from 'react-date-range';
import enUSLocale from 'date-fns/locale/en-US';
import LoggerTimeHistory from './logger-time-history';
import ThermostatTimeHistory from './thermostat-time-history';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Legend
);

const QUICK_DATE_RANGE_ITEMS = [
  {
    id: 'last5m',
    name: 'Last 5 mins',
    timeDiff: 5 * 60,
  },
  {
    id: 'last30m',
    name: 'Last 30 mins',
    timeDiff: 30 * 60,
  },
  {
    id: 'last1h',
    name: 'Last 1 hour',
    timeDiff: 60 * 60,
  },
  {
    id: 'last6h',
    name: 'Last 6 hours',
    timeDiff: 6 * 60 * 60,
  },
  {
    id: 'last1d',
    name: 'Last 1 day',
    timeDiff: 24 * 60 * 60,
  },
  {
    id: 'last2d',
    name: 'Last 2 days',
    timeDiff: 2 * 24 * 60 * 60,
  },
  {
    id: 'custom',
    name: 'Custom Range',
    timeDiff: null,
  },
];

const DeviceTimeHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const dateTimePickerRef = useRef();

  const [device, setDevice] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [tempSelectedDates, setTempSelectedDates] = useState([
    format(subDays(new Date(), 1), "yyyy-MM-dd hh:mm"),
    format(new Date(), "yyyy-MM-dd hh:mm")
  ]);
  const [selectedDates, setSelectedDates] = useState([subDays(new Date(), 1), new Date()]);
  const [system, setSystem] = useState(null);
  const [showQuickDateRangePicker, setShowQuickDateRangePicker] = useState(false);
  const [showCustomDateRangePicker, setShowCustomDateRangePicker] = useState(false);
  const [selectedQuickDateRangeId, setSelectedQuickDateRangeId] = useState('last1d');

  const userState = useSelector(state => state.user);

  const { type: userStateType, data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;
  const isUserReqSuccess = userStateType === SUCCESS;

  useOutsideAlerter(dateTimePickerRef, (event) => {
    if (
      dateTimePickerRef.current &&
      !dateTimePickerRef.current.contains(event.target) &&
      (showQuickDateRangePicker || showCustomDateRangePicker)
    ) {
      setShowQuickDateRangePicker(false);
      setShowCustomDateRangePicker(false);
    }
  });

  useEffect(() => {
    if (isUserReqSuccess && (isUserAdmin || isOrgAdmin)) {
      fetchDevice();
    }

    return () => {
      dispatch(fetchTimeHistoryInitial());
    };
  }, [isUserReqSuccess, isUserAdmin, isOrgAdmin]);

  useEffect(() => {
    if (device && device.hvacSystemId && !system) {
      fetchSystem(device.hvacSystemId);
    }
  }, [device, system]);

  /**
   * If user request is successfully completed and user is not a superadmin,
   * then navigate to device detail
   */
  useEffect(() => {
    if (isUserReqSuccess && !isUserAdmin && !isOrgAdmin) {
      navigate(`/devices/${id}`);
    }
  }, [isUserAdmin, isOrgAdmin, isUserReqSuccess]);

  const handleNavigateToDeviceDetail = () => {
    navigate(`/devices/${id}`);
  };

  const formatDates = (startDate, endDate) => {
    const longFormat = "do LLL''yy hh:mm";
    return `${format(startDate, longFormat)} to ${format(endDate, longFormat)}`;
  };

  const fetchSystem = (systemId) => {
    const onSuccess = (resp) => {
      setErrorMsg("");
      setSystem(resp);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch site");
    };

    fetchSystemAction(systemId, onSuccess, onError, () => {});
  };

  const fetchDevice = () => {
    const onSuccess = (resp) => {
      setErrorMsg("");
      setDevice(resp);
    };

    const onError = (err) => {
      setErrorMsg("Failed to fetch device");
    };

    const onFinally = () => {};

    fetchMiniDeviceAction(id, onSuccess, onError, onFinally);
  };

  const handleNavigateToOrg = () => {
    if (!system) {
      return;
    }

    navigate(`/?org_id=${system.organization.id}`);
  };

  const handleNavigateToSite = () => {
    if (system) {
      navigate(`/sites/${system.site.id}/systems`);
    }
  };

  const handleNavigateToSystem = () => {
    if (system) {
      navigate(`/systems/${system.id}`);
    }
  };

  const handleSetQuickRangeDate = (timeDiff) => {
    const now = new Date();
    const nowMinusTimeDiff = subSeconds(now, timeDiff);

    setSelectedDates([nowMinusTimeDiff, now]);
    setTempSelectedDates([
      format(nowMinusTimeDiff, "yyyy-MM-dd hh:mm"),
      format(now, "yyyy-MM-dd hh:mm")
    ]);
    setShowQuickDateRangePicker(false);
  };

  const handleSelectCustomRangePicker = (ranges) => {
    setTempSelectedDates([
      format(ranges.selection.startDate, 'yyyy-MM-dd hh:mm'),
      format(ranges.selection.endDate, 'yyyy-MM-dd hh:mm'),
    ]);
  };

  const selectedDatesLabel = selectedQuickDateRangeId === 'custom'
    ? formatDates(selectedDates[0], selectedDates[1])
    : QUICK_DATE_RANGE_ITEMS.find(it => it.id === selectedQuickDateRangeId).name;

  if (!isUserReqSuccess) {
    return;
  }

  const breadCrumbOptions = [
    {
      id: 1,
      title: system ? system.organization.name : "...",
      onClick: handleNavigateToOrg,
    },
    {
      id: 2,
      title: system ? system.site.name : "...",
      onClick: handleNavigateToSite,
    },
    {
      id: 3,
      title: system ? system.name : "...",
      onClick: handleNavigateToSystem,
    },
    {
      id: 4,
      title: device ? device.name : "...",
      onClick: handleNavigateToDeviceDetail
    },
    {
      id: 5,
      title: 'Time History',
    }
  ];

  const selectionRange = {
    startDate: !isNaN(new Date(tempSelectedDates[0]))
      ? new Date(tempSelectedDates[0])
      : selectedDates[0],
    endDate: !isNaN(new Date(tempSelectedDates[1]))
      ? new Date(tempSelectedDates[1])
      : selectedDates[1],
    key: 'selection',
  };

  const quickRangeItems = QUICK_DATE_RANGE_ITEMS.map(quickRange => {
    const { id, name, timeDiff } = quickRange;

    return (
      <div
        key={id}
        className={`quick-range-item ${id === selectedQuickDateRangeId ? "checked" : ""}`}
        onClick={() => {
          if (id !== 'custom') {
            handleSetQuickRangeDate(timeDiff);
            setSelectedQuickDateRangeId(id);
          } else {
            setShowCustomDateRangePicker(true);
            setTempSelectedDates([
                format(selectedDates[0], 'yyyy-MM-dd hh:mm'),
                format(selectedDates[1], 'yyyy-MM-dd hh:mm')
            ])
          }
        }}>
        <Form.Check
          checked={id === selectedQuickDateRangeId}
          type="checkbox"
          label={name}
          readOnly />
      </div>
    );
  });

  return (
    <DefaultLayout>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <div className="device-time-history-container">
        <div className='device-time-history-header'>
          <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions} />

          <div className='date-time-range-wrap' ref={dateTimePickerRef}>
            <div
              className='date-time-selected-wrap'
              onClick={() => {
                setShowQuickDateRangePicker(!showQuickDateRangePicker);
                setShowCustomDateRangePicker(false);
              }}>
              <span className='label'>
                <Image src={ClockIcon} />
                <span>{selectedDatesLabel}</span>
              </span>
            </div>

            {showQuickDateRangePicker && (
              <div className='date-time-picker'>
                <div className='quick-range-wrap'>
                  <ul className='quick-ranges'>{quickRangeItems}</ul>
                </div>
              </div>
            )}

            {showCustomDateRangePicker && (
              <div className="date-time-picker">
                <div className='absolute-range-wrap'>
                  <div className='date-range-selection-wrap'>
                    <div className='input-wrap'>
                        <input
                            type="text"
                            onChange={(e) => {
                                    setTempSelectedDates([
                                        e.target.value,
                                        tempSelectedDates[1]
                                    ]);
                            }}
                            value={tempSelectedDates[0]}
                            placeholder="2000-01-01 00:00" />
                    </div>

                    <div className="input-label">To</div>

                    <div className='input-wrap'>
                        <input
                            type="text"
                            onChange={(e) => {
                                if (e.target.value) {
                                    setTempSelectedDates([
                                        tempSelectedDates[0],
                                        e.target.value
                                    ]);
                                }
                            }}
                            value={tempSelectedDates[1]}
                            placeholder="2000-01-02 00:00" />
                    </div>
                  </div>

                  <DateRangePicker
                      locale={enUSLocale}
                      ranges={[selectionRange]}
                      staticRanges={[]}
                      inputRanges={[]}
                      showDateDisplay={false}
                      maxDate={new Date()}
                      onChange={handleSelectCustomRangePicker} />

                  <div className='action-btns'>
                    <div
                      className='btn btn-primary cancel-btn'
                      onClick={() => {
                        setShowCustomDateRangePicker(false);
                        setTempSelectedDates([
                          !isNaN(new Date(tempSelectedDates[0]))
                            ? tempSelectedDates[0]
                            : format(selectedDates[0], 'yyyy-MM-dd hh:mm'),
                          !isNaN(new Date(tempSelectedDates[1]))
                            ? tempSelectedDates[1]
                            : format(selectedDates[1], 'yyyy-MM-dd hh:mm'),
                        ]);
                      }}>
                      Cancel
                    </div>

                    <div
                      className='btn btn-primary apply-range-btn'
                      onClick={() => {
                        setSelectedQuickDateRangeId('custom');

                        let tempStartDate = !isNaN(new Date(tempSelectedDates[0]))
                          ? new Date(tempSelectedDates[0])
                          : selectedDates[0];
                        let tempEndDate = !isNaN(new Date(tempSelectedDates[1]))
                          ? new Date(tempSelectedDates[1])
                          : selectedDates[1];

                        if (tempStartDate > tempEndDate) {
                          const tempDate = tempStartDate;
                          tempStartDate = tempEndDate;
                          tempEndDate = tempDate;
                        }

                        setSelectedDates([tempStartDate, tempEndDate]);

                        setTempSelectedDates([
                          format(tempStartDate, 'yyyy-MM-dd hh:mm'),
                          format(tempEndDate, 'yyyy-MM-dd hh:mm')
                        ]);
                        setShowCustomDateRangePicker(false);
                      }}>
                      Apply
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="thermostat-device-name">
          {device.thermostatDeviceName}
        </div>

        <div className='charts-container'>
          <div className='chart-list'>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <div style={{ height }}>
                    {device.unitType === DEVICE_TYPE_OPTIONS[0].id && (
                      <LoggerTimeHistory
                        layoutWidth={width}
                        selectedDates={selectedDates}
                        onSetErrorMsg={setErrorMsg} />
                    )}

                    {device.unitType === DEVICE_TYPE_OPTIONS[1].id && (
                      <ThermostatTimeHistory
                        layoutWidth={width}
                        selectedDates={selectedDates}
                        onSetErrorMsg={setErrorMsg} />
                    )}
                  </div>
                );
              }}
            </AutoSizer>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default DeviceTimeHistory;
