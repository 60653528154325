import "./index.scss";

import { useEffect, useState } from "react";
import { forgotPassword, resetPasswordAction } from "../../actions";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import BlockUi from "react-block-ui";
import logoImg from "../../assets/logo-lg.png";
import { addMinutes } from "date-fns";

const ForgotPwd = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const [isOTPValidated, setIsOTPValidated] = useState(false);
  const [OTP, setOTP] = useState("");
  const [requestOTPAgainTime, setRequestOTPAgainTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date()), 1000);

    return () => clearInterval(interval);
  }, [requestOTPAgainTime]);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeOTP = (event) => {
    setOTP(event.target.value);
  };

  const handleChangePwd1 = (event) => {
    setPwd1(event.target.value);
  };

  const handleChangePwd2 = (event) => {
    setPwd2(event.target.value);
  };

  const handleSendPwdEmail = (isOTPRequestedAgain = false) => {
    if (!email) {
      return;
    }

    setIsLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const { message } = resp;

      if (message === "OTP has been sent, check your email") {
        setIsOTPRequested(true);
        setSuccessMsg(message);
        setRequestOTPAgainTime(addMinutes(new Date(), 2));
      } else if (
        message === "Incorrect OTP" ||
        message === "OTP incorrect too many times, account is locked. Please contact the administrator" ||
        message === "OTP requested too many times"
      ) {
        setErrorMsg(message);
        setSuccessMsg("");
      } else if (message === "Correct OTP") {
        setIsOTPValidated(true);
        setSuccessMsg("OTP validated, enter new password");
      }
    };

    const onError = err => {
      setErrorMsg(err.data ? err.data.message || "Failed to process the request" : "Failed to process the request");
    };

    const onFinally = () => {
      setIsLoading(false);
    };

    const payload = { username: email };

    if (OTP && !isOTPRequestedAgain) {
      payload.otp = parseInt(OTP, 10);

      if (!payload.otp) {
        setErrorMsg("OTP should be a number");
        setSuccessMsg("");
        setIsLoading(false);

        return;
      }
    }

    forgotPassword(payload, onSuccess, onError, onFinally);
  };

  const handleResetPassword = () => {
    if (pwd1 !== pwd2) {
      setErrorMsg("Passwords do not match");
      setSuccessMsg("");
    }

    setIsLoading(true);

    const onSuccess = (resp) => {
      setSuccessMsg("Password reset successfully, redirecting to login page");
      setErrorMsg("");

      setTimeout(() => {
        navigate("/");
      }, 1500);
    };

    const onError = (err) => {
      setErrorMsg(err.data ? err.data.message || "Failed to process the request" : "Failed to process the request");
    };

    const onFinally = () => {
      setIsLoading(false);
    };

    resetPasswordAction({ username: email, password: pwd1 }, onSuccess, onError, onFinally);
  };

  const timeLeftToRequestOTPAgain = parseInt((requestOTPAgainTime - currentTime) / 1000);

  return (
    <div className="login-page">
      {errorMsg && (
        <Alert variant="danger">{errorMsg}</Alert>
      )}

      {successMsg && (
        <Alert variant="success">{successMsg}</Alert>
      )}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
        <div className="login-container">
          <div className="pane logo-pane">
            <img className="icon" src={logoImg} alt="logo" />
          </div>

          <div className="pane form-pane">
            <div className="login-form">
              {!isOTPValidated && (
                <div className="input">
                  <input
                    type="text"
                    onChange={handleChangeEmail}
                    value={email}
                    placeholder="Email (handle@mailserver.com)" />
                </div>
              )}

              {isOTPRequested && !isOTPValidated && (
                <>
                  <div className="input">
                    <input
                      type="text"
                      onChange={handleChangeOTP}
                      value={OTP}
                      placeholder="OTP" />
                  </div>

                  {requestOTPAgainTime && (
                    <div className="request-otp-again-wrap">
                      {timeLeftToRequestOTPAgain > 0 && (
                        <span className="otp-request-again-timer">
                          {timeLeftToRequestOTPAgain}s
                        </span>
                      )}

                      <button
                        type="button"
                        className={`btn btn-light ${timeLeftToRequestOTPAgain > 0 ? "disabled" : ""} request-otp-again-btn`}
                        disabled={timeLeftToRequestOTPAgain > 0}
                        onClick={() => handleSendPwdEmail(true)}>
                        Request OTP again?
                      </button>
                    </div>
                  )}
                </>
              )}

              {isOTPRequested && isOTPValidated && (
                <>
                  <div className="input">
                    <input
                      type="password"
                      onChange={handleChangePwd1}
                      value={pwd1}
                      placeholder="Password (******)" />
                  </div>

                  <div className="input">
                    <input
                      type="password"
                      onChange={handleChangePwd2}
                      value={pwd2}
                      placeholder="Password again (******)" />
                  </div>
                </>
              )}

              {!isOTPValidated && (
                <button
                  type="button"
                  className={`btn btn-primary ${isLoading ? "disabled" : ""} request-otp-btn`}
                  disabled={isLoading}
                  onClick={() => handleSendPwdEmail()}>
                  {!isOTPRequested ? "Request OTP": "Validate OTP"}
                </button>
              )}

              {isOTPValidated && (
                <button
                  type="button"
                  className={`btn btn-primary ${isLoading ? "disabled" : ""} reset-pwd-btn`}
                  disabled={isLoading}
                  onClick={() => handleResetPassword()}>
                  Reset password
                </button>
              )}
            </div>
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default ForgotPwd;
