import { Button, Dropdown, Form, Image, Modal } from "react-bootstrap";
import DropDownIcon from "../../assets/icons/dropDownArrow.svg";
import FileUploader from "../../components/inputs/file_uploader";
import GoogleMap from "../../components/maps";
import MapMarker from "../../components/map-marker";
import { useEffect, useMemo, useRef, useState } from "react";
import { ADMIN, DEFAULT_MAP_ZOOM } from "../../constants";
import useOutsideAlerter from "../../hooks/outside-alerter";
import { useSelector } from "react-redux";
import { fetchSystemSizesAction } from "../../actions/systems/api";
import CrossIcon from "../../assets/icons/crossIcon.svg";

const REFRIGERANT_TYPE_OPTIONS = ["r22", "r410a", "r407c"];

const AddEditSystemModal = ({
  isVisible,
  newSystem,
  addSystemFormErrors,
  orgs,
  sites,
  selectedOrgId,
  selectedSiteId,
  onChangeOrgId,
  onChangeSiteId,
  onClose,
  onUpdateNewSystem,
  onSaveSystem,
  onSetNewSystem,
}) => {
  const userState = useSelector(state => state.user);

  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;

  const refrigerantDropDownRef = useRef();
  const orgDropdownRef = useRef();
  const siteDropDownRef = useRef();
  const sizeDropdownRef = useRef();

  const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);
  const [isOrgDropdownVisible, setIsOrgDropdownVisible] = useState(false);
  const [isSiteDropdownVisible, setIsSiteDropdownVisible] = useState(false);
  const [isRefrigerantTypeDropdownVisible, setIsRefrigerantTypeDropdownVisible] = useState(false);
  const [isSizeDropdownVisible, setIsSizeDropdownVisible] = useState(false);
  const [systemSizes, setSystemSizes] = useState([]);
  const [showAddNewSizeInput, setShowAddNewSizeInput] = useState(false);
  const [newSize, setNewSize] = useState("1");

  useOutsideAlerter(refrigerantDropDownRef, (event) => {
    if (
      refrigerantDropDownRef.current &&
      !refrigerantDropDownRef.current.contains(event.target) &&
      isRefrigerantTypeDropdownVisible
    ) {
      setIsRefrigerantTypeDropdownVisible(false);
    }
  });

  const fetchSystemSizes = () => {
    const onSuccess = (resp) => {
      setSystemSizes(resp);
    };

    const onError = () => {};
    const onFinally = () => {};

    fetchSystemSizesAction(onSuccess, onError, onFinally);
  };

  const handleGetCurrentLocation = ({ updateFunction, prevValue }) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { coords } = position;
        const { latitude, longitude, accuracy } = coords;
        const { accuracy: prevAccuracy } = prevValue;

        // if current accuracy is more than the previously set, then do nothing
        if (prevAccuracy <= accuracy) {
          return;
        }

        const updatedValue = {
          ...prevValue,
          latitude,
          longitude,
          accuracy,
        };

        updateFunction(updatedValue);
        setMapZoom(DEFAULT_MAP_ZOOM);
      },
      (error) => {
        alert(error.message);
      },
      { maximumAge: 10, timeout: 5000, enableHighAccuracy: true }
    );
  };

  const handleAddNewSize = () => {
    if (!newSize.trim()) {
      setShowAddNewSizeInput(false);
      return;
    }

    const sizeAsFloat = parseFloat(newSize.trim()) || 0;
    setSystemSizes(
      [...systemSizes, { size: sizeAsFloat }].sort(
        ({ size: sizeA }, { size: sizeB }) => (sizeA > sizeB) ? 1 : (sizeA < sizeB ? -1 : 0)
      )
    );
    setShowAddNewSizeInput(false);
    onUpdateNewSystem("size", sizeAsFloat);
  };

  const refrigerantTypeDropdownItems = REFRIGERANT_TYPE_OPTIONS.map(refrigerantType => {
    const isChecked = refrigerantType === newSystem.refrigerantType;

    return (
      <Dropdown.Item
        key={refrigerantType}
        onClick={() => onUpdateNewSystem("refrigerantType", refrigerantType)}>
        <Form.Check checked={isChecked} type="checkbox" label={refrigerantType} readOnly />
      </Dropdown.Item>
    );
  });

  const orgDropdownItems = orgs.map(org => {
    const isChecked = org.id === selectedOrgId;

    return (
      <Dropdown.Item
        key={org.id}
        onClick={() => { onChangeOrgId(org.id); onChangeSiteId(null); }}>
        <Form.Check checked={isChecked} type="checkbox" label={org.name} readOnly />
      </Dropdown.Item>
    );
  });

  const siteDropdownItems = sites.map(site => {
    const isChecked = site.id === selectedSiteId;

    return (
      <Dropdown.Item key={site.id} onClick={() => onChangeSiteId(site.id)}>
        <Form.Check checked={isChecked} type="checkbox" label={site.name} readOnly />
      </Dropdown.Item>
    );
  });

  const sizeDropdownItems = [{ size: "+ Add new"}, ...systemSizes].map(({ size }, idx) => {
    const isChecked = size === newSystem.size;

    // for "Add new" button & input
    if (idx === 0) {
      if (!showAddNewSizeInput) {
        return (
          <div
            key={size}
            className="add-new-size-btn"
            onClick={() => setShowAddNewSizeInput(true)}>
            <span>{size}</span>
          </div>
        );
      } else {
        return (
          <div className="add-new-size-input-container" key={size}>
            <input
              min={0.5}
              type="number"
              step="0.5"
              className="add-new-size-input"
              value={newSize}
              onChange={(event) => setNewSize(event.target.value)}
              onKeyDown={(e) => { e.keyCode === 13 && handleAddNewSize() }} />

            <img
              className="cancel-add-new-size-btn"
              src={CrossIcon}
              onClick={() => {
                setNewSize("1");
                setShowAddNewSizeInput(false);
              }}
              alt="cancel" />
          </div>
        );
      }
    }

    return (
      <Dropdown.Item
        key={size}
        onClick={() => onUpdateNewSystem("size", size)}>
        <Form.Check checked={isChecked} type="checkbox" label={`${size} Ton`} readOnly />
      </Dropdown.Item>
    );
  });

  const selectedOrg = orgs.find(it => it.id === selectedOrgId);
  const selectedSite = useMemo(() => {
    return sites.find(it => it.id === selectedSiteId);
  }, [sites, selectedSiteId]);

  useEffect(() => {
    fetchSystemSizes();
  }, []);

  useEffect(() => {
    if (!selectedSite) {
      return;
    }

    if (newSystem.latitude === 0 && newSystem.longitude === 0) {
      onSetNewSystem({
        ...newSystem,
        latitude: selectedSite.latitude,
        longitude: selectedSite.longitude,
      });
    }
  }, [newSystem, selectedSite]);

  return (
    <Modal show={isVisible} style={{ overflow: "auto" }}>
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>
          {newSystem.id ? "Update system" : "Add a system"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="system-modal-wrapper">
          <div className="add-system-form">
            <div className="input">
              <div className="name-wrapper">
                <div className="text-wrapper">
                  System Name <span style={{ color: "#BF2137" }}>*</span>
                </div>

                <input
                  type="text"
                  onChange={(e) => onUpdateNewSystem("name", e.target.value)}
                  value={newSystem.name || ""}
                  placeholder="Name" />
              </div>

              <div className="name-wrapper">
                <div className="text-wrapper">
                  Brand Name
                </div>

                <input
                  type="text"
                  onChange={(e) => onUpdateNewSystem("brand", e.target.value)}
                  value={newSystem.brand || ""}
                  placeholder="Brand" />
              </div>

              <div className="name-wrapper">
                <div className="text-wrapper">
                  Model Number
                </div>

                <input
                  type="text"
                  onChange={(e) => onUpdateNewSystem("modelNumber", e.target.value)}
                  value={newSystem.modelNumber || ""}
                  placeholder="Model Number" />
              </div>

              <div className="name-wrapper">
                <div className="text-wrapper">
                  Install Date
                </div>

                <input
                  className="date-input"
                  type="date"
                  min="2020-01-01"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => onUpdateNewSystem("installedOn", e.target.value)}
                  value={newSystem.installedOn.split("T")[0] || ""}
                  placeholder="Name" />
              </div>

              <div className="dropdown-wrapper">
                <div className="text-wrapper">
                  Size
                </div>

                <Dropdown
                  className="header-dropdown"
                  onClick={() => setIsSizeDropdownVisible(!isSizeDropdownVisible)}
                  ref={sizeDropdownRef}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {`${newSystem.size} Ton` || "No size selected"}

                    <Image
                      src={DropDownIcon}
                      style={{
                        transform: isSizeDropdownVisible ? "rotate(180deg)" : "",
                      }}
                      width={20}
                      height={10}
                      alt="DropDown Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{sizeDropdownItems}</Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="dropdown-wrapper">
                <div className="text-wrapper">
                  Refrigerant Type
                </div>

                <Dropdown
                  className="header-dropdown"
                  onClick={() => setIsRefrigerantTypeDropdownVisible(!isRefrigerantTypeDropdownVisible)}
                  ref={refrigerantDropDownRef}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {newSystem.refrigerantType || "No refrigerant type selected"}

                    <Image
                      src={DropDownIcon}
                      style={{
                        transform: isRefrigerantTypeDropdownVisible ? "rotate(180deg)" : "",
                      }}
                      width={20}
                      height={10}
                      alt="DropDown Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{refrigerantTypeDropdownItems}</Dropdown.Menu>
                </Dropdown>
              </div>

              {isUserAdmin && (
                <div className="dropdown-wrapper">
                  <div className="text-wrapper">
                    Organization <span style={{ color: "#BF2137" }}>*</span>
                  </div>

                  <Dropdown
                    className="header-dropdown"
                    onClick={() => setIsOrgDropdownVisible(!isOrgDropdownVisible)}
                    ref={orgDropdownRef}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {selectedOrg ? selectedOrg.name : "No organization selected"}

                      <Image
                        src={DropDownIcon}
                        style={{
                          transform: isOrgDropdownVisible ? "rotate(180deg)" : "",
                        }}
                        width={20}
                        height={10}
                        alt="DropDown Icon" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>{orgDropdownItems}</Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              <div className="dropdown-wrapper">
                <div className="text-wrapper">
                  Site <span style={{ color: "#BF2137" }}>*</span>
                </div>

                <Dropdown
                  className="header-dropdown"
                  onClick={() => setIsSiteDropdownVisible(!isSiteDropdownVisible)}
                  ref={siteDropDownRef}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedSite ? selectedSite.name : "No site selected"}

                    <Image
                      src={DropDownIcon}
                      style={{
                        transform: isSiteDropdownVisible ? "rotate(180deg)" : "",
                      }}
                      width={20}
                      height={10}
                      alt="DropDown Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{siteDropdownItems}</Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="name-wrapper">
                <div className="text-wrapper">
                  System Images
                </div>

                <FileUploader
                  selectedFiles={newSystem.files}
                  buttonLabel={`${newSystem.id ? "Change" : "Upload"} system image`}
                  onFileChange={(files) => onUpdateNewSystem("files", files)}
                  isMultipleUpload
                  acceptedFileTypes={["image/*"]} />
              </div>

              <div className="form-errors">
                {addSystemFormErrors.map((errorMsg) => {
                  return (
                    <div key={errorMsg.slice(6)} className="form-error">
                      {errorMsg}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="map-wrapper">
              <div className="text-wrapper">
                Site Location
              </div>

              <div className="map-container">
                <div className="map">
                  <GoogleMap
                    lat={newSystem.latitude || 0}
                    lng={newSystem.longitude || 0}
                    zoom={mapZoom}
                    MapMarkerComponent={MapMarker}
                    onSetMarker={({ lat, lng }) => onSetNewSystem({ ...newSystem, latitude: lat, longitude: lng })} />
                </div>

                <Button
                  className="map-current-location-btn"
                  onClick={() => handleGetCurrentLocation({ updateFunction: onSetNewSystem, prevValue: newSystem })}>
                  Current Location
                  <i className="fa fa-crosshairs map-crosshairs" />
                </Button>
              </div>
            </div>
          </div>

          <div className="bottom-controller">
            <div className="cancel-modal" onClick={onClose}>
              Cancel
            </div>

            <div className="submit-modal" onClick={onSaveSystem}>
              Submit
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditSystemModal;
