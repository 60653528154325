import "./index.scss";

import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Alert, Button, Dropdown, Image, Modal } from "react-bootstrap";
import { ADMIN, SORT_DIRECTIONS, SUCCESS, USER_ROLES } from "../../constants";
import DefaultLayout from "../../components/layouts/default";
import List from "../../components/list";
import ConfirmationPopup from "../../components/popup/confirmation";
import ThreeDotIcon from "../../assets/icons/threeDotsMore.svg";
import EditIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import DropDownIcon from "../../assets/icons/dropDownArrow.svg";
import ResetPwdIcon from "../../assets/icons/resetPwd.svg";
import format from "date-fns/format";
import { useSelector } from "react-redux";
import { useRef } from "react";
import useOutsideAlerter from "../../hooks/outside-alerter";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import {
  addUpdateUserAction,
  deleteUsersAction,
  fetchOrgsAction,
  fetchUsersAction,
  resetPasswordAction,
  updateUserStatusAction,
} from "../../actions";

const isValidEmail = (email) => {
  return email
    ? email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    : false;
};

const isValidName = (firstName, lastName) => {
  return firstName.length > 0;
};

const isValidPassword = (password) => {
  return password && password.length >= 6;
};

const transformUser = (user, orgs, currentUserId) => {
  const dateJoinedObj = format(new Date(user.dateJoined), "d MMM yyyy");
  const lastLoginObj = format(new Date(user.lastLogin), "d MMM yyyy");

  return {
    ...user,
    dateJoined: dateJoinedObj,
    lastLogin: lastLoginObj,
    organization: orgs.find((it) => it.id === user.organizationId),
    actions: [
      user.id !== currentUserId
        ? {
            id: "activateUser",
            label: user.isActive ? "Deactivate user" : "Activate user",
            icon: null,
          }
        : null,
      ...DEFAULT_USER_ACTIONS.filter(({ id: actionId }) =>
        user.id === currentUserId
          ? actionId === "editUser" || actionId === "resetPassword"
          : true
      ),
    ].filter((it) => it),
  };
};

const DEFAULT_NEW_USER = {
  email: "",
  firstName: "",
  lastName: "",
  role: 1,
  password: "",
  organizationId: null,
};

const DEFAULT_USER_ACTIONS = [
  {
    id: "editUser",
    label: "Edit user",
    icon: EditIcon,
  },
  {
    id: "deleteUser",
    label: "Delete user",
    icon: DeleteIcon,
  },
  {
    id: "resetPassword",
    label: "Reset password",
    icon: ResetPwdIcon,
  },
];

const BREADCRUMB_OPTIONS = [
  {
    id: 1,
    title: "User Management",
  },
];

const UserManagement = () => {
  const optionsMenuRef = useRef();
  const dropDownRef = useRef();

  const [errorMsg, setErrorMsg] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [sortColumn, setSortColumn] = useState("first_name");
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTIONS.ASC);
  const [isConfirmDeleteRowsVisible, setIsConfirmDeleteRowsVisible] =
    useState(false);
  const [isConfirmStatusUpdateVisible, setIsConfirmStatusUpdateVisible] =
    useState(false);
  const [isAddUserFormVisible, setIsAddUserFormVisible] = useState(false);
  const [isResetPwdFormVisible, setIsResetPwdFormVisible] = useState(false);
  const [newUser, setNewUser] = useState(DEFAULT_NEW_USER);
  const [addUserFormErrors, setAddUserFormErrors] = useState([]);
  const [resetPwdFormErrors, setResetPwdFormErrors] = useState([]);
  const [tempUser, setTempUser] = useState(DEFAULT_NEW_USER);
  const [resetPwd1, setResetPwd1] = useState("");
  const [resetPwd2, setResetPwd2] = useState("");
  const [orgs, setOrgs] = useState([]);
  const [isOrgsReqLoading, setIsOrgsReqLoading] = useState(false);
  const [optionsMenuOpenForId, setOptionsMenuOpenForId] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isUserReqLoading, setIsUserReqLoading] = useState(false);
  const [isUserUpdateReqLoading, setIsUserUpdateReqLoading] = useState(false);
  const [isUserDeleteReqLoading, setIsUserDeleteReqLoading] = useState(false);
  const [isUserStatusUpdateLoading, setIsUserStatusUpdateLoading] =
    useState(false);
  const [isResetPwdReqLoading, setIsResetPwdReqLoading] = useState(false);

  const userState = useSelector((state) => state.user);
  const { type: userStateType, data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isUserReqSuccess = userStateType === SUCCESS;
  const currentUserId = userStateData.id;
  const currentUserOrgId = userStateData.organizationId;

  const fetchUsers = () => {
    setIsUserReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const transformedUsers = resp.map((it) =>
        transformUser(it, orgs, currentUserId)
      );
      setAllUsers(transformedUsers);
      setUsers(transformedUsers);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch users");
    };

    const onFinally = () => {
      setIsUserReqLoading(false);
    };

    fetchUsersAction(sortColumn, sortDirection, onSuccess, onError, onFinally);
  };

  const addUpdateUser = () => {
    setIsUserUpdateReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      let updatedUsers = [...allUsers];

      if (newUser.id) {
        updatedUsers = allUsers.map((it) => {
          if (it.id !== newUser.id) {
            return it;
          }

          return transformUser(resp, orgs, currentUserId);
        });
      } else {
        updatedUsers = [transformUser(resp, orgs, currentUserId), ...allUsers];
      }

      setAllUsers(updatedUsers);
      setUsers(updatedUsers);
      setIsAddUserFormVisible(false);
    };

    const onError = (err) => {
      const { msg } = err.data || {};
      setAddUserFormErrors([`Failed to save user: ${msg || "unknown error"}`]);
    };

    const onFinally = () => {
      setIsUserUpdateReqLoading(false);
    };

    addUpdateUserAction(newUser, onSuccess, onError, onFinally);
  };

  const deleteUsers = () => {
    setIsUserDeleteReqLoading(true);

    const userIds = [tempUser.id];

    const onSuccess = () => {
      setErrorMsg("");

      const updatedUsers = allUsers.filter(
        (it) => userIds.indexOf(it.id) === -1
      );

      setAllUsers(updatedUsers);
      setUsers(updatedUsers);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg(["Failed to delete user"]);
    };

    const onFinally = () => {
      setIsUserDeleteReqLoading(false);
    };

    deleteUsersAction(userIds, onSuccess, onError, onFinally);
  };

  const updateUserStatus = () => {
    setIsUserStatusUpdateLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const updatedUserIdx = allUsers.findIndex((it) => tempUser.id === it.id);

      const updatedUsers = [
        ...allUsers.slice(0, updatedUserIdx),
        {
          ...transformUser(resp, orgs, currentUserId),
          role: allUsers[updatedUserIdx].role,
        },
        ...allUsers.slice(updatedUserIdx + 1),
      ];
      setAllUsers(updatedUsers);
      setUsers(updatedUsers);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg(["Failed to update user status"]);
    };

    const onFinally = () => {
      setIsUserStatusUpdateLoading(false);
      setNewUser({
        ...DEFAULT_NEW_USER,
        organizationId: !isUserAdmin ? currentUserOrgId : null,
      });
    };

    updateUserStatusAction(tempUser, onSuccess, onError, onFinally);
  };

  const fetchOrganizations = () => {
    setIsOrgsReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
      setOrgs(resp);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch organizations");
    };

    const onFinally = () => {
      setIsOrgsReqLoading(false);
    };

    fetchOrgsAction(onSuccess, onError, onFinally);
  };

  const resetPassword = () => {
    setIsResetPwdReqLoading(true);

    const onSuccess = () => {
      setErrorMsg("");
      setIsResetPwdFormVisible(false);
    };

    const onError = (err) => {
      console.log(err);
      setResetPwdFormErrors(["Failed to reset password"]);
    };

    const onFinally = () => {
      setIsResetPwdReqLoading(false);
      setTempUser({ ...DEFAULT_NEW_USER });
    };

    resetPasswordAction(
      { id: tempUser.id, password: resetPwd1 },
      onSuccess,
      onError,
      onFinally
    );
  };

  useEffect(() => {
    if (!isUserReqSuccess) {
      return;
    }

    if (isUserAdmin) {
      fetchOrganizations();
    }
  }, [isUserReqSuccess]);

  useEffect(() => {
    if (!isUserReqSuccess) {
      return;
    }

    if (isUserAdmin && orgs.length === 0) return;

    fetchUsers();
  }, [isUserReqSuccess, isUserAdmin, orgs, sortColumn, sortDirection]);

  useEffect(() => {
    if (allUsers.length === 0) {
      return;
    }

    if (searchTerm.length === 0) {
      setUsers(allUsers);
    } else {
      const filteredUsers = allUsers.filter(
        (user) =>
          user.id.toString().indexOf(searchTerm) !== -1 ||
          user.username.indexOf(searchTerm) !== -1 ||
          user.firstName.indexOf(searchTerm) !== -1 ||
          user.lastName.indexOf(searchTerm) !== -1
      );

      setUsers(filteredUsers);
    }
  }, [searchTerm]);

  useOutsideAlerter(optionsMenuRef, (event) => {
    if (optionsMenuOpenForId !== null) {
      setOptionsMenuOpenForId(null);
    }
  });

  const isAddUserFormValid = () => {
    let isValid = true;
    const errors = [];

    const isEmailValid = isValidEmail(newUser.email);
    if (!isEmailValid) {
      isValid = false;
      errors.push("Invalid email");
    }

    const isNameValid = isValidName(newUser.firstName, newUser.lastName);
    if (!isNameValid) {
      isValid = false;
      errors.push("First name is required");
    }

    const isPwdValid = isValidPassword(newUser.password);
    if (!isPwdValid && !newUser.id) {
      isValid = false;
      errors.push("Password should be at least 6 characters long");
    }

    return { isValid, errors };
  };

  const isResetPwdFormValid = () => {
    let isValid = true;
    const errors = [];

    if (resetPwd1.length < 6) {
      isValid = false;
      errors.push("Password should be at least 6 characters long");
    }

    if (resetPwd1 !== resetPwd2) {
      isValid = false;
      errors.push("Passwords do not match");
    }

    return { isValid, errors };
  };

  const handleSearch = (searchInput) => {
    setSearchTerm(searchInput);
  };

  const handleEditListItem = (item) => {
    setNewUser({
      ...item,
      organizationId: !isUserAdmin ? currentUserOrgId : item.organizationId,
    });
    setIsAddUserFormVisible(true);
  };

  const handleResetPassword = () => {
    const { isValid, errors } = isResetPwdFormValid();

    if (!isValid) {
      setResetPwdFormErrors(errors);
    } else {
      setResetPwdFormErrors([]);
      resetPassword();
    }
  };

  const handleListItemAction = (item, actionId) => {
    if (actionId === "editUser") {
      handleEditListItem(item);
    } else if (actionId === "activateUser") {
      setTempUser({ ...item });
      setIsConfirmStatusUpdateVisible(item);
    } else if (actionId === "deleteUser") {
      setTempUser({ ...item });
      setIsConfirmDeleteRowsVisible(true);
    } else if (actionId === "resetPassword") {
      setTempUser({ ...item });
      setIsResetPwdFormVisible(true);
    }
  };

  const handleOpenOptionsMenu = (event, itemId) => {
    event.stopPropagation();

    if (itemId === optionsMenuOpenForId) {
      setOptionsMenuOpenForId(null);
    } else {
      setOptionsMenuOpenForId(itemId);
    }
  };

  const handleRenderItem = (item, columnId) => {
    const { id, actions } = item;
    const optionsMenuOpen = optionsMenuOpenForId === id;

    if (columnId === "actions") {
      return (
        <div className="actions-column" key={`${id}-actions`}>
          <img
            className="three-dot-more-icon"
            src={ThreeDotIcon}
            alt="More"
            onClick={(event) => handleOpenOptionsMenu(event, id)}
          />

          {optionsMenuOpen && (
            <div className="more-options-wrapper" ref={optionsMenuRef}>
              {actions.map((action) => {
                const {
                  id: actionId,
                  label: actionLabel,
                  icon: actionIcon,
                } = action;

                return (
                  <div
                    className="more-options-row"
                    key={actionId}
                    onClick={() => handleListItemAction(item, actionId)}
                  >
                    {actionIcon && <Image src={actionIcon} alt="Icon" />}
                    {!actionIcon && <div style={{ width: "18px" }} />}
                    <div className="more-options-text">{actionLabel}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }

    if (columnId === "username") {
      const { username, firstName, lastName } = item;

      return (
        <div
          key={`${id}-${columnId}`}
          className={`list-body-cell ${columnId}-column`}
        >
          <div className="name-text">
            {`${firstName} ${lastName} ${currentUserId === id ? "(You)" : ""}`}
          </div>

          <div className="username-text">{username}</div>
        </div>
      );
    }

    let cellData = item[columnId];
    if (columnId === "role") {
      cellData = USER_ROLES.find((it) => it.id === item[columnId]).label;
    } else if (columnId === "organization") {
      cellData = item[columnId] ? item[columnId].name : "";
    }

    return (
      <div
        key={`${id}-${columnId}`}
        className={`list-body-cell ${columnId}-column`}
      >
        {cellData}
      </div>
    );
  };

  const handleSortList = (columnId) => {
    setSortColumn(columnId);

    let newSortDirection = null;
    if (columnId === sortColumn) {
      if (sortDirection === SORT_DIRECTIONS.ASC) {
        newSortDirection = SORT_DIRECTIONS.DESC;
      } else {
        newSortDirection = SORT_DIRECTIONS.ASC;
      }
    } else {
      newSortDirection = SORT_DIRECTIONS.DESC;
    }

    setSortDirection(newSortDirection);
  };

  const handleUpdateNewUser = (key, value) => {
    setNewUser({ ...newUser, [key]: value });
  };

  const handleShowAddUserForm = () => {
    setNewUser({
      ...DEFAULT_NEW_USER,
      organizationId: !isUserAdmin ? currentUserOrgId : orgs[0].id,
    });
    setIsAddUserFormVisible(true);
  };

  const handleAddUser = () => {
    const { isValid, errors } = isAddUserFormValid();

    if (!isValid) {
      setAddUserFormErrors(errors);
    } else {
      setAddUserFormErrors([]);
      addUpdateUser();
    }
  };

  let userListColumns = [
    {
      id: "id",
      label: "ID",
      canSort: true,
      onSort: handleSortList,
    },
    {
      id: "username",
      label: "Username",
      canSort: true,
      onSort: handleSortList,
    },
    {
      id: "dateJoined",
      label: "Date Joined",
      canSort: true,
      onSort: handleSortList,
    },
    {
      id: "lastLogin",
      label: "Last Login",
      canSort: true,
      onSort: handleSortList,
    },
    {
      id: "role",
      label: "Role",
      canSort: false,
    },
    {
      id: "organization",
      label: "Organization",
      canSort: false,
    },
    {
      id: "actions",
      label: "",
      canSort: false,
    },
  ].map((column) => ({
    ...column,
    sortDirection: column.id === sortColumn ? sortDirection : null,
  }));

  if (!isUserAdmin) {
    userListColumns = userListColumns.filter((it) => it.id !== "organization");
  }

  const isLoading =
    isUserReqLoading ||
    isUserUpdateReqLoading ||
    isUserDeleteReqLoading ||
    isUserStatusUpdateLoading ||
    isResetPwdReqLoading ||
    isOrgsReqLoading;

  const selectedOrg =
    orgs.find(
      (it) => it.id === (newUser.organizationId || tempUser.organizationId)
    ) || orgs[0];

  return (
    <DefaultLayout
      pageTitle={"Manage users"}
      showSearchBar
      onSearch={handleSearch}
      searchPlaceholder={"Search user by id/name"}
    >
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
        <div className="user-mgmt-container">
          <div className="user-list-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={BREADCRUMB_OPTIONS}
            />

            <Button
              className="add-user-btn"
              variant="primary"
              onClick={handleShowAddUserForm}
            >
              Add user
            </Button>
          </div>

          <div className="user-list-center-container">
            <List
              canSelectMultipleRows={false}
              columns={userListColumns}
              items={users}
              renderItem={handleRenderItem}
            />
          </div>
          <ConfirmationPopup
            show={isConfirmDeleteRowsVisible}
            title={"Wait, wait, wait..."}
            content={`This will permanently delete the user: ${tempUser.email}. Continue?`}
            onNo={() => {
              setTempUser(DEFAULT_NEW_USER);
              setIsConfirmDeleteRowsVisible(false);
            }}
            onYes={() => {
              setIsConfirmDeleteRowsVisible(false);
              deleteUsers();
            }}
          />

          <ConfirmationPopup
            show={isConfirmStatusUpdateVisible}
            title={"Status Update"}
            content={`This action will ${
              tempUser.isActive ? "deactivate" : "activate"
            } the user. Continue?`}
            onNo={() => {
              setTempUser(DEFAULT_NEW_USER);
              setIsConfirmStatusUpdateVisible(false);
            }}
            onYes={() => {
              setIsConfirmStatusUpdateVisible(false);
              updateUserStatus();
            }}
          />

          <Modal show={isResetPwdFormVisible} className="reset-pwd-modal">
            <Modal.Header
              closeButton
              onClick={() => {
                setTempUser(DEFAULT_NEW_USER);
                setIsResetPwdFormVisible(false);
              }}
            >
              <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="reset-pwd-form">
                <div className="input">
                  <div className="name-wrapper">
                    <div className="text-wrapper">
                      Password <span style={{ color: "#BF2137" }}>*</span>
                    </div>

                    <input
                      type="password"
                      onChange={(e) => setResetPwd1(e.target.value)}
                      value={resetPwd1}
                      placeholder="Password"
                    />
                  </div>

                  <div className="name-wrapper">
                    <div className="text-wrapper">
                      Confirm Password{" "}
                      <span style={{ color: "#BF2137" }}>*</span>
                    </div>

                    <input
                      type="password"
                      onChange={(e) => setResetPwd2(e.target.value)}
                      value={resetPwd2}
                      placeholder="Confirm password"
                    />
                  </div>

                  <div className="form-errors">
                    {resetPwdFormErrors.map((errorMsg) => {
                      return (
                        <div key={errorMsg.slice(6)} className="form-error">
                          {errorMsg}
                        </div>
                      );
                    })}
                  </div>

                  <Button variant="primary" onClick={handleResetPassword}>
                    Change password
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={isAddUserFormVisible} className="add-user-modal">
            <Modal.Header
              closeButton
              onClick={() => {
                setAddUserFormErrors([]);
                setNewUser({
                  ...DEFAULT_NEW_USER,
                  organizationId: !isUserAdmin ? currentUserOrgId : null,
                });
                setIsAddUserFormVisible(false);
              }}
            >
              <Modal.Title>
                {newUser.id ? "Update User" : "Add New User"}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="add-user-form">
                <div className="input">
                  <div className="name-wrapper">
                    <div className="text-wrapper">
                      Email <span style={{ color: "#BF2137" }}>*</span>
                    </div>

                    <input
                      type="text"
                      onChange={(e) =>
                        handleUpdateNewUser("email", e.target.value)
                      }
                      value={newUser.email || ""}
                      placeholder="Email (foo@bar.com)"
                    />
                  </div>

                  <div className="name-wrapper">
                    <div className="text-wrapper">
                      First name <span style={{ color: "#BF2137" }}>*</span>
                    </div>

                    <input
                      type="text"
                      onChange={(e) =>
                        handleUpdateNewUser("firstName", e.target.value)
                      }
                      value={newUser.firstName || ""}
                      placeholder="First Name"
                    />
                  </div>

                  <div className="name-wrapper">
                    <div className="text-wrapper">Last name</div>

                    <input
                      type="text"
                      onChange={(e) =>
                        handleUpdateNewUser("lastName", e.target.value)
                      }
                      value={newUser.lastName || ""}
                      placeholder="Last Name"
                    />
                  </div>

                  {!newUser.id && (
                    <div className="name-wrapper">
                      <div className="text-wrapper">
                        Password <span style={{ color: "#BF2137" }}>*</span>
                      </div>

                      <input
                        type="password"
                        onChange={(e) =>
                          handleUpdateNewUser("password", e.target.value)
                        }
                        value={newUser.password || ""}
                        placeholder="Password"
                      />
                    </div>
                  )}

                  <div className="name-wrapper">
                    <div className="text-wrapper">
                      Role <span style={{ color: "#BF2137" }}>*</span>
                    </div>

                    <Dropdown
                      className="role-dropdown"
                      onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                      ref={dropDownRef}
                    >
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {USER_ROLES.find((it) => it.id === newUser.role).label}

                        <Image
                          src={DropDownIcon}
                          style={{
                            transform: isDropdownVisible
                              ? "rotate(180deg)"
                              : "",
                          }}
                          width={20}
                          height={10}
                          alt="DropDown Icon"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {USER_ROLES.filter((it) =>
                          isUserAdmin ? true : it.id === 0 ? false : true
                        ).map((role) => {
                          return (
                            <Dropdown.Item
                              key={role.id}
                              onClick={() =>
                                handleUpdateNewUser("role", role.id)
                              }
                            >
                              {role.label}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {isUserAdmin && (
                    <div className="name-wrapper">
                      <div className="text-wrapper">
                        Organization <span style={{ color: "#BF2137" }}>*</span>
                      </div>

                      <Dropdown
                        className="org-dropdown"
                        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                        ref={dropDownRef}
                      >
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {selectedOrg ? selectedOrg.name : "Not selected"}

                          <Image
                            src={DropDownIcon}
                            style={{
                              transform: isDropdownVisible
                                ? "rotate(180deg)"
                                : "",
                            }}
                            width={20}
                            height={10}
                            alt="DropDown Icon"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {orgs.map((org) => {
                            return (
                              <Dropdown.Item
                                key={org.id}
                                onClick={() =>
                                  handleUpdateNewUser("organizationId", org.id)
                                }
                              >
                                {org.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}

                  <div className="form-errors">
                    {addUserFormErrors.map((errorMsg) => {
                      return (
                        <div key={errorMsg.slice(6)} className="form-error">
                          {errorMsg}
                        </div>
                      );
                    })}
                  </div>

                  <div className="action-btns">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setAddUserFormErrors([]);
                        setNewUser({
                          ...DEFAULT_NEW_USER,
                          organizationId: !isUserAdmin
                            ? currentUserOrgId
                            : null,
                        });
                        setIsAddUserFormVisible(false);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button variant="primary" onClick={handleAddUser}>
                      {!newUser.id ? "Send Invite" : "Update user"}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </BlockUi>
    </DefaultLayout>
  );
};

export default UserManagement;
