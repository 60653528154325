import React from "react";
import { useSelector } from "react-redux";
import { getLineChartData, getLineChartOptions, getSelectedDatesKey } from "../../chart-utils.js";
import { Line } from "react-chartjs-2";
import { useMemo } from "react";
import BlockUi from "react-block-ui";

const DutyCycleChart = ({ selectedDates, visibleMetricNames, measureNameGroup }) => {
    const timeHistoryState = useSelector(state => state.timeHistory);
    const { data: deviceTimeHistory, loadingIds } = timeHistoryState;

    const selectedDatesKey = getSelectedDatesKey(selectedDates);
    const { timestamps = [], metrics = {} } = useMemo(
        () => (deviceTimeHistory[selectedDatesKey] || []).find(it => it.id === "duty_cycle")?.results || {},
        [selectedDates, deviceTimeHistory]
    );

    const isLoading = loadingIds.indexOf(measureNameGroup.id) !== -1;

    const demandSignalDutyCycleSeries = useMemo(() => {
        return timestamps?.map(
            (timestamp, index) => [
                new Date(parseInt(timestamp)),
                metrics["Demand_Signal_dutyCycle"]?.[index]
            ]
        );
    }, [metrics]);
    const compressorCurrentDutyCycleSeries = useMemo(() => {
        return timestamps?.map(
            (timestamp, index) => [
                new Date(parseInt(timestamp)),
                metrics["Compressor_Current_dutyCycle"]?.[index]
            ]
        );
    }, [metrics]);
    const compressorCurrent2DutyCycleSeries = useMemo(() => {
        return timestamps?.map(
            (timestamp, index) => [
                new Date(parseInt(timestamp)),
                metrics["Compressor_Current2_dutyCycle"]?.[index]
            ]
        );
    }, [metrics]);

    const chartTitles = [];
    const chartSeries = [];

    if (visibleMetricNames.indexOf("Demand_Signal") !== -1) {
        chartTitles.push("Demand Signal");
        chartSeries.push(demandSignalDutyCycleSeries);
    }

    if (visibleMetricNames.indexOf("Compressor_Current") !== -1) {
        chartTitles.push("Compressor Current");
        chartSeries.push(compressorCurrentDutyCycleSeries);
    }

    if (visibleMetricNames.indexOf("Compressor_Current2") !== -1) {
        chartTitles.push("Compressor Current 2");
        chartSeries.push(compressorCurrent2DutyCycleSeries);
    }

    return (
        <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
            <div className="chart-row">
                <Line
                    width={"100%"}
                    height={"400px"}
                    data={getLineChartData(chartTitles, chartSeries)}
                    options={getLineChartOptions("Duty Cycle (%)", "%")} />
            </div>
        </BlockUi>
    );
};

export default React.memo(DutyCycleChart);
