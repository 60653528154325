import "./index.scss";

import { useEffect, useState } from "react";
import DefaultLayout from "../../components/layouts/default";
import { Alert, Button, Image } from "react-bootstrap";
import BlockUi from "react-block-ui";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import EditIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import List from "../../components/list";
import AddEditOrgModal from "./add-edit-org-modal";
import { deleteOrgAction, fetchOrgsAction, saveOrgAction } from "../../actions";
import ConfirmationPopup from "../../components/popup/confirmation";

const DEFAULT_NEW_ORG = {
    name: "",
};

const OrgManagement = () => {
    const [errorMsg, setErrorMsg] = useState("");
    const [orgs, setOrgs] = useState([]);
    const [isOrgListReqLoading, setIsOrgListReqLoading] = useState(false);
    const [isAddOrgFormVisible, setIsAddOrgFormVisible] = useState(false);
    const [newOrg, setNewOrg] = useState(DEFAULT_NEW_ORG);
    const [isOrgSaveReqLoading, setIsOrgSaveReqLoading] = useState(false);
    const [toDeleteOrg, setToDeleteOrg] = useState(DEFAULT_NEW_ORG);
    const [isConfirmDeleteOrgVisible, setIsConfirmDeleteOrgVisible] = useState(false);
    const [isOrgDeleteReqLoading, setIsOrgDeleteReqLoading] = useState(false);

    const fetchOrganizations = () => {
        setIsOrgListReqLoading(true);

        const onSuccess = (resp) => {
            setOrgs(resp);
        };

        const onError = () => {
            setErrorMsg("Failed to fetch organizations")
        };

        const onFinally = () => {
            setIsOrgListReqLoading(false);
        };

        fetchOrgsAction(onSuccess, onError, onFinally);
    };

    const saveOrg = () => {
        setIsOrgSaveReqLoading(true);
        setErrorMsg("");

        const onSuccess = (resp) => {
            if (!newOrg.id) {
                setOrgs([resp, ...orgs]);
            } else {
                const orgIdx = orgs.findIndex(it => it.id === newOrg.id);

                setOrgs([
                    ...orgs.slice(0, orgIdx),
                    resp,
                    ...orgs.slice(orgIdx + 1),
                ]);
            }

            handleAddOrgModalClose();
        };

        const onError = () => {
            setErrorMsg("Failed to save organization");
        };

        const onFinally = () => {
            setIsOrgSaveReqLoading(false);
            setNewOrg(DEFAULT_NEW_ORG);
        };

        saveOrgAction(newOrg, onSuccess, onError, onFinally);
    };

    const deleteOrg = () => {
        setIsOrgDeleteReqLoading(true);
        setErrorMsg("");

        const onSuccess = (resp) => {
            const orgIdx = orgs.findIndex(it => it.id === toDeleteOrg.id);

            if (orgIdx !== -1) {
                setOrgs([...orgs.slice(0, orgIdx), ...orgs.slice(orgIdx + 1)]);
            }
        };

        const onError = (err) => {
            setErrorMsg("Failed to delete organization");
        };

        const onFinally = () => {
            setIsOrgDeleteReqLoading(false);
            setToDeleteOrg(DEFAULT_NEW_ORG);
        };

        deleteOrgAction([toDeleteOrg.id], onSuccess, onError, onFinally);
    };

    const handleRenderItem = (item, columnId) => {
        const { id } = item;

        if (columnId === 'actions') {
            return (
                <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column`}>
                    <div
                        className="btn btn-light edit-org-btn"
                        onClick={() => handleEditOrg(item)}>
                        <Image src={EditIcon} alt="Icon" />
                        <div className="btn-text">Edit</div>
                    </div>

                    <div
                        className="btn btn-light delete-org-btn"
                        onClick={() => handleConfirmDeleteOrg(item)}>
                        <Image src={DeleteIcon} alt="Icon" />
                        <div className="btn-text">Delete</div>
                    </div>
                </div>
            );
        }

        return (
            <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column`}>
                {item[columnId] || "-"}
            </div>
        );
    };

    const handleAddOrgModalClose = () => {
        setNewOrg(DEFAULT_NEW_ORG);
        setIsAddOrgFormVisible(false);
    };

    const handleEditOrg = (org) => {
        setNewOrg(org);
        setIsAddOrgFormVisible(true);
    };

    const handleConfirmDeleteOrg = (org) => {
        setToDeleteOrg(org);
        setIsConfirmDeleteOrgVisible(true);
    };

    useEffect(() => {
        fetchOrganizations();
    }, []);

    const listColumns = [
        {
            id: "id",
            label: "ID",
            canSort: false,
        },
        {
            id: "name",
            label: "Organization",
            canSort: false,
        },
        {
            id: "actions",
            label: "Actions",
        }
    ];

    const isLoading = isOrgListReqLoading || isOrgSaveReqLoading || isOrgDeleteReqLoading;
    const breadCrumbOptions = [
        {
            id: 1,
            title: "Organization Management"
        },
    ];

    return (
        <DefaultLayout>
            {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

            <BlockUi tag="div" blocking={isLoading} message="Loading, please wait">
                <div className="main-content">
                    <div className="org-list-header">
                        <BreadCrumbs
                            homeIcon={HomeIcon}
                            progressIcon={RightArrow}
                            options={breadCrumbOptions} />

                        <Button
                            className="add-org-btn site-detail-header-btn"
                            variant="secondary"
                            onClick={() => setIsAddOrgFormVisible(true)}
                            >
                            New Org
                        </Button>
                    </div>

                    <div className="org-list-wrap">
                        <List
                            canSelectMultipleRows={false}
                            columns={listColumns}
                            items={orgs}
                            renderItem={handleRenderItem} />
                    </div>
                </div>

                {isAddOrgFormVisible && (
                    <AddEditOrgModal
                        isVisible={isAddOrgFormVisible}
                        newOrg={newOrg}
                        onUpdateNewOrg={setNewOrg}
                        onSaveOrg={saveOrg}
                        onClose={handleAddOrgModalClose} />
                )}

                <ConfirmationPopup
                    show={isConfirmDeleteOrgVisible}
                    title={"Wait, wait, wait..."}
                    content={`Are you sure you want to delete ${toDeleteOrg?.name ?? ""} organization. Continue?`}
                    onNo={() => {
                        setIsConfirmDeleteOrgVisible(false);
                        setToDeleteOrg(null);
                    }}
                    onYes={() => {
                        setIsConfirmDeleteOrgVisible(false);
                        deleteOrg();
                    }} />
            </BlockUi>
        </DefaultLayout>
    )
};

export default OrgManagement;
