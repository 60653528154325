import {
  buildRequest,
  getUTCTimestamp,
  transformQueryParams,
} from '../../utils';

export const fetchDevicesAction = (systemId, unitType, onSuccess, onError, onFinally) => {
  const queryParams = {};

  if (unitType) {
    queryParams.unit_type = unitType;
  }

  const queryParamsStr = transformQueryParams(queryParams);

  return buildRequest(`core/systems/${systemId}/units?${queryParamsStr}`, 'GET')
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const fetchDeviceAction = (deviceId, onSuccess, onError, onFinally) => buildRequest(`core/units/${deviceId}/`, 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchMiniDeviceAction = (
  deviceId,
  onSuccess,
  onError,
  onFinally,
) => buildRequest(`core/units/${deviceId}/mini`, 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const saveDeviceAction = async (
  systemId,
  newDevice,
  onSuccess,
  onError,
  onFinally,
) => {
  const modifiedDevice = { ...newDevice };

  const payload = {
    ...modifiedDevice,
    systemId,
  };

  return buildRequest(
    'core/units',
    newDevice.id ? 'PUT' : 'POST',
    payload,
    false,
  )
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const deleteDeviceAction = (ids, onSuccess, onError, onFinally) => buildRequest('core/units', 'DELETE', { ids })
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchUnassignedDevicesAction = (onSuccess, onError, onFinally) => buildRequest('core/units/orphans', 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchDeviceTimeHistoryAction = (
  deviceId,
  { selectedDates, measureNames },
  onSuccess,
  onError,
  onFinally,
) => {
  const queryParamsMap = {
    start_time: getUTCTimestamp(selectedDates[0]),
    end_time: getUTCTimestamp(selectedDates[1]),
  };

  if (measureNames) {
    queryParamsMap.measure_names = measureNames.join(',');
  }

  const queryParams = transformQueryParams(queryParamsMap);

  return buildRequest(
    `core/units/${deviceId}/logger_time_history/?${queryParams}`,
    'GET',
  )
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const fetchThermostatTimeHistoryAction = (
  deviceId,
  { selectedDates, channelAliases },
  onSuccess,
  onError,
  onFinally,
) => {
  const queryParamsMap = {
    start_time: getUTCTimestamp(selectedDates[0]),
    end_time: getUTCTimestamp(selectedDates[1]),
  };

  if (channelAliases) {
    queryParamsMap.channel_aliases = channelAliases.join(',');
  }

  const queryParams = transformQueryParams(queryParamsMap);

  return buildRequest(
    `core/units/${deviceId}/thermostat_time_history/?${queryParams}`,
    'GET',
  )
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const fetchLoggerDevicesAction = (onSuccess, onError, onFinally) => buildRequest('core/unit_sensor_config', 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchChannelsAction = (onSuccess, onError, onFinally) => buildRequest('core/channels', 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchSensorsAction = (onSuccess, onError, onFinally) => buildRequest('core/sensors', 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchSensorTypesAction = (onSuccess, onError, onFinally) => buildRequest('core/sensor_types', 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchLoggerSensorConfigAction = (loggerId, rcvAlias, onSuccess, onError, onFinally) => buildRequest(`core/unit_sensor_config/${loggerId}?rcvAlias=${rcvAlias}`, 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const saveLoggerSensorConfigAction = (sensorConfigsPayload, onSuccess, onError, onFinally) => buildRequest('core/unit_sensor_config', 'PUT', sensorConfigsPayload, false)
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const saveSensorTypeAction = (sensorTypePayload, onSuccess, onError, onFinally) => buildRequest('core/sensor_types', 'POST', sensorTypePayload, false)
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const updateSensorTypeAction = (sensorTypePayload, onSuccess, onError, onFinally) => buildRequest(`core/sensor_types`, 'PUT', sensorTypePayload, false)
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const deleteSensorTypeAction = (ids, onSuccess, onError, onFinally) => buildRequest('core/sensor_types', 'DELETE', { ids }, false)
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);
