import { buildRequest, getFileFromUrl } from '../../utils';

export const fetchSystemsAction = (siteId, onSuccess, onError, onFinally) => buildRequest(`core/site/${siteId}/systems`, 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchSystemAction = (systemId, onSuccess, onError, onFinally) => buildRequest(`core/systems/${systemId}/`, 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchMiniSystemAction = (
  systemId,
  onSuccess,
  onError,
  onFinally,
) => buildRequest(`core/systems/${systemId}/mini`, 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const saveSystemAction = async (
  siteId,
  newSystem,
  onSuccess,
  onError,
  onFinally,
) => {
  // Create a new object without modifying the original function parameter
  const modifiedSystem = { ...newSystem };
  delete modifiedSystem.status;

  const payload = {
    ...modifiedSystem,
    siteId,
    imagesMetadata: modifiedSystem.files.map((file) => ({
      isPrimary: file.isPrimary,
    })),
  };

  const hasPrimaryImage = payload.files.find((file) => file.isPrimary);
  if (!hasPrimaryImage) {
    payload.imagesMetadata = payload.files.map((file, idx) => ({
      ...file,
      isPrimary: idx === 0,
    }));
  }

  payload.files = await Promise.all(
    payload.files.map(async ({ file, url }) => {
      if (file) {
        return file;
      }

      const fileFromURL = await getFileFromUrl(url, url.slice(0, 5));
      return fileFromURL;
    }),
  );

  return buildRequest(
    'core/systems',
    newSystem.id ? 'PUT' : 'POST',
    payload,
    true,
  )
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const deleteSystemAction = (ids, onSuccess, onError, onFinally) => buildRequest('core/systems', 'DELETE', { ids })
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const fetchSystemSizesAction = (onSuccess, onError, onFinally) => buildRequest(`core/system_sizes`, 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);
