import "./index.scss";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import { useNavigate, useSearchParams } from "react-router-dom";
import logoImg from "../../assets/logo-lg.png";
import BlockUi from "react-block-ui";
import { login } from "../../actions/auth/api";

const Login = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const postLoginUrl = searchParams.get("next") || "/dashboard";

    const isLoginValid = email.trim() && password.trim();

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    
    const handleChangePwd = (event) => {
        setPassword(event.target.value);
    };

    const loginReq = () => {
        setIsLoading(true);

        const onSuccess = () => {
            navigate(postLoginUrl, { replace: true });
        };

        const onError = err => {
            setErrorMsg(err.data ? err.data.message || "Failed to login" : "Failed to login");
        };

        const onFinally = () => {
            setIsLoading(false);
        };

        login({ username: email, password }, onSuccess, onError, onFinally);
    };

    const handleLogin = () => {
        if (!isLoginValid) {
            return;
        }

        loginReq();
    };

    const handleForgotPwd = () => {
        navigate("/forgot-password");
    };

    return (
        <div className="login-page">
            {errorMsg && (
                <Alert variant="danger">{errorMsg}</Alert>
            )}

            <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
                <div className="login-container">
                    <div className="pane logo-pane">
                        <img className="icon" src={logoImg} alt="logo" />
                    </div>

                    <div className="pane form-pane">
                        <div className="login-form">
                            <div className="input">
                                <input
                                    type="text"
                                    onChange={handleChangeEmail}
                                    value={email}
                                    placeholder="Email (handle@mailserver.com)"
                                    onKeyDown={(e) => { e.keyCode === 13 && handleLogin() }} />
                            </div>

                            <div className="input">
                                <input
                                    type="password"
                                    onChange={handleChangePwd}
                                    value={password}
                                    placeholder="Password (*******)"
                                    onKeyDown={(e) => e.keyCode === 13 && handleLogin()} />
                            </div>

                            <button
                                type="button"
                                className={`btn btn-primary ${isLoading || !isLoginValid ? "disabled" : ""} login-btn`}
                                disabled={isLoading || !isLoginValid}
                                onClick={handleLogin}>
                                Sign in
                            </button>

                            <button
                                type="button"
                                className={`btn btn-secondary ${isLoading ? "disabled" : ""} forgot-pwd-btn`}
                                disabled={isLoading}
                                onClick={handleForgotPwd}>
                                Forgot password?
                            </button>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </div>
    );
};

export default Login;
