import { BASE_API_URL } from './constants';

export const logoutUser = () => {
  if (window.location.hash.indexOf('login') !== -1) {
    return;
  }

  window.location.href = `/#/login?next=${window.location.hash.replace('#', '')}`;
};

export const buildRequestData = (method, data, isFormData) => {
  const headers = {};

  if (!isFormData) {
    headers['Content-Type'] = 'application/json';
  }

  const reqData = { headers, credentials: 'include' };

  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    const options = {
      ...reqData,
      method,
    };

    if (!isFormData) {
      options.body = JSON.stringify(data);
    } else {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (data[key] instanceof Array) {
          data[key].forEach((item) => {
            formData.append(
              key,
              item instanceof Object && !(item instanceof File) ? JSON.stringify(item) : item,
            );
          });
        } else {
          formData.append(key, data[key]);
        }
      });

      options.body = formData;
    }

    return options;
  } if (method === 'GET') {
    const queryParams = data ? Object.keys(data)
      .filter((it) => !!data[it])
      .reduce((res, cur) => [...res, `${cur}=${data[cur]}`], [])
      .join('&') : '';

    return { ...reqData, queryParams };
  }

  return reqData;
};

export const buildRequest = (url, method = 'GET', data = {}, isFormData = false) => {
  const reqData = buildRequestData(method, data, isFormData);

  let reqUrl = `${BASE_API_URL}/${url}`;
  if (method === 'GET' && reqData.queryParams) {
    reqUrl += `?${reqData.queryParams}`;
  }

  return fetch(reqUrl, reqData).then(async (res) => {
    if (res.status === 401) {
      logoutUser();
    }

    if (!res.ok) {
      const err = new Error();
      err.status = res.status;

      try {
        err.data = await res.json();
      } catch (exc) {
        err.data = res.statusText;
      }

      throw err;
    }

    if (res.status !== 204) {
      try {
        return res.json();
      } catch (err) {
        return res.text();
      }
    } else {
      return res.text();
    }
  });
};

export const transformQueryParams = (queryParamsMap) => Object
  .keys(queryParamsMap)
  .reduce((result, mapKey) => {
    const mapValue = queryParamsMap[mapKey];
    return [...result, `${mapKey}=${mapValue}`];
  }, [])
  .join('&');

/**
 * Fetches blob data from URL and returns a `File` object
 *
 * @param {URL} url
 * @param {string} name
 * @param {string} defaultType
 * @returns {File}
 */
export const getFileFromUrl = async (url, name, defaultType = 'image/jpeg') => {
  const response = await fetch(url);
  const data = await response.blob();

  return new File([data], name, {
    type: data.type || defaultType,
  });
};

/**
 * Transforms URLs in `item`, adding in `files` key containing `File` objects
 *
 * @param {object} item
 * @param {string} urlsKey: key in which all the URLs are stored for the `item`
 * @returns {object}
 */
export const transformItemFiles = (item, urlsKey = 'imageUrls') => {
  const imageUrls = item[urlsKey] || [];
  return { ...item, files: imageUrls };
};

/**
 * Transforming to lower/upper case or as it is if lower/upper case not intended
 * If both lower/upper case flags are set to true, then only lower case will be applied.
 *
 * @param {Object} string
 * @param {Object} transformations: currently supports `lowerCase` & `upperCase` flags
 */
export const transformString = (string, transformations) => {
  const { lowerCase = false, upperCase = false } = transformations;

  if (!lowerCase && !upperCase) {
    return string;
  }

  return lowerCase ? string.toLowerCase() : string.toUpperCase();
};

export const sortItems = (items, sortKey = 'name') => {
  const sortedItems = items.sort((itemA, itemB) => {
    const itemAName = transformString(itemA[sortKey], { lowerCase: true });
    const itemBName = transformString(itemB[sortKey], { lowerCase: true });

    if (itemAName === itemBName) {
      return 0;
    }

    return itemAName > itemBName ? 1 : -1;
  });

  return sortedItems;
};

export const titleCase = (string) => {
  const numChars = string.length;
  return string.charAt(0).toUpperCase() + string.slice(1, numChars);
};

export const getUTCTimestamp = (date) => date.getTime() - (date.getTimezoneOffset() * 60 * 1000);
