import {
  FETCH_TIME_HISTORY_FAILURE,
  FETCH_TIME_HISTORY_INITIAL,
  FETCH_TIME_HISTORY_LOADING,
  FETCH_TIME_HISTORY_SUCCESS,
} from '../actions/devices/types';
import {
  FAILURE, INITIAL, LOADING, SUCCESS,
} from '../constants';

const initialState = {
  type: INITIAL,
  data: {},
  loadingIds: [],
  errors: [],
};

const reducer = (state = initialState, action = null) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TIME_HISTORY_INITIAL:
      return initialState;

    case FETCH_TIME_HISTORY_LOADING: {
      const updatedLoadingIds = [...state.loadingIds, payload.id];
      return { ...state, type: LOADING, loadingIds: updatedLoadingIds };
    }

    case FETCH_TIME_HISTORY_SUCCESS: {
      const stateData = state.data[payload.selectedDatesKey] || [];

      let updatedData = [...stateData];
      const storedAtIdx = stateData.findIndex((it) => it.id === payload.results.id);

      if (storedAtIdx === -1) {
        updatedData.push(payload.results);
      } else {
        updatedData = [
          ...updatedData.slice(0, storedAtIdx),
          payload.results,
          ...updatedData.slice(storedAtIdx + 1),
        ];
      }

      const updatedLoadingIds = state.loadingIds.filter((id) => id !== payload.results.id);

      return {
        ...state,
        type: SUCCESS,
        data: {
          [payload.selectedDatesKey]: updatedData,
        },
        loadingIds: updatedLoadingIds,
        errors: initialState.errors,
      };
    }

    case FETCH_TIME_HISTORY_FAILURE: {
      const updatedLoadingIds = state.loadingIds.filter((id) => id !== payload.groupId);

      return {
        ...state,
        type: FAILURE,
        loadingIds: updatedLoadingIds,
        errors: [...payload.errors],
      };
    }

    default:
      return state;
  }
};

export default reducer;
