import { buildRequest, getFileFromUrl, transformQueryParams } from '../../utils';

export const fetchSitesAction = (orgId, onSuccess, onError, onFinally) => {
  const queryParamsMap = {
    organization_id: orgId,
  };

  const queryParams = transformQueryParams(queryParamsMap);

  return buildRequest(`core/sites?${queryParams}`, 'GET')
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const fetchSiteAction = (siteId, onSuccess, onError, onFinally) => buildRequest(`core/sites/${siteId}`, 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const saveSiteAction = async (newSite, onSuccess, onError, onFinally) => {
  const payload = {
    ...newSite,
    imagesMetadata: newSite.files.map((file) => ({ isPrimary: file.isPrimary })),
  };

  const hasPrimaryImage = payload.files.find((file) => file.isPrimary);
  if (!hasPrimaryImage) {
    payload.imagesMetadata = payload.files.map((file, idx) => ({ ...file, isPrimary: idx === 0 }));
  }

  payload.files = await Promise.all(
    payload.files.map(async ({ file, url }) => {
      if (file) {
        return file;
      }

      const fileFromURL = await getFileFromUrl(url, url.slice(0, 5));
      return fileFromURL;
    }),
  );

  return buildRequest('core/sites', newSite.id ? 'PUT' : 'POST', payload, true)
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const deleteSiteAction = (siteIds, onSuccess, onError, onFinally) => buildRequest('core/sites', 'DELETE', { siteIds: siteIds.map((it) => parseInt(it, 10)) })
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);
