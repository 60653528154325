import React, { useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { getLineChartData, getLineChartOptions, getSelectedDatesKey, processData } from "../../chart-utils.js";
import { useSelector } from "react-redux";
import BlockUi from "react-block-ui";

const TemperatureChart = ({ selectedDates, measureNameGroup, visibleMetricNames, onFetchMetrics }) => {
  const timeHistoryState = useSelector(state => state.timeHistory);
  const { data: deviceTimeHistory, loadingIds } = timeHistoryState;

  const isLoading = loadingIds.indexOf(measureNameGroup.id) !== -1;

  const selectedDatesKey = getSelectedDatesKey(selectedDates);
  const { timestamps = [], metrics = {} } = useMemo(
    () => (deviceTimeHistory[selectedDatesKey] || []).find(it => it.id === "temperature")?.results || {},
    [selectedDates, deviceTimeHistory]
  );
  const tempLSSeries = useMemo(
    () => processData(timestamps, metrics, "Temperature_LS", "float", true),
    [metrics]
  );
  const tempHSSeries = useMemo(
    () => processData(timestamps, metrics, "Temperature_HS", "float", true),
    [metrics]
  );
  const tempLS2Series = useMemo(
    () => processData(timestamps, metrics, "Temperature_LS2", "float", true),
    [metrics]
  );
  const tempHS2Series = useMemo(
    () => processData(timestamps, metrics, "Temperature_HS2", "float", true),
    [metrics]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const selectedDatesKey = getSelectedDatesKey(selectedDates);

    if (!deviceTimeHistory[selectedDatesKey] || !deviceTimeHistory[selectedDatesKey].find(it => it.id === "temperature")) {
      onFetchMetrics(measureNameGroup);
    }
  }, [selectedDates, deviceTimeHistory]);

  const chartTitles = [];
  const chartSeries = [];

  if (visibleMetricNames.indexOf("Temperature_LS") !== -1) {
    chartTitles.push("Temperature LS");
    chartSeries.push(tempLSSeries);
  }

  if (visibleMetricNames.indexOf("Temperature_HS") !== -1) {
    chartTitles.push("Temperature HS");
    chartSeries.push(tempHSSeries);
  }

  if (visibleMetricNames.indexOf("Temperature_LS2") !== -1) {
    chartTitles.push("Temperature LS2");
    chartSeries.push(tempLS2Series);
  }

  if (visibleMetricNames.indexOf("Temperature_HS2") !== -1) {
    chartTitles.push("Temperature HS2");
    chartSeries.push(tempHS2Series);
  }

  return (
    <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
      <div className="chart-row">
        <div className="chart-line">
          <Line
            width={"100%"}
            height={"400px"}
            data={getLineChartData(chartTitles, chartSeries)}
            options={getLineChartOptions("Refigerant Temperature (°F)", "°F", 20, 140, 10)} />
        </div>
      </div>
    </BlockUi>
  )
};

export default React.memo(TemperatureChart);
