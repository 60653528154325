import { Modal } from "react-bootstrap";

const AddEditOrgModal = ({
    isVisible,
    newOrg,
    onClose,
    onUpdateNewOrg,
    onSaveOrg,
}) => {
    return (
        <Modal show={isVisible} style={{ overflow: "auto" }}>
            <Modal.Header closeButton onClick={onClose}>
                <Modal.Title>
                    {newOrg.id ? "Update organization" : "Add organization"}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="org-modal-wrapper">
                    <div className="add-org-form">
                        <div className="input">
                            <div className="name-wrapper">
                                <div className="text-wrapper">
                                    Organization Name <span style={{ color: "#BF2137" }}>*</span>
                                </div>

                                <input
                                    type="text"
                                    onChange={(e) => onUpdateNewOrg({ ...newOrg, "name": e.target.value })}
                                    value={newOrg.name || ""}
                                    placeholder="Name" />
                            </div>
                        </div>
                    </div>

                    <div className="bottom-controller">
                        <div className="cancel-modal" onClick={onClose}>
                            Cancel
                        </div>

                        <div className="submit-modal" onClick={onSaveOrg}>
                            Submit
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddEditOrgModal;
