export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:8000/api';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDPNp4iBsgZJhxWuwL5N-j_xGXQ3W-V55o';
export const DEFAULT_MAP_ZOOM = 15;

export const DEFAULT_IMAGE_URL = '/favicon.ico';

export const INITIAL = 'initial';
export const LOADING = 'loading';
export const SUCCESS = 'success';
export const FAILURE = 'failure';

export const ADMIN = 'Admin';
export const ORG_ADMIN = 'Org Admin';
export const ORG_USER = 'Org User';
export const USER_ROLES = [
  {
    id: 0,
    label: ADMIN,
  },
  {
    id: 1,
    label: ORG_ADMIN,
  },
  {
    id: 2,
    label: ORG_USER,
  },
];

export const DEVICE_TYPE_OPTIONS = [
  {
    id: 0,
    name: 'Logger',
  },
  {
    id: 1,
    name: 'Thermostat',
  },
];

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const MAX_INACTIVITY_SECONDS = 60 * 60; // 1 hour
