import "react-block-ui/style.css";
import "./index.scss";

import React, { useEffect, useState } from "react";
import { Alert, Image, Modal } from "react-bootstrap";
import { sortItems, transformItemFiles } from "../../utils";
import DefaultLayout from "../../components/layouts/default";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN, DEFAULT_MAP_ZOOM, DEVICE_TYPE_OPTIONS, ORG_ADMIN } from "../../constants";
import { useSelector } from "react-redux";
import ConfirmationPopup from "../../components/popup/confirmation";
import BlockUi from "react-block-ui";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import EditWhiteIcon from "../../assets/icons/editIconWhite.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import DeviceListView from "./device-list-view";
import AddEditDeviceModal from "./add-edit-device-modal";
import {
  deleteDeviceAction,
  deleteSystemAction,
  fetchDevicesAction,
  fetchOrgsAction,
  fetchSitesAction,
  fetchSystemAction,
  saveDeviceAction,
  saveSystemAction
} from "../../actions";
import Carousel from "../../components/carousel";
import AddEditSystemModal from "../system-list/add-edit-system-modal";
import MapMarker from "../../components/map-marker";
import GoogleMap from "../../components/maps";

const DEFAULT_NEW_DEVICE = {
  name: "",
  unitType: DEVICE_TYPE_OPTIONS[1].id,
  externalId: "",
};

const DEFAULT_NEW_SYSTEM = {
  name: "",
  brand: "",
  modelNumber: "",
  latitude: 0,
  longitude: 0,
  files: [],
  refrigerantType: "",
  installedOn: "",
  size: 7.5,
};

const DeviceList = () => {
  const navigate = useNavigate();
  const { id: systemId } = useParams();

  const [system, setSystem] = useState({});
  const [devices, setDevices] = useState([]);
  const [visibleDevices, setVisibleDevices] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isAddDeviceFormVisible, setIsAddDeviceFormVisible] = useState(false);
  const [newDevice, setNewDevice] = useState(DEFAULT_NEW_DEVICE);
  const [tempDevice, setTempDevice] = useState(DEFAULT_NEW_DEVICE);
  const [isConfirmDeleteDeviceVisible, setIsConfirmDeleteDeviceVisible] = useState(false);
  const [showCarouselImage, setShowCarouselImage] = useState(false);
  const [showCarouselImageFile, setShowCarouselImageFile] = useState();
  const [isDeviceListLoading, setIsDeviceListLoading] = useState(false);
  const [isDeviceSaveReqLoading, setIsDeviceSaveReqLoading] = useState(false);
  const [isDeleteDeviceReqLoading, setIsDeleteDeviceReqLoading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isOrgsReqLoading, setIsOrgsReqLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [isSiteListLoading, setIsSiteListLoading] = useState(false);
  const [newSystem, setNewSystem] = useState(DEFAULT_NEW_SYSTEM);
  const [isConfirmDeleteSystemVisible, setIsConfirmDeleteSystemVisible] = useState(false);
  const [isAddSystemFormVisible, setIsAddSystemFormVisible] = useState(false);
  const [toDeleteSystem, setToDeleteSystem] = useState(null);
  const [isDeleteSystemReqLoading, setIsDeleteSystemReqLoading] = useState(false);
  const [isSystemSaveReqLoading, setIsSystemSaveReqLoading] = useState(false);
  const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);

  const userState = useSelector((state) => state.user);
  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;
  const currentUserOrgId = userStateData.organizationId;

  const fetchSystem = () => {
    const onSuccess = (resp) => {
      setErrorMsg("");

      const system = transformItemFiles(resp);
      setSystem(system);
      setNewDevice({
        ...newDevice,
        organizationId: system.organization.id,
        siteId: system.site.id,
        systemId
      });
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch system details");
    };

    fetchSystemAction(systemId, onSuccess, onError, () => { });
  };

  const saveSystem = (event) => {
    setIsSystemSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      setSystem(transformItemFiles(resp));
      setIsAddSystemFormVisible(false);
    };

    const onError = (err) => {
      setErrorMsg("Failed to save system");
    };

    const onFinally = () => {
      setIsSystemSaveReqLoading(false);
    };

    saveSystemAction(newSystem.siteId, newSystem, onSuccess, onError, onFinally);
  };

  const fetchDevices = () => {
    setIsDeviceListLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      setDevices(resp);
      setVisibleDevices(sortItems(resp));
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch devices");
    };

    const onFinally = () => {
      setIsDeviceListLoading(false);
    };

    fetchDevicesAction(systemId, null, onSuccess, onError, onFinally);
  };

  const saveDevice = (updatedDevice) => {
    setIsDeviceSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      let transformedDevices = [];
      const transformedDevice = { ...updatedDevice, ...resp };

      if (!updatedDevice.id) {
        transformedDevices = [transformedDevice, ...devices];
      } else {
        const deviceIdx = devices.findIndex((it) => it.id === updatedDevice.id);

        transformedDevices = [
          ...devices.slice(0, deviceIdx),
          transformedDevice,
          ...devices.slice(deviceIdx + 1),
        ];
      }

      setDevices(transformedDevices);
      setVisibleDevices(sortItems(transformedDevices));
      setNewDevice({
        ...DEFAULT_NEW_DEVICE,
        organizationId: system.organization.id,
        siteId: system.site.id,
        systemId
      });
      setIsAddDeviceFormVisible(false);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to save device");
    };

    const onFinally = () => {
      setIsDeviceSaveReqLoading(false);
    };

    saveDeviceAction(updatedDevice.systemId, { ...updatedDevice }, onSuccess, onError, onFinally);
  };

  const deleteDevice = () => {
    setIsDeleteDeviceReqLoading(true);

    const deviceIds = [tempDevice.id];

    const onSuccess = () => {
      setErrorMsg("");

      const filteredDevices = devices.filter((it) => deviceIds.indexOf(it.id) === -1);

      setDevices(filteredDevices);
      setVisibleDevices(sortItems(filteredDevices));

      setTempDevice(DEFAULT_NEW_DEVICE);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg(["Failed to delete device"]);
    };

    const onFinally = () => {
      setIsDeleteDeviceReqLoading(false);
      setTempDevice(DEFAULT_NEW_DEVICE);
    };

    deleteDeviceAction(deviceIds, onSuccess, onError, onFinally);
  };

  const fetchOrganizations = () => {
    setIsOrgsReqLoading(true);

    const onSuccess = resp => {
      setErrorMsg("");
      setOrgs(resp);
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch organizations");
    };

    const onFinally = () => {
      setIsOrgsReqLoading(false);
    };

    fetchOrgsAction(onSuccess, onError, onFinally);
  };

  const fetchSites = () => {
    setIsSiteListLoading(true);

    const orgId = selectedOrgId ? selectedOrgId : currentUserOrgId;

    const onSuccess = resp => {
      setErrorMsg("");

      const transformedSites = resp.map(it => transformItemFiles(it));
      setSites(transformedSites);
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch sites");
    };

    const onFinally = () => {
      setIsSiteListLoading(false);
    };

    fetchSitesAction(orgId, onSuccess, onError, onFinally);
  };

  const deleteSystem = () => {
    setIsDeleteSystemReqLoading(true);

    const onSuccess = () => {
      navigate(`/sites/${toDeleteSystem.siteId}/systems`);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to delete system");
    };

    const onFinally = () => {
      setIsDeleteSystemReqLoading(false);
    };

    deleteSystemAction([toDeleteSystem.id], onSuccess, onError, onFinally);
  };

  useEffect(() => {
    fetchSystem();
    fetchDevices();
  }, []);

  useEffect(() => {
    if (!selectedOrgId || !isAddSystemFormVisible)
      return;

    fetchSites();
  }, [selectedOrgId, isAddSystemFormVisible]);

  const handleNavigateToTimeHistory = (event, device) => {
    navigate(`/devices/${device.id}/time-history`);
  };

  const handleOptionsAction = (event, item, device) => {
    if (item.id === 1) {
      return handleNavigateToTimeHistory(event, device);
    } else if (item.id === 2) {
      return handleCloneDevice(event, device);
    } else if (item.id === 3) {
      return handleEditDevice(event, device);
    } else if (item.id === 4) {
      return handleDeleteDevice(event, device);
    }
  };

  const handleAddDevice = () => {
    setIsAddDeviceFormVisible(true);
  };

  const handleEditDevice = (event, device) => {
    event.stopPropagation();

    setNewDevice(device);
    setIsAddDeviceFormVisible(true);
  };

  const handleCloneDevice = (event, device) => {
    event.stopPropagation();

    const clonedDevice = { ...device, name: `${device.name} (Clone)`, externalId: "" };
    delete clonedDevice.id;

    saveDevice(clonedDevice);
  };

  const handleDeleteDevice = (event, device) => {
    event.stopPropagation();

    setTempDevice(device);
    setIsConfirmDeleteDeviceVisible(true);
  };

  const handleUpdateNewDevice = (updatedDevice) => {
    setNewDevice(updatedDevice);
  };

  const handleNavigateToOrg = () => {
    if (!system.organization) {
      return;
    }

    navigate(`/?org_id=${system.organization.id}`);
  };

  const handleNavigateToSite = () => {
    if (!system.site) {
      return;
    }

    navigate(`/sites/${system.site.id}/systems`);
  };

  const handleAddEditDeviceModalClose = () => {
    setNewDevice({
      ...DEFAULT_NEW_DEVICE,
      organizationId: system.organization.id,
      siteId: system.site.id,
      systemId
    });
    setIsAddDeviceFormVisible(false);
  };

  const handleShowFullImage = (image) => {
    setShowCarouselImage(true);
    setShowCarouselImageFile(image.url);
  };

  const handleEditSystem = (event, system) => {
    event.stopPropagation();

    setNewSystem(system);
    setIsAddSystemFormVisible(true);

    if (!orgs || orgs.length === 0) {
      if (isUserAdmin) {
        fetchOrganizations();
      }

      setSelectedOrgId(system.organization.id);
      setSelectedSiteId(system.site.id);
    }
  };

  const handleCloseSystemModal = () => {
    setIsAddSystemFormVisible(false);
    setNewSystem(DEFAULT_NEW_SYSTEM);
  };

  const handleUpdateSystem = (field, val) => {
    setNewSystem({ ...newSystem, [field]: val });
  };

  const isLoading = (
    isDeviceListLoading ||
    isDeleteDeviceReqLoading ||
    isDeviceSaveReqLoading ||
    isOrgsReqLoading ||
    isSiteListLoading ||
    isDeleteSystemReqLoading ||
    isSystemSaveReqLoading
  );

  const breadCrumbOptions = [
    {
      id: 1,
      title: system.organization ? system.organization?.name : "...",
      onClick: handleNavigateToOrg,
    },
    {
      id: 2,
      title: system.site ? system.site.name : "...",
      onClick: handleNavigateToSite,
    },
    {
      id: 3,
      title: system ? system.name : "...",
    },
  ];

  const carouselPageSize = document.documentElement.clientWidth < 840 ? 1 : 3;

  return (
    <DefaultLayout pageTitle={"Device list"}>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
        <div className="main-content">
          <div className="site-detail-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions} />

            <div className="button-controller">
              {(isUserAdmin || isOrgAdmin) && (
                <>
                  <div className="edit-button-wrapper" onClick={(event) => handleEditSystem(event, system)}>
                    <div className="edit-button-text">Edit</div>
                    <Image src={EditWhiteIcon} alt="Edit Button" className="edit-icon" />
                  </div>

                  <div className="delete-button-wrapper" onClick={() => {
                    setToDeleteSystem(system);
                    setIsConfirmDeleteSystemVisible(true);
                  }}>
                    <div className="delete-button-text">Delete</div>
                    <Image src={DeleteIcon} alt="Delete Button" className="delete-icon" />
                  </div>
                </>
              )}
            </div>
          </div>

          <Carousel
            imageUrls={system?.imageUrls}
            pageSize={carouselPageSize}
            onImageClick={handleShowFullImage} />

          <div className="list-actions">
            <div className="title">{system ? system.name : ""}</div>

            {(isUserAdmin || isOrgAdmin) && (
              <div className="add-device-btn" onClick={handleAddDevice}>
                Add device
              </div>
            )}
          </div>

          <div className="device-detail-container">
            <div className="device-detail">
              <div className="device-detail-item">
                <span className="label">Brand: </span>
                <span>{system && system.brand ? system.brand : "NA"}</span>
              </div>
              <div className="device-detail-item">
                <span className="label">Model Number: </span>
                <span>{system && system.modelNumber ? system.modelNumber : ""}</span>
              </div>
              <div className="device-detail-item">
                <span className="label">Refrigerant Type: </span>
                <span>{system && system.refrigerantType ? system.refrigerantType : ""}</span>
              </div>
              <div className="device-detail-item">
                <span className="label">Installed On: </span>
                <span>{system && system.installedOn ? system.installedOn.split('T')[0] : ""}</span>
              </div>
              <div className="device-detail-item">
                <span className="label">Size: </span>
                <span>{system && system.size ? system.size : ""}</span>
              </div>
            </div>

            <div className="map">
              <GoogleMap
                lat={system.latitude || 0}
                lng={system.longitude || 0}
                zoom={mapZoom}
                MapMarkerComponent={MapMarker}
              />
            </div>
          </div>

          <div className="device-list-wrap">
            <DeviceListView
              visibleDevices={visibleDevices}
              onOptionsAction={handleOptionsAction} />
          </div>

          <ConfirmationPopup
            show={isConfirmDeleteDeviceVisible}
            title={"Wait, wait, wait..."}
            content={"This will permanently delete the device. Continue?"}
            onNo={() => {
              setIsConfirmDeleteDeviceVisible(false);
              setTempDevice(DEFAULT_NEW_DEVICE);
            }}
            onYes={() => {
              setIsConfirmDeleteDeviceVisible(false);
              deleteDevice();
            }} />

          <Modal centered show={showCarouselImage} className="carousel-image">
            <Modal.Header closeButton onClick={() => setShowCarouselImage(false)} />

            <Modal.Body className="carousel-image-body">
              <Image src={showCarouselImageFile} alt="image" />
            </Modal.Body>
          </Modal>

          <AddEditDeviceModal
            isVisible={isAddDeviceFormVisible}
            newDevice={newDevice}
            addDeviceFormErrors={[]}
            onUpdateNewDevice={handleUpdateNewDevice}
            onSaveDevice={() => saveDevice(newDevice)}
            onClose={handleAddEditDeviceModalClose} />

          <AddEditSystemModal
            isVisible={isAddSystemFormVisible}
            newSystem={newSystem}
            addSystemFormErrors={[]}
            orgs={orgs}
            sites={sites}
            selectedOrgId={selectedOrgId}
            selectedSiteId={selectedSiteId}
            onChangeOrgId={setSelectedOrgId}
            onChangeSiteId={setSelectedSiteId}
            onClose={handleCloseSystemModal}
            onSaveSystem={saveSystem}
            onUpdateNewSystem={handleUpdateSystem}
            onSetNewSystem={setNewSystem} />

          <ConfirmationPopup
            show={isConfirmDeleteSystemVisible}
            title={"Wait, wait, wait..."}
            content={"This will permanently delete the system. Continue?"}
            onNo={() => {
              setIsConfirmDeleteSystemVisible(false);
              setToDeleteSystem(null);
            }}
            onYes={() => {
              setIsConfirmDeleteSystemVisible(false);
              deleteSystem();
            }} />
        </div>
      </BlockUi>
    </DefaultLayout>
  );
};

export default DeviceList;
