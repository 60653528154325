import * as Sentry from '@sentry/react';

const SECURE_ENVS = ['production', 'staging'];
// eslint-disable-next-line max-len
const SENTRY_DSN = 'https://58a4e79e61b233761c35d7e56e2c377f@o4506116173529088.ingest.sentry.io/4506116969594880';

export default SECURE_ENVS.indexOf(process.env.NODE_ENV) !== -1 ? {
  environment: process.env.REACT_APP_ENV,
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [/^https:\/\/app\.hvacvitals\.com/],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
} : {};
