import { Dropdown, Form, Image, Modal } from "react-bootstrap";
import DropDownIcon from "../../assets/icons/dropDownArrow.svg";
import { useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../../hooks/outside-alerter";
import { DEVICE_TYPE_OPTIONS } from "../../constants";
import { fetchOrgsAction, fetchSitesAction, fetchSystemsAction } from "../../actions";

const THERMOSTAT_BRAND_DROPDOWN_OPTIONS = [
  { id: 1, name: 'Honeywell TCC' },
  { id: 2, name: 'Honeywell Resideo' },
  { id: 3, name: 'Ecobee' },
];

const AddEditDeviceModal = ({
  isVisible,
  newDevice,
  addDeviceFormErrors,
  onClose,
  onUpdateNewDevice,
  onSaveDevice,
}) => {
  const deviceTypeDropdownRef = useRef();
  const orgDropdownRef = useRef();
  const siteDropdownRef = useRef();
  const systemDropdownRef = useRef();
  const thermostatBrandRef = useRef();

  const [isDeviceTypeDropdownVisible, setIsDeviceTypeDropdownVisible] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [isOrgDropdownVisible, setIsOrgDropdownVisible] = useState(false);
  const [sites, setSites] = useState([]);
  const [isSiteDropdownVisible, setIsSiteDropdownVisible] = useState(false);
  const [systems, setSystems] = useState([]);
  const [isSystemDropdownVisible, setIsSystemDropdownVisible] = useState(false);
  const [isThermostatBrandDropdownVisible, setIsThermostatBrandDropdownVisible] = useState(false);

  const fetchOrgs = () => {
    const onSuccess = (resp) => {
      setOrgs(resp);
    };

    const onError = () => { };
    const onFinally = () => { };

    fetchOrgsAction(onSuccess, onError, onFinally);
  };

  const fetchSites = (orgId) => {
    const onSuccess = (resp) => {
      setSites(resp);
    };

    const onError = () => { };
    const onFinally = () => { };

    fetchSitesAction(orgId, onSuccess, onError, onFinally);
  };

  const fetchSystems = (siteId) => {
    const onSuccess = (resp) => {
      setSystems(resp);
    };

    const onError = () => { };
    const onFinally = () => { };

    fetchSystemsAction(siteId, onSuccess, onError, onFinally);
  };

  useEffect(() => {
    fetchOrgs();
  }, []);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (newDevice.organizationId) {
      fetchSites(newDevice.organizationId);
    }
  }, [isVisible, newDevice.organizationId]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (newDevice.siteId) {
      fetchSystems(newDevice.siteId);
    }
  }, [isVisible, newDevice.siteId]);

  useOutsideAlerter(deviceTypeDropdownRef, (event) => {
    if (
      deviceTypeDropdownRef.current &&
      !deviceTypeDropdownRef.current.contains(event.target) &&
      isDeviceTypeDropdownVisible
    ) {
      setIsDeviceTypeDropdownVisible(false);
    }
  });

  useOutsideAlerter(orgDropdownRef, (event) => {
    if (
      orgDropdownRef.current &&
      !orgDropdownRef.current.contains(event.target) &&
      isOrgDropdownVisible
    ) {
      setIsOrgDropdownVisible(false);
    }
  });

  useOutsideAlerter(siteDropdownRef, (event) => {
    if (
      siteDropdownRef.current &&
      !siteDropdownRef.current.contains(event.target) &&
      isSiteDropdownVisible
    ) {
      setIsSiteDropdownVisible(false);
    }
  });

  useOutsideAlerter(systemDropdownRef, (event) => {
    if (
      systemDropdownRef.current &&
      !systemDropdownRef.current.contains(event.target) &&
      isSystemDropdownVisible
    ) {
      setIsSystemDropdownVisible(false);
    }
  });

  useOutsideAlerter(thermostatBrandRef, (event) => {
    if (
      thermostatBrandRef.current &&
      !thermostatBrandRef.current.contains(event.target) &&
      isThermostatBrandDropdownVisible
    ) {
      setIsThermostatBrandDropdownVisible(false);
    }
  });

  const deviceTypeDropdownItems = DEVICE_TYPE_OPTIONS.map(deviceTypeOption => {
    const isChecked = deviceTypeOption.id === newDevice.unitType;

    return (
      <Dropdown.Item
        key={deviceTypeOption.id}
        onClick={() => onUpdateNewDevice({ ...newDevice, unitType: deviceTypeOption.id })}>
        <Form.Check checked={isChecked} type="checkbox" label={deviceTypeOption.name} readOnly />
      </Dropdown.Item>
    );
  });

  const thermostatBrandDropdownItems = THERMOSTAT_BRAND_DROPDOWN_OPTIONS.map(thermostatBrand => {
    const { id, name } = thermostatBrand;

    const isChecked = name === newDevice.thermostatBrand;

    return (
      <Dropdown.Item
        key={id}
        onClick={() => onUpdateNewDevice({ ...newDevice, "thermostatBrand": name })}>
        <Form.Check checked={isChecked} type="checkbox" label={name} readOnly />
      </Dropdown.Item>
    );
  });

  const orgDropdownItems = orgs.map(org => {
    const { id, name } = org;

    const isChecked = id === newDevice.organizationId;

    return (
      <Dropdown.Item
        key={id}
        onClick={() => onUpdateNewDevice({
          ...newDevice,
          organizationId: id,
          organizationName: name,
          siteId: null
        })}>
        <Form.Check checked={isChecked} type="checkbox" label={name} readOnly />
      </Dropdown.Item>
    );
  });

  const siteDropdownItems = sites.map(site => {
    const { id, name } = site;

    const isChecked = id === newDevice.siteId;

    return (
      <Dropdown.Item
        key={id}
        onClick={() => onUpdateNewDevice({
          ...newDevice,
          siteId: id,
          siteName: name,
          systemId: null
        })}>
        <Form.Check checked={isChecked} type="checkbox" label={name} readOnly />
      </Dropdown.Item>
    );
  });

  const systemDropdownItems = systems.map(system => {
    const { id, name } = system;

    const isChecked = id === newDevice.systemId;

    return (
      <Dropdown.Item
        key={id}
        onClick={() => onUpdateNewDevice({ ...newDevice, systemId: id, systemName: name })}>
        <Form.Check checked={isChecked} type="checkbox" label={name} readOnly />
      </Dropdown.Item>
    );
  });

  const selectedDeviceType = DEVICE_TYPE_OPTIONS.find(it => it.id === newDevice.unitType);
  const selectedThermostatBrand = THERMOSTAT_BRAND_DROPDOWN_OPTIONS.find(
    it => it.name === newDevice.thermostatBrand
  );
  const selectedOrg = orgs.find(it => it.id === newDevice.organizationId);
  const selectedSite = sites.find(it => it.id === newDevice.siteId);
  const selectedSystem = systems.find(it => it.id === newDevice.systemId);

  return (
    <Modal show={isVisible} style={{ overflow: "auto" }}>
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>
          {newDevice.id ? "Update device" : "Add device"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="device-modal-wrapper">
          <div className="add-device-form">
            <div className="input">
              <div className="dropdown-wrapper">
                <div className="text-wrapper">
                  Device Type
                </div>

                <Dropdown
                  className="header-dropdown"
                  onClick={() => setIsDeviceTypeDropdownVisible(!isDeviceTypeDropdownVisible)}
                  ref={deviceTypeDropdownRef}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedDeviceType ? selectedDeviceType.name : "No device type selected"}

                    <Image
                      src={DropDownIcon}
                      style={{
                        transform: isDeviceTypeDropdownVisible ? "rotate(180deg)" : "",
                      }}
                      width={20}
                      height={10}
                      alt="DropDown Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{deviceTypeDropdownItems}</Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="name-wrapper">
                <div className="text-wrapper">
                  Device Name <span style={{ color: "#BF2137" }}>*</span>
                </div>

                <input
                  type="text"
                  onChange={(e) => onUpdateNewDevice({ ...newDevice, "name": e.target.value })}
                  value={newDevice.name || ""}
                  placeholder="Name" />
              </div>

              <div className="name-wrapper">
                <div className="text-wrapper">
                  External ID
                </div>

                <input
                  type="text"
                  onChange={(e) => onUpdateNewDevice({ ...newDevice, "externalId": e.target.value })}
                  value={newDevice.externalId || ""}
                  placeholder="External ID" />
              </div>

              {newDevice.unitType === DEVICE_TYPE_OPTIONS[1].id && (
                <>
                  <div className="dropdown-wrapper">
                    <div className="text-wrapper">
                      Brand Name
                    </div>

                    <Dropdown
                      className="header-dropdown"
                      onClick={() => setIsThermostatBrandDropdownVisible(!isThermostatBrandDropdownVisible)}
                      ref={thermostatBrandRef}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {selectedThermostatBrand ? selectedThermostatBrand.name : "No brand selected"}

                        <Image
                          src={DropDownIcon}
                          style={{
                            transform: isThermostatBrandDropdownVisible ? "rotate(180deg)" : "",
                          }}
                          width={20}
                          height={10}
                          alt="DropDown Icon" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>{thermostatBrandDropdownItems}</Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="name-wrapper">
                    <div className="text-wrapper">
                      Model Number
                    </div>

                    <input
                      type="text"
                      onChange={(e) => onUpdateNewDevice({ ...newDevice, thermostatModelNumber: e.target.value })}
                      value={newDevice.thermostatModelNumber || ""}
                      placeholder="Model number" />
                  </div>
                </>
              )}

              <div className="dropdown-wrapper">
                <div className="text-wrapper">
                  Organization <span style={{ color: "#BF2137" }}>*</span>
                </div>

                <Dropdown
                  className="header-dropdown"
                  onClick={() => setIsOrgDropdownVisible(!isOrgDropdownVisible)}
                  ref={orgDropdownRef}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedOrg ? selectedOrg.name : "No organization selected"}

                    <Image
                      src={DropDownIcon}
                      style={{
                        transform: isOrgDropdownVisible ? "rotate(180deg)" : "",
                      }}
                      width={20}
                      height={10}
                      alt="DropDown Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{orgDropdownItems}</Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="dropdown-wrapper">
                <div className="text-wrapper">
                  Site <span style={{ color: "#BF2137" }}>*</span>
                </div>

                <Dropdown
                  className="header-dropdown"
                  onClick={() => setIsSiteDropdownVisible(!isSiteDropdownVisible)}
                  ref={siteDropdownRef}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedSite ? selectedSite.name : "No site selected"}

                    <Image
                      src={DropDownIcon}
                      style={{
                        transform: isSiteDropdownVisible ? "rotate(180deg)" : "",
                      }}
                      width={20}
                      height={10}
                      alt="DropDown Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{siteDropdownItems}</Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="dropdown-wrapper">
                <div className="text-wrapper">
                  System <span style={{ color: "#BF2137" }}>*</span>
                </div>

                <Dropdown
                  className="header-dropdown"
                  onClick={() => setIsSystemDropdownVisible(!isSystemDropdownVisible)}
                  ref={systemDropdownRef}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedSystem ? selectedSystem.name : "No system selected"}

                    <Image
                      src={DropDownIcon}
                      style={{
                        transform: isSystemDropdownVisible ? "rotate(180deg)" : "",
                      }}
                      width={20}
                      height={10}
                      alt="DropDown Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>{systemDropdownItems}</Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="form-errors">
                {addDeviceFormErrors.map((errorMsg) => {
                  return (
                    <div key={errorMsg.slice(6)} className="form-error">
                      {errorMsg}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="bottom-controller">
            <div className="cancel-modal" onClick={onClose}>
              Cancel
            </div>

            <div className="submit-modal" onClick={onSaveDevice}>
              Submit
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditDeviceModal;
