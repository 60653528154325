import "./confirmation.scss";

import { Button, Modal } from "react-bootstrap";

const ConfirmationPopup = ({ show, title, content, onNo, onYes }) => {
  return (
    <Modal show={show}>
      <Modal.Header closeButton onClick={onNo}>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {content}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onNo}>
          No
        </Button>

        <Button variant="primary" onClick={onYes}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationPopup;
