import {
  FETCH_USER_FAILURE, FETCH_USER_INITIAL, FETCH_USER_LOADING, FETCH_USER_SUCCESS,
} from './types';

export const fetchUserInitial = () => ({
  type: FETCH_USER_INITIAL,
});

export const fetchUserLoading = () => ({
  type: FETCH_USER_LOADING,
});

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: {
    user,
  },
});

export const fetchUserFailure = (errors) => ({
  type: FETCH_USER_FAILURE,
  payload: {
    errors,
  },
});
