import "./index.scss";

import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { DEFAULT_MAP_ZOOM, GOOGLE_MAPS_API_KEY } from "../../constants";

const GoogleMap = ({ lat, lng, zoom, MapMarkerComponent, mapMarkerComponentProps, onSetMarker }) => {
  const [coords, setCoords] = useState({ lat, lng });

  useEffect(() => {
    setCoords({ lat, lng });
  }, [lat, lng]);

  const mapOptions = {
    styles: [
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#bbbbbb" }]
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#bbbbbb" }]
      }
    ]
  };

  const onClick = ({lat: newLat, lng: newLng}) => {
    onSetMarker({ lat: newLat, lng: newLng });
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
      center={coords}
      zoom={zoom}
      defaultZoom={zoom}
      options={mapOptions}
      onClick={onClick}
    >
      <MapMarkerComponent {...coords} {...mapMarkerComponentProps} />
    </GoogleMapReact>
  );
};

GoogleMap.defaultProps = {
  lat: 0,
  lng: 0,
  zoom: 12,
  mapMarkerComponentProps: {}
};

export default GoogleMap;
