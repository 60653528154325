import {
  FETCH_TIME_HISTORY_FAILURE,
  FETCH_TIME_HISTORY_INITIAL,
  FETCH_TIME_HISTORY_LOADING,
  FETCH_TIME_HISTORY_SUCCESS,
} from './types';

export const fetchTimeHistoryInitial = () => ({
  type: FETCH_TIME_HISTORY_INITIAL,
});

export const fetchTimeHistoryLoading = (id) => ({
  type: FETCH_TIME_HISTORY_LOADING,
  payload: { id },
});

export const fetchTimeHistorySuccess = (selectedDatesKey, results) => ({
  type: FETCH_TIME_HISTORY_SUCCESS,
  payload: {
    selectedDatesKey,
    results,
  },
});

export const fetchTimeHistoryFailure = (groupId, errors) => ({
  type: FETCH_TIME_HISTORY_FAILURE,
  payload: {
    groupId,
    errors,
  },
});
