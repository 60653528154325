import DropDownIcon from "../../assets/icons/dropDownArrow.svg";
import { Button, Dropdown, Form, Image, Modal } from "react-bootstrap";
import FileUploader from "../../components/inputs/file_uploader";
import GoogleMap from "../../components/maps";
import { useEffect, useRef, useState } from "react";
import MapMarker from "../../components/map-marker";
import { DEFAULT_MAP_ZOOM } from "../../constants";
import useOutsideAlerter from "../../hooks/outside-alerter";
import { fetchOrgsAction } from "../../actions";

const AddEditSiteModal = ({
  isVisible,
  newSite,
  addSiteFormErrors,
  onClose,
  onUpdateNewSite,
  onSaveSite,
}) => {
  const orgDropdownRef = useRef();

  const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);
  const [orgs, setOrgs] = useState([]);
  const [isOrgDropdownVisible, setIsOrgDropdownVisible] = useState(false);

  const fetchOrgs = () => {
    const onSuccess = (resp) => {
      setOrgs(resp);
    };

    const onError = () => { };
    const onFinally = () => { };

    fetchOrgsAction(onSuccess, onError, onFinally);
  };

  useEffect(() => {
    fetchOrgs();
  }, []);

  useOutsideAlerter(orgDropdownRef, (event) => {
    if (
      orgDropdownRef.current &&
      !orgDropdownRef.current.contains(event.target) &&
      isOrgDropdownVisible
    ) {
      setIsOrgDropdownVisible(false);
    }
  });

  const handleGetCurrentLocation = ({ updateFunction, prevValue }) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { coords } = position;
        const { latitude, longitude, accuracy } = coords;
        const { accuracy: prevAccuracy } = prevValue;

        // if current accuracy is more than the previously set, then do nothing
        if (prevAccuracy <= accuracy) {
          return;
        }

        const updatedValue = {
          ...prevValue,
          latitude,
          longitude,
          accuracy
        };

        updateFunction(updatedValue);
        setMapZoom(DEFAULT_MAP_ZOOM);
      }, (error) => {
        console.error(error.message);
      }, { maximumAge: 10, timeout: 5000, enableHighAccuracy: true }
    );
  };

  const orgDropdownItems = orgs.map(org => {
    const { id, name } = org;

    const isChecked = id === newSite.organizationId;

    return (
      <Dropdown.Item
        key={id}
        onClick={() => onUpdateNewSite({ ...newSite, organizationId: id })}>
        <Form.Check checked={isChecked} type="checkbox" label={name} readOnly />
      </Dropdown.Item>
    );
  });

  const selectedOrg = orgs.find(it => it.id === newSite.organizationId);

  return (
    <Modal show={isVisible} style={{ overflow: "hidden" }}>
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>
          {newSite.id ? "Update site" : "Add a site"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="site-modal-wrapper">
          <div className="add-site-form">
            <div className="input">
              <div className="name-wrapper">
                <div className="dropdown-wrapper">
                  <div className="text-wrapper">
                    Organization <span style={{ color: "#BF2137" }}>*</span>
                  </div>

                  <Dropdown
                    className="header-dropdown"
                    onClick={() => setIsOrgDropdownVisible(!isOrgDropdownVisible)}
                    ref={orgDropdownRef}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {selectedOrg ? selectedOrg.name : "No organization selected"}

                      <Image
                        src={DropDownIcon}
                        style={{
                          transform: isOrgDropdownVisible ? "rotate(180deg)" : "",
                        }}
                        width={20}
                        height={10}
                        alt="DropDown Icon" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>{orgDropdownItems}</Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="text-wrapper">
                  Site Name <span style={{ color: "#BF2137" }}>*</span>
                </div>

                <input
                  type="text"
                  onChange={(e) => onUpdateNewSite({ ...newSite, name: e.target.value })}
                  value={newSite.name || ""}
                  placeholder="Name"
                />
              </div>

              <div className="name-wrapper">
                <div className="text-wrapper">
                  Address (Optional)
                </div>

                <input
                  type="text"
                  onChange={(e) => onUpdateNewSite({ ...newSite, address: e.target.value })}
                  value={newSite.address || ""}
                  placeholder="Address"
                />
              </div>

              <div className="name-wrapper">
                <div className="text-wrapper">
                  Site Photos
                </div>

                <FileUploader
                  selectedFiles={newSite.files}
                  buttonLabel="Drag & Drop Media"
                  onFileChange={(files) => onUpdateNewSite({ ...newSite, files })}
                  isMultipleUpload
                  acceptedFileTypes={["image/*"]}
                />
              </div>

              <div className="form-errors">
                {addSiteFormErrors.map(errorMsg => {
                  return (
                    <div key={errorMsg.slice(6)} className="form-error">
                      {errorMsg}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="map-wrapper">
              <div className="text-wrapper">
                Site Location
              </div>

              <div className="map-container">
                <div className="map">
                  <GoogleMap
                    lat={newSite.latitude || 0}
                    lng={newSite.longitude || 0}
                    zoom={mapZoom}
                    MapMarkerComponent={MapMarker}
                    onSetMarker={({ lat, lng }) => onUpdateNewSite({ ...newSite, latitude: lat, longitude: lng })} />
                </div>

                <Button
                  className="map-current-location-btn"
                  onClick={() => handleGetCurrentLocation(
                    { updateFunction: onUpdateNewSite, prevValue: newSite }
                  )}>
                  Current Location
                  <i className="fa fa-crosshairs map-crosshairs" />
                </Button>
              </div>
            </div>
          </div>

          <div className="bottom-controller">
            <div className="cancel-modal" onClick={onClose}>
              Cancel
            </div>

            <div className="submit-modal" onClick={onSaveSite}>
              Submit
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditSiteModal;
