import Grid from "../../components/grid";
import SystemGridItem from "./system-grid-item";

const SystemGridView = ({ visibleSystems, onOpenMap, onOptionsAction }) => {
  const handleRenderSystemGridItem = (system, index) => {
    return (
      <SystemGridItem
        idx={index}
        system={system}
        onOpenMap={onOpenMap}
        onOptionsAction={onOptionsAction} />
    );
  };

  return (
    <div className="system-list">
      {visibleSystems.length === 0 && (
        <div className="no-systems">No systems found</div>
      )}

      <Grid
        items={visibleSystems}
        className={"system-grid"}
        renderItem={handleRenderSystemGridItem} />
    </div>
  );
};

export default SystemGridView;
