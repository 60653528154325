import DemandMetricCard from "./demand-metric-card";
import "./index.scss";

import MetricDetailCard from "./metric-detail-card";

const FUNCTION_MODE_MAP = {
  0: "Auto",
  1: "Heat",
  2: "Off",
  3: "Cool",
};

const FAN_MODE_MAP = {
  0: "Auto",
  1: "On",
  2: "Circulate",
};

const ThermostatDetail = ({ device, system }) => {
  const { metrics } = device;

  const {
    ActualTemperature = 32.0,
    HeatSetpoint = 32.0,
    CoolSetpoint = 32.0,
    FunctionMode = 0,
    FanMode = 0,
    FanDemand = false,
    HeatDemand = false,
    CoolDemand = false,
  } = metrics || {};

  const metricsConfig = [
    {
      metricId: "ActualTemperature",
      name: "Actual Temperature",
      value: ActualTemperature,
      unit: "\u00B0F",
    },
    {
      metricId: "HeatSetpoint",
      name: "Heat Setpoint Temperature",
      value: HeatSetpoint,
      unit: "\u00B0F",
    },
    {
      metricId: "CoolSetpoint",
      name: "Cool Setpoint Temperature",
      value: CoolSetpoint,
      unit: "\u00B0F",
    },
    {
      metricId: "FunctionMode",
      name: "Function Mode",
      value: FUNCTION_MODE_MAP[FunctionMode] || "-",
      unit: "",
    },
    {
      metricId: "FanMode",
      name: "Fan Mode",
      value: FAN_MODE_MAP[FanMode] || "-",
      unit: "",
    },
    {
      metricId: "FanDemand",
      name: "Fan Demand",
      value: FanDemand,
      unit: "",
      isDemandMetric: true,
    },
    {
      metricId: "HeatDemand",
      name: "Heat Demand",
      value: HeatDemand,
      unit: "",
      isDemandMetric: true,
    },
    {
      metricId: "CoolDemand",
      name: "Cool Demand",
      value: CoolDemand,
      unit: "",
      isDemandMetric: true,
    },
  ];

  const metricItems = metricsConfig
    .map((metricConfig) => {
      const { metricId: id, isDemandMetric, name, value } = metricConfig;

      if (isDemandMetric) {
        return <DemandMetricCard key={id} name={name} value={value} />;
      }

      return <MetricDetailCard key={id} metricConfig={metricConfig} />;
    });

  return (
    <div className="metric-detail-card-wrapper">
      {metricItems}        
    </div>
  );
};

export default ThermostatDetail;
