import {
  FETCH_USER_FAILURE, FETCH_USER_INITIAL, FETCH_USER_LOADING, FETCH_USER_SUCCESS,
} from '../actions/user/types';
import {
  FAILURE, INITIAL, LOADING, SUCCESS,
} from '../constants';

const initialState = {
  type: INITIAL,
  data: {},
  errors: [],
};

const userReducer = (state = initialState, action = null) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_INITIAL:
      return initialState;

    case FETCH_USER_LOADING:
      return {
        ...state,
        type: LOADING,
      };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        type: SUCCESS,
        data: { ...payload.user },
        errors: initialState.errors,
      };

    case FETCH_USER_FAILURE:
      return {
        ...state,
        type: FAILURE,
        errors: [...payload.errors],
      };

    default:
      return state;
  }
};

export default userReducer;
