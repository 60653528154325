import { Alert } from "react-bootstrap";
import DefaultLayout from "../../components/layouts/default";
import BlockUi from "react-block-ui";
import BreadCrumbs from "../../components/breadcrumbs";
import DeviceListView from "./device-list-view";
import { useEffect, useState } from "react";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import { sortItems } from "../../utils";
import { fetchLoggerDevicesAction } from "../../actions";

const LoggerManagement = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [visibleDevices, setVisibleDevices] = useState([]);
  const [isDeviceListLoading, setIsDeviceListLoading] = useState(false);

  const fetchLoggerDevices = () => {
    setIsDeviceListLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      setVisibleDevices(sortItems(resp));
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch devices");
    };

    const onFinally = () => {
      setIsDeviceListLoading(false);
    };

    fetchLoggerDevicesAction(onSuccess, onError, onFinally);
  };

  useEffect(() => {
    fetchLoggerDevices();
  }, []);

  const isLoading = isDeviceListLoading;

  const breadCrumbOptions = [
    {
      id: 1,
      title: "Logger Management"
    },
  ];

  return (
    <DefaultLayout pageTitle={"Logger Management"}>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
        <div className="main-content">
          <div className="site-detail-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions} />
          </div>

          <div className="device-list-wrap">
            <DeviceListView visibleDevices={visibleDevices} />
          </div>
        </div>
      </BlockUi>
    </DefaultLayout>
  );
};

export default LoggerManagement;
