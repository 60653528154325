import List from "../../components/list";
import CrossIcon from "../../assets/icons/crossIcon.svg";
import RightTick from "../../assets/icons/rightTick.svg";
import EditBlackIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import { format } from "date-fns";

const CARD_OPTIONS = [
  {
    id: 1,
    title: "Clone",
  },
  {
    id: 2,
    title: "Edit",
    icon: EditBlackIcon,
  },
  {
    id: 3,
    title: "Delete",
    icon: DeleteIcon,
  },
];

const transformSystems = (systems) => {
  return systems.map((system) => {
    return {
      ...system,
      status: system.status === 1 ? "Active" : "Inactive",
      health: system.status,
    };
  });
};

const SystemListView = ({ visibleSystems, onOptionsAction }) => {
  const navigate = useNavigate();

  const handleNavigateToSystemDetail = (systemId) => {
    navigate(`/systems/${systemId}`);
  };

  const handleRenderItem = (item, columnId) => {
    const idx = visibleSystems.findIndex((it) => it.id === item.id);

    if (columnId === "actions") {
      return (
        <div className="list-body-cell actions-column" key={`${item.id}-${columnId}`}>
          {CARD_OPTIONS.map((cardOption) => {
            return (
              <div
                className="more-options-row"
                key={cardOption.title}
                onClick={(event) =>
                  onOptionsAction(
                    event,
                    cardOption,
                    visibleSystems[idx]
                  )
                }
              >
                {cardOption.icon && <Image src={cardOption.icon} alt="Icon" />}

                <div className="more-options-text">
                  {cardOption.title}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    if (columnId === "health") {
      return (
        <div
          className="list-body-cell health-column"
          key={`${item.id}-${columnId}`}
        >
          {item[columnId] === 1 ? (
            <img className="healthy" src={RightTick} />
          ) : (
            <img className={"unhealthy"} src={CrossIcon} />
          )}
        </div>
      );
    }

    if (columnId === "status") {
      return (
        <div className="status-card">
          <div
            className={`list-body-cell ${columnId}-column ${item[columnId] || ""
              }`}
            key={`${item.id}-${columnId}`}
          >
            {item[columnId] || ""}
          </div>
        </div>
      );
    }

    if (columnId === "refrigerantType") {
      return (
        <div
          className={`list-body-cell ${columnId}-column ${item[columnId] || ""
            }`}
          key={`${item.id}-${columnId}`}
        >
          {item[columnId] || ""}
        </div>
      );
    }

    if (columnId === "name") {
      return (
        <div
          className={`list-body-cell ${columnId}-column`}
          key={`${item.id}-${columnId}`}
        >
          <div
            className="system-name"
            onClick={() => handleNavigateToSystemDetail(item.id)}
          >
            {item[columnId] || ""}
          </div>
        </div>
      );
    }

    if (columnId === "size") {
      return (
        <div
          className={`list-body-cell ${columnId}-column ${item[columnId] || ""
            }`}
          key={`${item.id}-${columnId}`}
        >
          {item[columnId] || "-"} Ton
        </div>
      );
    }

    if (columnId === "installedOn") {
      return (
        <div
          className={`list-body-cell ${columnId}-column ${item[columnId] || ""
            }`}
          key={`${item.id}-${columnId}`}
        >
          {item[columnId] ? format(new Date(item[columnId]), "PP") : "-"}
        </div>
      );
    }

    if (["actualTemp", "heatSetpoint", "coolSetpoint"].indexOf(columnId) !== -1) {
      return (
        <div className={`list-body-cell ${columnId}-column`} key={`${item.id}-${columnId}`}>
          <div className="thermostat-temp">
            {item[columnId] ? `${item[columnId]}\u00B0F` : "-"}
          </div>
        </div>
      )
    }
  };

  const listColumns = [
    {
      id: "name",
      label: "System Name",
      canSort: false,
    },
    {
      id: "size",
      label: "Size",
      canSort: false,
    },
    {
      id: "installedOn",
      label: "Install Date",
      canSort: false,
    },
    {
      id: "refrigerantType",
      label: "Refrigerant Type",
      canSort: false,
    },
    {
      id: "heatSetpoint",
      label: "Heat Setpoint",
    },
    {
      id: "actualTemp",
      label: "Current Temp",
    },
    {
      id: "coolSetpoint",
      label: "Cool Setpoint",
    },
    {
      id: "status",
      label: "Status",
      canSort: false,
    },
    {
      id: "health",
      label: "Health",
      canSort: false,
    },
    {
      id: "actions",
      label: "",
      canSort: false,
    },
  ];

  return (
    <div className="system-list">
      {visibleSystems.length === 0 && (
        <div className="no-systems">No systems found</div>
      )}

      {visibleSystems.length > 0 && (
        <List
          className={"system-list-container"}
          canSelectMultipleRows={false}
          columns={listColumns}
          items={transformSystems(visibleSystems)}
          renderItem={handleRenderItem}
        />
      )}
    </div>
  );
};

export default SystemListView;
