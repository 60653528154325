import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ADMIN, ORG_ADMIN, SUCCESS } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDeviceTimeHistoryAction,
  fetchLoggerSensorConfigAction,
  fetchTimeHistoryFailure,
  fetchTimeHistoryLoading,
  fetchTimeHistorySuccess
} from '../../actions';
import DutyCycleChart from './duty-cycle-chart';
import RunTimeChart from './run-time-chart';
import GaugeCharts from './gauge-charts';
import CompressorDemandChart from './compressor-demand-chart';
import CompressorCurrentChart from './compressor-current-chart';
import FanCurrentChart from './fan-current-chart';
import PressureChart from './pressure-chart';
import TemperatureChart from './temperature-chart';
import { getSelectedDatesKey } from "../../chart-utils.js";
import { LazyLoader } from '../../components/lazy-loader';
import ReactGridLayout from 'react-grid-layout';

const DEFAULT_METRIC_NAMES = [
  'Enclosure_Temp',
  'Enclosure_Humidity',
  'Enclosure_Pressure',
  'rssi',
];

const MEASURE_NAMES = [
  {
    id: "gauge",
    names: [
      "rssi",
      "Enclosure_Humidity",
      "Enclosure_Temp",
    ],
  },
  {
    id: "duty_cycle",
    names: [
      "Demand_Signal",
      "Compressor_Current",
      "Compressor_Current2"
    ],
  },
  {
    id: "compressor_demand",
    names: [
      "Demand_Signal",
    ],
  },
  {
    id: "compressor_current",
    names: [
      "Compressor_Current",
      "Compressor_Current2"
    ],
  },
  {
    id: "misc",
    names: ["Fan_Current"],
  },
  {
    id: "pressure",
    names: [
        "Pressure_LS",
        "Pressure_HS",
        "Pressure_LS2",
        "Pressure_HS2",
    ],
  },
  {
    id: "temperature",
    names: [
      "Temperature_LS",
      "Temperature_HS",
      "Temperature_LS2",
      "Temperature_HS2"
    ]
  }
];

const LoggerTimeHistory = ({ layoutWidth, selectedDates, onSetErrorMsg }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [visibleMetricNames, setVisibleMetricNames] = useState([]);

  const userState = useSelector(state => state.user);

  const { type: userStateType, data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;
  const isUserReqSuccess = userStateType === SUCCESS;

  const fetchDeviceTimeHistory = (dates, measureNamesGroup) => {
    dispatch(fetchTimeHistoryLoading(measureNamesGroup.id));

    const onSuccess = resp => {
      onSetErrorMsg("");

      dispatch(fetchTimeHistorySuccess(getSelectedDatesKey(selectedDates), {
        id: measureNamesGroup.id,
        results: resp
      }));
    };

    const onError = err => {
      onSetErrorMsg(err?.data?.message || "Failed to fetch metrics");
      dispatch(fetchTimeHistoryFailure(measureNamesGroup.id, [err?.data?.message]));
    };

    const onFinally = () => {};

    const measureNames = measureNamesGroup.names.filter(it => visibleMetricNames.indexOf(it) !== -1);

    if (measureNames.length === 0) {
      dispatch(fetchTimeHistorySuccess(getSelectedDatesKey(selectedDates), {
        id: measureNamesGroup.id,
        results: []
      }));
      return;
    }

    fetchDeviceTimeHistoryAction(
      id,
      {
        selectedDates: dates,
        measureNames,
      },
      onSuccess,
      onError,
      onFinally
    );
  };

  const fetchLoggerConfig = () => {
    const onSuccess = (resp) => {
      onSetErrorMsg("");
      const metricNames = resp.map(it => it.channelName).filter(it => it);
      setVisibleMetricNames([...metricNames, ...DEFAULT_METRIC_NAMES]);
    };

    const onError = (err) => {
      console.log(err);
      onSetErrorMsg("Failed to fetch logger config");
    };

    const onFinally = () => {};

    fetchLoggerSensorConfigAction(id, true, onSuccess, onError, onFinally);
  };

  useEffect(() => {
    if (isUserReqSuccess && (isUserAdmin || isOrgAdmin)) {
      fetchLoggerConfig();
    }
  }, [isUserReqSuccess, isUserAdmin, isOrgAdmin]);

  const handleFetchMetrics = useCallback((measureNameGroup) => {
    fetchDeviceTimeHistory(selectedDates, measureNameGroup);
  }, [selectedDates, visibleMetricNames]);

  const chartList = [
    {
        Component: GaugeCharts,
        measureNameGroupId: "gauge",
        rowHeight: 250,
    },
    {
        Component: RunTimeChart,
        measureNameGroupId: "duty_cycle",
        rowHeight: 400,
    },
    {
        Component: DutyCycleChart,
        measureNameGroupId: "duty_cycle",
        rowHeight: 400,
    },
    {
        Component: CompressorDemandChart,
        measureNameGroupId: "compressor_demand",
        rowHeight: 400,
    },
    {
        Component: CompressorCurrentChart,
        measureNameGroupId: "compressor_current",
        rowHeight: 400,
    },
    {
        Component: FanCurrentChart,
        measureNameGroupId: "misc",
        rowHeight: 400,
    },
    {
        Component: PressureChart,
        measureNameGroupId: "pressure",
        rowHeight: 400,
    },
    {
        Component: TemperatureChart,
        measureNameGroupId: "temperature",
        rowHeight: 400,
    }
  ].filter(it => {
    const { measureNameGroupId } = it;
    const measureNameGroup = MEASURE_NAMES.find(itt => itt.id === measureNameGroupId);
    const measureNames = measureNameGroup.names.filter(itt => visibleMetricNames.indexOf(itt) !== -1);

    return measureNames.length > 0;
  });

  const chartRowRenderer = ({ key, index, style, isScrolling }) => {
    const { Component, measureNameGroupId, extraProps = {} } = chartList[index];
    const measureNameGroup = MEASURE_NAMES.find(it => it.id === measureNameGroupId);

    return (
      <LazyLoader key={key} style={style} data-griditem-key={index}>
        <Component
          selectedDates={selectedDates}
          measureNameGroup={measureNameGroup}
          onFetchMetrics={handleFetchMetrics}
          isScrolling={isScrolling}
          visibleMetricNames={visibleMetricNames}
          {...extraProps} />
      </LazyLoader>
    );
  };

  const reactGridLayoutObj = chartList.map((chartListItem, idx) => {
    const layoutObj = {
        i: idx.toString(),
        x: 0,
        y: idx,
    };

    if (document.documentElement.clientWidth >= 480) {
      return {
          ...layoutObj,
          h: idx !== 0 ? 11 : 8,
          w: 23,
      };
    } else {
      return {
          ...layoutObj,
          h: idx !== 0 ? 12 : 22,
          w: 21,
      };
    }
  });

  const charts = chartList.map((_, idx) => chartRowRenderer({
    key: idx.toString(),
    index: idx,
    style: {},
  }));

  return (
    <ReactGridLayout
      width={layoutWidth + 40}
      isDraggable={false}
      isResizable={false}
      containerPadding={[0, 0]}
      useCSSTransforms={false}
      margin={[8, 8]}
      cols={24}
      rowHeight={30}
      layout={reactGridLayoutObj}
      isBounded={false}>
      {charts}
    </ReactGridLayout>
  );
};

export default LoggerTimeHistory;
