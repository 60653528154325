import { Alert, Button, Image } from "react-bootstrap";
import DefaultLayout from "../../components/layouts/default";
import "./index.scss";
import BlockUi from "react-block-ui";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import EditIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import { useEffect, useState } from "react";
import List from "../../components/list";
import { deleteSensorTypeAction, fetchSensorTypesAction, saveSensorTypeAction, updateSensorTypeAction } from "../../actions";
import ConfirmationPopup from "../../components/popup/confirmation";
import AddSensorTypeModal from "../logger-management-detail/add-sensor-type-modal";

const DEFAULT_SENSOR_TYPE = {
  name: "",
};

const SensorTypeManagement = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [sensorTypes, setSensorTypes] = useState([]);
  const [editModeSensorTypes, setEditModeSensorTypes] = useState([]);
  const [toDeleteSensorType, setToDeleteSensorType] = useState(null);
  const [isConfirmDeleteSensorTypeVisible, setIsConfirmDeleteSensorTypeVisible] = useState(false);
  const [isSensorTypesListReqLoading, setIsSensorTypesListReqLoading] = useState(false);
  const [isSensorTypesSaveReqLoading, setIsSensorTypesSaveReqLoading] = useState(false);
  const [isDeleteSensorTypeReqLoading, setIsDeleteSensorTypeReqLoading] = useState(false);
  const [isConfirmEditSensorTypeVisible, setIsConfirmEditSensorTypeVisible] = useState(false);
  const [toEditSensorType, setToEditSensorType] = useState(null);
  const [isAddSensorTypeFormVisible, setIsAddSensorTypeFormVisible] =
    useState(false);
  const [newSensorType, setNewSensorType] = useState(DEFAULT_SENSOR_TYPE);
  const [isSensorTypeSaveReqLoading, setIsSensorTypeSaveReqLoading] =
    useState(false);

  const fetchSensorTypes = () => {
    setIsSensorTypesListReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
      setSensorTypes(resp);
    };

    const onError = (err) => {
      setErrorMsg("Failed to fetch sensor types");
    };

    const onFinally = () => {
      setIsSensorTypesListReqLoading(false);
    };

    fetchSensorTypesAction(onSuccess, onError, onFinally);
  };

  const saveSensorType = (id) => {
    setIsSensorTypesSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const updatedSensorTypeIdx = sensorTypes.findIndex(it => it.id === id);
      const editModeSensorTypeIdx = editModeSensorTypes.findIndex(it => it.id === id);
      setSensorTypes([
        ...sensorTypes.slice(0, updatedSensorTypeIdx),
        resp,
        ...sensorTypes.slice(updatedSensorTypeIdx + 1)
      ]);
      setEditModeSensorTypes([
        ...editModeSensorTypes.slice(0, editModeSensorTypeIdx),
        ...editModeSensorTypes.slice(editModeSensorTypeIdx + 1),
      ]);
    };

    const onError = (err) => {
      setErrorMsg("Failed to save sensor type");
    };

    const onFinally = () => {
      setIsSensorTypesSaveReqLoading(false);
    };

    const sensorTypePayload = editModeSensorTypes.find(it => it.id === id);
    updateSensorTypeAction(sensorTypePayload, onSuccess, onError, onFinally);
  };

  const deleteSensorType = () => {
    setIsDeleteSensorTypeReqLoading(true);

    const onSuccess = () => {
      const deletedSensorTypeIdx = sensorTypes.findIndex(it => it.id === toDeleteSensorType.id);
      setSensorTypes([
        ...sensorTypes.slice(0, deletedSensorTypeIdx),
        ...sensorTypes.slice(deletedSensorTypeIdx + 1),
      ]);
    };

    const onError = () => {
      setErrorMsg("Failed to delete sensor type");
    };

    const onFinally = () => {
      setIsDeleteSensorTypeReqLoading(false);
    };

    deleteSensorTypeAction([toDeleteSensorType.id], onSuccess, onError, onFinally);
  };

  useEffect(() => {
    fetchSensorTypes();
  }, []);

  const onUpdateSensorType = (itemId, columnId, value) => {
    const itemIdx = editModeSensorTypes.findIndex(it => it.id === itemId);
    const item = {
      ...editModeSensorTypes[itemIdx],
      [columnId]: value,
    };

    setEditModeSensorTypes([
      ...editModeSensorTypes.slice(0, itemIdx),
      item,
      ...editModeSensorTypes.slice(itemIdx + 1),
    ]);
  };

  const handleRenderItem = (item, columnId) => {
    const { id } = item;
    const editModeSensorType = editModeSensorTypes.find(it => it.id === id);

    if (editModeSensorType && columnId === 'name') {
      return (
        <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column input`}>
          <input
            type="text"
            onChange={(e) => onUpdateSensorType(id, columnId, e.target.value)}
            value={editModeSensorType[columnId] || ""}  />
        </div>
      );
    }

    if (columnId === 'actions') {
      return (
        <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column input`}>
          {!editModeSensorType && (
            <div
              className="btn btn-light edit-sensor-type-btn"
              onClick={() => setEditModeSensorTypes([...editModeSensorTypes, item])}>
              <Image src={EditIcon} alt="Icon" />
              <div className="btn-text">Edit</div>
            </div>
          )}

          {editModeSensorType && (
            <>
              <div
                className="btn btn-primary save-sensor-type-btn"
                onClick={() => {
                  setIsConfirmEditSensorTypeVisible(true);
                  setToEditSensorType(id);
                }}>
                <i className="fa fa-save save-icon" style={{ fontSize: "18px" }} />
                <div className="btn-text">Save</div>
              </div>

              <div
                className="btn btn-light cancel-sensor-type-btn"
                onClick={() => {
                  const editModeSensorTypeIdx = editModeSensorTypes.findIndex(it => it.id === id);
                  setEditModeSensorTypes([
                    ...editModeSensorTypes.slice(0, editModeSensorTypeIdx),
                    ...editModeSensorTypes.slice(editModeSensorTypeIdx + 1),
                  ]);
                }}>
                <i className="fa fa-times cancel-edit-icon" />
                <div className="btn-text">Cancel</div>
              </div>
            </>
          )}

          <div
            className="btn btn-light delete-sensor-type-btn"
            onClick={() => {
              setToDeleteSensorType(item);
              setIsConfirmDeleteSensorTypeVisible(true);
            }}>
            <Image src={DeleteIcon} alt="Icon" />
            <div className="btn-text">Delete</div>
          </div>
        </div>
      );
    }

    return (
      <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column`}>
        {item[columnId] || "-"}
      </div>
    );
  };

  const handleUpdateSensorType = (key, value) => {
    const updatedSensorType = {
      ...newSensorType,
      [key]: value,
    };

    setNewSensorType(updatedSensorType);
  };

  const handleSaveSensorType = () => {
    setIsSensorTypeSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
      setSensorTypes([...sensorTypes, resp]);
      setIsAddSensorTypeFormVisible(false);
    };

    const onError = (err) => {
      setErrorMsg("Failed to save sensor type");
    };

    const onFinally = () => {
      setIsSensorTypeSaveReqLoading(false);
      setNewSensorType(DEFAULT_SENSOR_TYPE);
    };

    saveSensorTypeAction(newSensorType, onSuccess, onError, onFinally);
  };

  const handleCloseSensorTypeModal = () => {
    setIsAddSensorTypeFormVisible(false);
    setNewSensorType(DEFAULT_SENSOR_TYPE);
  };

  const listColumns = [
    {
      id: "id",
      label: "ID",
      canSort: false,
    },
    {
      id: "name",
      label: "Sensor Type",
      canSort: false,
    },
    {
      id: "actions",
      label: "Actions",
    }
  ];

  const breadCrumbOptions = [
    {
      id: 1,
      title: "Sensor Type Management"
    },
  ];

  const isLoading = (
    isSensorTypesListReqLoading ||
    isSensorTypesSaveReqLoading ||
    isDeleteSensorTypeReqLoading ||
    isSensorTypeSaveReqLoading
  );

  return (
    <DefaultLayout>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait">
        <div className="main-content">
          <div className="sensor-type-list-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions} />

            <Button
              className="add-sensor-type-btn site-detail-header-btn"
              variant="secondary"
              onClick={() => setIsAddSensorTypeFormVisible(true)}
            >
              New Sensor Type
            </Button>
          </div>

          <div className="sensor-type-list-wrap">
            <List
              canSelectMultipleRows={false}
              columns={listColumns}
              items={sensorTypes}
              renderItem={handleRenderItem} />
          </div>

          <ConfirmationPopup
            show={isConfirmDeleteSensorTypeVisible}
            title={"Wait, wait, wait..."}
            content={"This will permanently delete the sensor type. Continue?"}
            onNo={() => {
              setIsConfirmDeleteSensorTypeVisible(false);
              setToDeleteSensorType(null);
            }}
            onYes={() => {
              setIsConfirmDeleteSensorTypeVisible(false);
              deleteSensorType();
            }} />

          <ConfirmationPopup
            show={isConfirmEditSensorTypeVisible}
            title={"Wait, wait, wait..."}
            content={"This might cause a loss of metrics. Continue?"}
            onNo={() => {
              setIsConfirmEditSensorTypeVisible(false);
              setToEditSensorType(null);
            }}
            onYes={() => {
              setIsConfirmEditSensorTypeVisible(false);
              saveSensorType(toEditSensorType);
            }} />
        </div>

        <AddSensorTypeModal
          title={"Add sensor type"}
          isVisible={isAddSensorTypeFormVisible}
          sensorType={newSensorType}
          onUpdateSensorType={handleUpdateSensorType}
          onSaveSensorType={handleSaveSensorType}
          onClose={handleCloseSensorTypeModal}
        />
      </BlockUi>
    </DefaultLayout>
  );
};

export default SensorTypeManagement;
