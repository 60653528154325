import "./file_uploader.scss";

import React from "react";
import { useRef } from "react";
import { Button, Image } from "react-bootstrap";
import UploadIcon from "../../assets/icons/uploadIcon.svg";
import PlusIcon from "../../assets/icons/plusIcon.svg";
import TrashIcon from "../../assets/icons/trashIcon.svg";

const FileUploader = ({ selectedFiles, buttonLabel, onFileChange, isMultipleUpload, acceptedFileTypes }) => {
  const fileInput = useRef(null);

  const handleFileInput = (event) => {
    const uploadedFiles = [];

    Array.from(event.target.files)
      .forEach((file, idx) => {
        uploadedFiles.push({
          file,
          isPrimary: selectedFiles ? selectedFiles.length === 0 && idx === 0 : false,
          url: URL.createObjectURL(file),
        });
      });

    onFileChange([...(selectedFiles || []), ...uploadedFiles]);
    fileInput.current.value = null;
  };

  const handleDelete = (index) => {
    let updatedFiles = selectedFiles.filter((_, idx) => idx !== index);
    const hasPrimaryFile = updatedFiles.find(file => file.isPrimary);

    if (!hasPrimaryFile) {
      updatedFiles = updatedFiles.map((file, idx) => ({ ...file, isPrimary: idx === 0 }));
    }

    onFileChange(updatedFiles);
    fileInput.current.value = null;
  };

  return (
    <div className="file-uploader">
      <input
        ref={fileInput}
        type="file"
        accept={acceptedFileTypes.join(",")}
        multiple={isMultipleUpload}
        onChange={handleFileInput}
        style={{ display: "none" }} />
      {selectedFiles && selectedFiles.length > 0 
        ? <div className="upload-multi-btn-wrapper">
            <div className="upload-multi-btn" onClick={() => fileInput.current && fileInput.current.click()}>
              <Image src={PlusIcon}  className="upload-plus-icon" alt="plus icon" />
            </div>
            {
              selectedFiles.map((image,idx)=>{
                return (
                  <div key={idx.toString()} className="uploaded-image-preview-wrapper">
                    <Image
                      className="uploaded-image-preview"
                      src={image.url || URL.createObjectURL(image.file)}
                      alt="Preview Image" />
                    <Image
                      src={TrashIcon}
                      className="trash-icon"
                      alt="Trash Icon"
                      onClick={() => handleDelete(idx)} />
                  </div>
                );
              })
            }
          </div>
        : <div
            onClick={() => fileInput.current && fileInput.current.click()}
            className="upload-btn">
            <div className="upload-btn-wrapper">
                <Image src={UploadIcon} className="upload-img" alt="upload icon"/>
                <div className="upload-text">{buttonLabel}</div>
                <Button className="internal-upload-btn">Upload</Button>
            </div>
          </div>
      }
    </div>
  );
};

FileUploader.defaultProps = {
  buttonLabel: "Upload a file",
  acceptedFileTypes: ["*"]
};

export default FileUploader;
