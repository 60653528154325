import { Button, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ADMIN, ORG_ADMIN } from "../../constants";
import EditBlackIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import DefaultSiteCardImage from "../../assets/images/default-site-card-image.svg";
import ThreeDotIcon from "../../assets/icons/threeDotsMore.svg";
import LocationIcon from "../../assets/icons/locationIcon.svg";
import { useNavigate } from "react-router";
import { useRef, useState } from "react";
import useOutsideAlerter from "../../hooks/outside-alerter";

const CARD_OPTIONS = [
  {
    id: 1,
    title: "Clone",
  },
  {
    id: 2,
    title: "Edit",
    icon: EditBlackIcon,
  },
  {
    id: 3,
    title: "Delete",
    icon: DeleteIcon,
  },
];

const SystemGridItem = ({ system, idx, onOpenMap, onOptionsAction }) => {
  const navigate = useNavigate();
  const optionsMenuRef = useRef();

  const userState = useSelector(state => state.user);

  const [openMoreMenu, setOpenMoreMenu] = useState(-1);

  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;

  const { id, name, imageUrls, latitude, longitude } = system;
  const primaryImageUrlObj = imageUrls.find(
    (it) => it.isPrimary
  );
  const primaryImageUrl = primaryImageUrlObj
    ? primaryImageUrlObj.url
    : DefaultSiteCardImage;

  useOutsideAlerter(optionsMenuRef, (event) => {
    if (openMoreMenu !== -1) {
      setOpenMoreMenu(-1);
    }
  });

  const handleNavigateToSystemDetail = (systemId) => {
    navigate(`/systems/${systemId}`);
  };

  const handleOpenOptions = (event, idx) => {
    event.stopPropagation();

    if (openMoreMenu === idx) {
      setOpenMoreMenu(-1);
    } else {
      setOpenMoreMenu(idx);
    }
  };

  const isOptionsOpen = (idx) => {
    return openMoreMenu === idx;
  };

  return (
    <li className={"system"} key={idx.toString()}>
      <div className="site-row">
        <div className="site-name-wrap" onClick={() => handleNavigateToSystemDetail(id)}>
          <img className="site-img" src={primaryImageUrl} alt="site-img" />
        </div>

        <div className="actions-wrap">
          <div className="site-name">{name}</div>

          <div className="icon-wrapper">
            <Button className="location-btn" onClick={() => onOpenMap({ latitude, longitude })}>
              <img
                className="location-icon"
                src={LocationIcon}
                alt="Location"
              />
            </Button>

            {(isUserAdmin || isOrgAdmin) && (
              <div className="listcard-more-wrapper">
                <img
                  className="three-dot-more-icon"
                  src={ThreeDotIcon}
                  alt="More"
                  onClick={(event) =>
                    handleOpenOptions(event, idx)
                  } />

                {isOptionsOpen(idx) && (
                  <div className="more-options-wrapper" ref={optionsMenuRef}>
                    {CARD_OPTIONS
                      .map((item) => {
                        return (
                          <div
                            className="more-options-row"
                            key={item.title}
                            onClick={(event) =>
                              onOptionsAction(
                                event,
                                item,
                                system
                              )
                            }>
                            {item.icon && <Image src={item.icon} alt="Icon" />}

                            <div className="more-options-text">
                              {item.title}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default SystemGridItem;
