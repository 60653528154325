import "./index.scss";

import DefaultLayout from "../../components/layouts/default";
import { Alert, Button, Image } from "react-bootstrap";
import BlockUi from "react-block-ui";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import EditIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import { useEffect, useState } from "react";
import List from "../../components/list";
import { deleteSystemAction, fetchOrgsAction, fetchSitesAction, fetchSystemsAction, saveSystemAction } from "../../actions";
import ConfirmationPopup from "../../components/popup/confirmation";
import { useNavigate } from "react-router-dom";
import AddEditSystemModal from "../system-list/add-edit-system-modal";
import { transformItemFiles } from "../../utils";
import { useSelector } from "react-redux";
import { ADMIN } from "../../constants";
import { format } from "date-fns";

const DEFAULT_NEW_SYSTEM = {
  name: "",
  brand: "",
  modelNumber: "",
  latitude: 0,
  longitude: 0,
  files: [],
  refrigerantType: "",
  installedOn: "",
  size: 7.5,
};

const SystemManagement = () => {
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");
  const [systems, setSystems] = useState([]);
  const [toDeleteSystem, setToDeleteSystem] = useState(null);
  const [isConfirmDeleteSystemVisible, setIsConfirmDeleteSystemVisible] = useState(false);
  const [isSystemListReqLoading, setIsSystemListReqLoading] = useState(false);
  const [isSystemSaveReqLoading, setIsSystemSaveReqLoading] = useState(false);
  const [isDeleteSystemReqLoading, setIsDeleteSystemReqLoading] = useState(false);
  const [isAddSystemFormVisible, setIsAddSystemFormVisible] = useState(false);
  const [newSystem, setNewSystem] = useState(DEFAULT_NEW_SYSTEM);
  const [orgs, setOrgs] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isOrgsReqLoading, setIsOrgsReqLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [isSiteListLoading, setIsSiteListLoading] = useState(false);

  const userState = useSelector((state) => state.user);
  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const currentUserOrgId = userStateData.organizationId;

  const fetchSystems = () => {
    setIsSystemListReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
      const transformedSystems = resp.map((it) => transformItemFiles(it));
      setSystems(transformedSystems);
    };

    const onError = (err) => {
      setErrorMsg("Failed to fetch systems");
    };

    const onFinally = () => {
      setIsSystemListReqLoading(false);
    };

    fetchSystemsAction("all", onSuccess, onError, onFinally);
  };

  const fetchOrganizations = () => {
    setIsOrgsReqLoading(true);

    const onSuccess = resp => {
      setErrorMsg("");
      setOrgs(resp);
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch organizations");
    };

    const onFinally = () => {
      setIsOrgsReqLoading(false);
    };

    fetchOrgsAction(onSuccess, onError, onFinally);
  };

  const fetchSites = () => {
    setIsSiteListLoading(true);

    const orgId = selectedOrgId ? selectedOrgId : currentUserOrgId;

    const onSuccess = resp => {
      setErrorMsg("");

      const transformedSites = resp.map(it => transformItemFiles(it));
      setSites(transformedSites);
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch sites");
    };

    const onFinally = () => {
      setIsSiteListLoading(false);
    };

    fetchSitesAction(orgId, onSuccess, onError, onFinally);
  };

  const saveSystem = (updatedSystem) => {
    setIsSystemSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const transformedSystem = transformItemFiles({ ...updatedSystem, ...resp });
      const updatedSystemIdx = systems.findIndex(
        it => parseInt(it.id, 10) === parseInt(updatedSystem.id, 10)
      );

      if (updatedSystemIdx === -1) {
        setSystems([transformedSystem, ...systems]);
      } else {
        setSystems([
          ...systems.slice(0, updatedSystemIdx),
          transformedSystem,
          ...systems.slice(updatedSystemIdx + 1)
        ]);
      }

      setIsAddSystemFormVisible(false);
    };

    const onError = (err) => {
      setErrorMsg("Failed to save system");
    };

    const onFinally = () => {
      setIsSystemSaveReqLoading(false);
    };

    saveSystemAction(selectedSiteId || updatedSystem.site.id, { ...updatedSystem  }, onSuccess, onError, onFinally);
  };

  const deleteSystem = () => {
    setIsDeleteSystemReqLoading(true);

    const onSuccess = () => {
      const deletedSystemIdx = systems.findIndex(it => it.id === toDeleteSystem.id);
      setSystems([
        ...systems.slice(0, deletedSystemIdx),
        ...systems.slice(deletedSystemIdx + 1),
      ]);

      setIsConfirmDeleteSystemVisible(false);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to delete system");
    };

    const onFinally = () => {
      setIsDeleteSystemReqLoading(false);
    };

    deleteSystemAction([toDeleteSystem.id], onSuccess, onError, onFinally);
  };

  const handleNavigateToSystemDetail = (systemId) => {
    navigate(`/systems/${systemId}`);
  };

  useEffect(() => {
    fetchSystems();
  }, []);

  useEffect(() => {
    if (!selectedOrgId || !isAddSystemFormVisible)
      return;

    fetchSites();
  }, [selectedOrgId, isAddSystemFormVisible]);

  const handleAddSystem = () => {
    setIsAddSystemFormVisible(true);

    if (!orgs || orgs.length === 0) {
      if (isUserAdmin) {
        fetchOrganizations();
      }
    }
  };

  const handleEditSystem = (event, system) => {
    event.stopPropagation();

    setNewSystem(system);
    setIsAddSystemFormVisible(true);

    if (!orgs || orgs.length === 0) {
      if (isUserAdmin) {
        fetchOrganizations();
      }

      setSelectedOrgId(system.organization.id);
      setSelectedSiteId(system.site.id);
    }
  };

  const handleCloneSystem = (event, system) => {
    event.stopPropagation();

    const clonedSystem = { ...system, name: `${system.name} (Clone)` };
    delete clonedSystem.id;

    saveSystem(clonedSystem);
  };

  const handleRenderItem = (item, columnId) => {
    const { id } = item;

    if (columnId === 'actions') {
      return (
        <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column input`}>
          <div
            className="btn btn-light clone-system-btn"
            onClick={(e) => handleCloneSystem(e, item)}>
            <div className="btn-text">Clone</div>
          </div>

          <div
            className="btn btn-light edit-system-btn"
            onClick={(e) => handleEditSystem(e, item)}>
            <Image src={EditIcon} alt="Icon" />
            <div className="btn-text">Edit</div>
          </div>

          <div
            className="btn btn-light delete-system-btn"
            onClick={() => {
              setToDeleteSystem(item);
              setIsConfirmDeleteSystemVisible(true);
            }}>
            <Image src={DeleteIcon} alt="Icon" />
            <div className="btn-text">Delete</div>
          </div>
        </div>
      );
    }

    if (columnId === "size") {
      return (
        <div
          className={`list-body-cell ${columnId}-column ${
            item[columnId] || ""
          }`}
          key={`${item.id}-${columnId}`}
        >
          {item[columnId] || "-"} Ton
        </div>
      );
    }

    if (columnId === "installedOn") {
      return (
        <div
          className={`list-body-cell ${columnId}-column ${
            item[columnId] || ""
          }`}
          key={`${item.id}-${columnId}`}
        >
          {item[columnId] ? format(new Date(item[columnId]), "PP") : "-"}
        </div>
      );
    }

    if (columnId === "name") {
      return (
        <div className={`list-body-cell ${columnId}-column`} key={`${item.id}-${columnId}`}>
          <div className="system-name" onClick={() => handleNavigateToSystemDetail(item.id)}>
            {item[columnId] || ""}
          </div>
        </div>
      );
    }

    return (
      <div key={`${id}-${columnId}`} className={`list-body-cell ${columnId}-column`}>
        {item[columnId] || "-"}
      </div>
    );
  };

  const handleUpdateSystem = (field, val) => {
    setNewSystem({ ...newSystem, [field]: val });
  };

  const handleCloseSystemModal = () => {
    setIsAddSystemFormVisible(false);
    setNewSystem(DEFAULT_NEW_SYSTEM);
  };

  const listColumns = [
    {
      id: "name",
      label: "System Name",
    },
    {
      id: "size",
      label: "Size",
    },
    {
      id: "installedOn",
      label: "Install Date",
    },
    {
      id: "brand",
      label: "Brand",
    },
    {
      id: "modelNumber",
      label: "Model",
    },
    {
      id: "refrigerantType",
      label: "Refrigerant Type",
    },
    {
      id: "actions",
      label: "Actions",
    }
  ];

  const breadCrumbOptions = [
    {
      id: 1,
      title: "System Management"
    },
  ];

  const isLoading = (
    isSystemSaveReqLoading ||
    isSystemListReqLoading ||
    isDeleteSystemReqLoading ||
    isOrgsReqLoading ||
    isSiteListLoading
  );

  return (
    <DefaultLayout>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait">
        <div className="main-content">
          <div className="system-list-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions} />

            <Button
              className="add-system-btn site-detail-header-btn"
              variant="secondary"
              onClick={handleAddSystem}
            >
              New System
            </Button>
          </div>

          <div className="system-list-wrap">
            <List
              canSelectMultipleRows={false}
              columns={listColumns}
              items={systems}
              renderItem={handleRenderItem} />
          </div>

          <ConfirmationPopup
            show={isConfirmDeleteSystemVisible}
            title={"Wait, wait, wait..."}
            content={"This will permanently delete the system. Continue?"}
            onNo={() => {
              setIsConfirmDeleteSystemVisible(false);
              setToDeleteSystem(null);
            }}
            onYes={() => {
              setIsConfirmDeleteSystemVisible(false);
              deleteSystem();
            }} />
        </div>

        <AddEditSystemModal
          isVisible={isAddSystemFormVisible}
          newSystem={newSystem}
          addSystemFormErrors={[]}
          orgs={orgs}
          sites={sites}
          selectedOrgId={selectedOrgId}
          selectedSiteId={selectedSiteId}
          onChangeOrgId={setSelectedOrgId}
          onChangeSiteId={setSelectedSiteId}
          onUpdateNewSystem={handleUpdateSystem}
          onSaveSystem={() => saveSystem(newSystem)}
          onClose={handleCloseSystemModal}
          onSetNewSystem={setNewSystem} />
      </BlockUi>
    </DefaultLayout>
  );
};

export default SystemManagement;
