import { buildRequest } from '../../utils';

export const login = (payload, onSuccess, onError, onFinally) => buildRequest('token/', 'POST', payload)
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const logout = (onSuccess, onError) => buildRequest('logout/', 'DELETE')
  .then(onSuccess)
  .catch(onError);

export const forgotPassword = (payload, onSuccess, onError, onFinally) => buildRequest('forgot_password/', 'POST', payload)
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);
