import { SORT_DIRECTIONS } from '../../constants';
import { buildRequest, transformQueryParams } from '../../utils';

export const fetchUsersAction = (sortColumn, sortDirection, onSuccess, onError, onFinally) => {
  const queryParamsMap = {
    sortBy: `${sortDirection === SORT_DIRECTIONS.ASC ? '' : '-'}${sortColumn}`,
  };

  const queryParams = transformQueryParams(queryParamsMap);

  return buildRequest(`core/users?${queryParams}`, 'GET')
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const fetchUserAction = (onSuccess, onError) => buildRequest('core/user/', 'GET')
  .then(onSuccess)
  .catch(onError);

export const addUpdateUserAction = (newUser, onSuccess, onError, onFinally) => buildRequest('core/users', newUser.id ? 'PUT' : 'POST', newUser)
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const deleteUsersAction = (userIds, onSuccess, onError, onFinally) => buildRequest('core/users', 'DELETE', { userIds })
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const updateUserStatusAction = (user, onSuccess, onError, onFinally) => buildRequest('core/users', 'PUT', { id: user.id, isActive: !user.isActive })
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const resetPasswordAction = (payload, onSuccess, onError, onFinally) => buildRequest('core/users', 'PUT', payload)
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);
