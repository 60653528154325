import "./index.scss";

import React from "react";
import { SORT_DIRECTIONS } from "../../constants";

const List = ({ className, columns, items, renderItem, canSelectMultipleRows }) => {
  const checkboxColumn = {
    id: "checkbox",
    label: "",
  };

  const listColumns = canSelectMultipleRows ? [checkboxColumn, ...columns] : columns;

  const listHeaderItems = listColumns.map(column => {
    const { id, label, canSort = false, sortDirection = null, onSort } = column;

    return (
      <div
        key={id}
        className={`list-header-item ${id}-column ${canSort ? "sortable-column" : ""}`}
        onClick={() => canSort && onSort(id)}>
        <div className="list-header-item-label">{label}</div>
        <i
          className={`${sortDirection ? "arrow medium-bold" : `${canSort ? "arrow" : ""}`}
          ${sortDirection === SORT_DIRECTIONS.ASC ? "up" : "down"}`} />
      </div>
    );
  });

  const listBodyItems = items.map(item => {
    const { id: itemId, isActive } = item;

    return (
      <li key={itemId} className={`list-body-row ${isActive ? "active" : ""}`}>
        {listColumns.map(column => {
            const { id: columnId } = column;

            return renderItem(item, columnId);
          })
        }
      </li>
    );
  });

  if (listBodyItems.length === 0) {
    listBodyItems.push(<li key="emptyList">
      <div className="list-body-empty">There's nothing here.</div>
    </li>);
  }

  return (
    <div className={`list-container ${className ? className : ""}`}>
      <div className="list-header">
        {listHeaderItems}
      </div>

      <ul className="list-body">
        {listBodyItems}
      </ul>
    </div>
  );
};

List.defaultProps = {
  canSelectMultipleRows: false,
  classes: {
    row: ""
  }
};

export default List;
