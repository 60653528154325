import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchUserInitial } from "../actions/user/state";
import { logout } from "../actions/auth/api";

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const postLoginUrl = searchParams.get("next") || "";

    const logoutUser = () => {
        const onSuccess = () => {
            dispatch(fetchUserInitial());
            navigate(`/login?next=${postLoginUrl}`, { replace: true });
        };

        const onError = err => {
            console.log(err);
        };

        logout(onSuccess, onError);
    };

    useEffect(() => {
        logoutUser();
    }, []);

    return null;
};

export default Logout;
