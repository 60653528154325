import { useSelector } from "react-redux";
import List from "../../components/list";
import { ADMIN, DEVICE_TYPE_OPTIONS, ORG_ADMIN } from "../../constants";
import CrossIcon from "../../assets/icons/crossIcon.svg";
import RightTick from "../../assets/icons/rightTick.svg";
import ClockIcon from "../../assets/icons/clockIcon.svg";
import EditBlackIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useMemo, useRef, useState } from "react";
import useOutsideAlerter from "../../hooks/outside-alerter";

const CARD_OPTIONS = [
  {
    id: 1,
    title: "History",
    icon: ClockIcon,
  },
  {
    id: 2,
    title: "Clone",
  },
  {
    id: 3,
    title: "Edit",
    icon: EditBlackIcon,
  },
  {
    id: 4,
    title: "Delete",
    icon: DeleteIcon,
  },
];

const transformDevices = (devices) => {
  return devices.map(device => {
    return {
      ...device,
      status: device.status === 1 ? "Active" : "Inactive",
      health: device.status
    };
  });
};

const DeviceListView = ({ visibleDevices, onOptionsAction }) => {
  const navigate = useNavigate();
  const optionsMenuRef = useRef();

  const userState = useSelector(state => state.user);

  const [openMoreMenu, setOpenMoreMenu] = useState(-1);

  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;

  useOutsideAlerter(optionsMenuRef, (event) => {
    if (openMoreMenu !== -1) {
      setOpenMoreMenu(-1);
    }
  });

  const handleNavigateToDeviceDetail = (deviceId) => {
    navigate(`/devices/${deviceId}`);
  };

  const handleRenderItem = (item, columnId) => {
    const idx = visibleDevices.findIndex(it => it.id === item.id);

    if (columnId === "actions") {
      return (
        <>
          {(isUserAdmin || isOrgAdmin) && (
            <div className="list-body-cell actions-column">
              <div className="more-options-wrapper" ref={optionsMenuRef}>
                {CARD_OPTIONS
                  .map((cardOption) => {
                    return (
                      <div
                        className="more-options-row"
                        key={cardOption.title}
                        onClick={(event) =>
                          onOptionsAction(
                            event,
                            cardOption,
                            visibleDevices[idx]
                          )
                        }
                      >
                        {cardOption.icon && <Image src={cardOption.icon} alt="Icon" />}

                        <div className="more-options-text">
                          {cardOption.title}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          )}
        </>
      )
    }

    if (columnId === "health") {
      return (
        <div className="list-body-cell health-column" key={`${item.id}-${columnId}`}>
          {item[columnId] === 1
            ? <img className="healthy" src={RightTick} />
            : <img className={"unhealthy"} src={CrossIcon} />}
        </div>
      );
    }

    if (columnId === "status") {
      return (
        <div className="status-card" key={`${item.id}-${columnId}`}>
          <div className={`list-body-cell ${columnId}-column ${item[columnId] || ""}`}>
            {item[columnId] || ""}
          </div>
        </div>
      );
    }

    if (columnId === "name") {
      return (
        <div className={`list-body-cell ${columnId}-column`} key={`${item.id}-${columnId}`}>
          <div className="device-name" onClick={() => handleNavigateToDeviceDetail(item.id)}>
            {item[columnId] || ""}
          </div>

          {item.thermostatDeviceName && (
            <div className="thermostat-device-name">
              {item.thermostatDeviceName}
            </div>
          )}
        </div>
      );
    }

    if (columnId === "unitType") {
      return (
        <div className={`list-body-cell ${columnId}-column`} key={`${item.id}-${columnId}`}>
          {DEVICE_TYPE_OPTIONS.find((it) => it.id === item[columnId])?.name || ""}
        </div>
      )
    }
  };

  const listColumns = useMemo(() => {
    const columns = [
      {
        id: "name",
        label: "Device Name",
        canSort: false,
      },
      {
        id: "unitType",
        label: "Device Type",
        canSort: false,
      },
      {
        id: "status",
        label: "Status",
        canSort: false,
      },
      {
        id: "health",
        label: "Health",
        canSort: false,
      },
      {
        id: "actions",
        label: "",
        canSort: false,
      }
    ];

    return columns.filter(it => it.id !== "actions" || isUserAdmin || isOrgAdmin);
  }, [isUserAdmin, isOrgAdmin]);

  return (
    <div className="device-list">
      {visibleDevices.length === 0 && (
        <div className="no-devices">No devices found</div>
      )}

      {visibleDevices.length > 0 && (
        <List
          className={"device-list-container"}
          canSelectMultipleRows={false}
          columns={listColumns}
          items={transformDevices(visibleDevices)}
          renderItem={handleRenderItem} />
      )}
    </div>
  )
};

export default DeviceListView;
