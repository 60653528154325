import 'react-block-ui/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import reducer from './reducers';
import configureStore from './store';
import sentryConfig from './config/sentry';

import Dashboard from './pages/dashboard';
import DeviceList from './pages/device-list';
import Login from './pages/login';
import Logout from './pages/logout';
import DeviceDetail from './pages/device-detail';
import DeviceTimeHistory from './pages/device-time-history';
import UserManagement from './pages/user-management';
import UnassignedDevices from './pages/unassigned-devices';
import ForgotPwd from './pages/forgot-password';
import BaseComponent from './base';
import ErrorBoundary from './error-boundary';
import SystemList from './pages/system-list';
import LoggerManagement from './pages/logger-management';
import LoggerManagementDetail from './pages/logger-management-detail';
import SensorTypeManagement from './pages/sensor-type-management';
import ThermostatManagement from './pages/thermostat-management';
import SystemManagement from './pages/system-management';
import OrgManagement from './pages/org-management';

const store = configureStore(reducer);

Sentry.init(sentryConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <HashRouter>
      <ErrorBoundary>
        <Routes>
          <Route name="login" path="/login" element={<Login />} />
          <Route name="logout" path="/logout" element={<Logout />} />
          <Route name="forgot-pwd" path="/forgot-password" element={<ForgotPwd />} />

          <Route name="app" element={<BaseComponent />}>
            <Route name="dashboard" exact path="/" element={<Dashboard />} />
            <Route name="dashboard" path="/dashboard" element={<Dashboard />} />

            <Route name="system-list" path="/sites/:id/systems" element={<SystemList />} />
            <Route name="device-list" path="/systems/:id" element={<DeviceList />} />

            <Route name="unassigned-devices" path="/devices/unassigned" element={<UnassignedDevices />} />
            <Route name="device-detail" path="/devices/:id" element={<DeviceDetail />} />
            <Route name="device-time-history" path="/devices/:id/time-history" element={<DeviceTimeHistory />} />

            <Route name="user-management" path="/user_management" element={<UserManagement />} />

            <Route name="logger-management" path="/logger_management" element={<LoggerManagement />} />
            <Route name="logger-management-detail" path="/logger_management/:id" element={<LoggerManagementDetail />} />

            <Route name="sensor-type-management" path="/sensor_type_management" element={<SensorTypeManagement />} />
            <Route name="thermostat-management" path="/thermostat_management" element={<ThermostatManagement />} />
            <Route name="system-management" path="/system_management" element={<SystemManagement />} />
            <Route name="org-management" path="/org_management" element={<OrgManagement />} />
          </Route>
        </Routes>
      </ErrorBoundary>
    </HashRouter>
  </Provider>,
);
