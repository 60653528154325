import "./nav-item.scss";

import React from "react";

const NavItem = ({ id, label, isActive, onClick }) => {
  const handleClick = () => {
    onClick && onClick(id);
  };

  return (
    <div className={`nav-item ${isActive ? "active" : ""}`} onClick={handleClick}>
      {label}
    </div>
  )
};

NavItem.defaultProps = {
  isActive: false,
  onClick: () => {}
};

export default NavItem;
