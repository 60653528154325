import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADMIN, ORG_ADMIN } from "../../constants";
import EditIcon from "../../assets/icons/editIcon.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import DefaultSiteCardImage from "../../assets/images/default-site-card-image.svg";
import ThreeDotIcon from "../../assets/icons/threeDotsMore.svg";
import LocationIcon from "../../assets/icons/locationIcon.svg";
import useOutsideAlerter from "../../hooks/outside-alerter";
import { Button, Image } from "react-bootstrap";

const CARD_OPTIONS = [
    {
        id: 1,
        title: "Clone",
    },
    {
        id: 2,
        title: "Edit",
        icon: EditIcon
    },
    {
        id: 3,
        title: "Delete",
        icon: DeleteIcon
    }
];

const SiteGridItem = ({ idx, site, onCloneSite, onEditSite, onDeleteSite, onOpenMap }) => {
  const navigate = useNavigate();
  const optionsMenuRef = useRef();

  const userState = useSelector((state) => state.user);

  const [openMoreMenu, setOpenMoreMenu] = useState(-1);

  const {
      id,
      name,
      imageUrls,
      latitude,
      longitude,
      totalSystems = 0,
      numActiveSystems = 0,
  } = site;

  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;

  const primaryImageUrlObj = imageUrls.find(it => it.isPrimary);
  const primaryImageUrl = primaryImageUrlObj ? primaryImageUrlObj.url : DefaultSiteCardImage;

  useOutsideAlerter(optionsMenuRef, (event) => {
      if (openMoreMenu !== -1) {
        setOpenMoreMenu(-1);
      }
  });

  const activeSystemClassName = () => {
      let systemStatusClassName = "greenTag";

      if (numActiveSystems === 0) {
          systemStatusClassName = "redTag";
      } else if (numActiveSystems < totalSystems) {
          systemStatusClassName = "orangeTag";
      }

      return `system-active-wrapper ${systemStatusClassName}`;
  };

  const isOptionsOpen = (idx) => openMoreMenu === idx;

  const handleOpenOptions = (event, idx) => {
      event.stopPropagation();

      if (openMoreMenu === idx) {
          setOpenMoreMenu(-1);
      } else {
          setOpenMoreMenu(idx);
      }
  };

  const handleNavigateToSystemList = (siteId) => {
      navigate(`/sites/${siteId}/systems`);
  };

    const handleOptionsAction = (event, site, item) => {
        event.stopPropagation();

        switch (item.id) {
            case 1:
                return onCloneSite(site);
            case 2:
                return onEditSite(site);
            case 3:
                return onDeleteSite(site);
            default:
                break;
        }
    };

  const moreOptionItems = CARD_OPTIONS.map((item) => {
      return (
          <div
              className="more-options-row"
              key={item.title}
              onClick={(event) => handleOptionsAction(event, site, item)}>
              {item.icon && <Image src={item.icon} alt="Icon" />}
              <div className="more-options-text">{item.title}</div>
          </div>
      );
  });

  return (
      <li className={"site"} key={idx.toString()}>
          <div className="site-row">
              <div className="site-name-wrap" onClick={() => handleNavigateToSystemList(id)}>
                  <img className="site-img" src={primaryImageUrl} alt="site-img" />

                  <div className={activeSystemClassName()}>
                      <div className="system-active">
                          Systems Active <b>{numActiveSystems}/{totalSystems}</b>
                      </div>
                  </div>
              </div>

              <div className="actions-wrap">
                  <div className="site-name">{name}</div>

                  {(isUserAdmin || isOrgAdmin) && (
                      <div className="icon-wrapper">
                          <Button
                              className="location-btn"
                              onClick={() => onOpenMap({latitude, longitude})}>
                              <img
                                  className="location-icon"
                                  src={LocationIcon}
                                  alt="Location" />
                          </Button>

                          <div className="listcard-more-wrapper">
                              <img
                                  className="three-dot-more-icon"
                                  src={ThreeDotIcon}
                                  alt="More"
                                  onClick={(event) => handleOpenOptions(event, idx)} />

                              {isOptionsOpen(idx) && (
                                  <div className="more-options-wrapper" ref={optionsMenuRef}>
                                      {moreOptionItems}
                                  </div>
                              )}
                          </div>
                      </div>
                  )}
              </div>
          </div>
      </li>
  );
};

export default SiteGridItem;
