import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { buildRequest } from "../../utils";
import { Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SUCCESS } from "../../constants";

const UnassignedDevicesAlert = () => {
  const navigate = useNavigate();

  const userState = useSelector(state => state.user);
  const { type: userStateType } = userState;
  const isUserReqSuccess = userStateType === SUCCESS;

  const [areThereUnassignedDevices, setAreThereUnassignedDevices] = useState(false);

  const fetchUnassignedDevices = () => {
    return buildRequest(`core/units/orphans`, 'GET')
      .then(resp => {
        setAreThereUnassignedDevices(resp.length > 0);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!isUserReqSuccess) {
      return;
    }

    fetchUnassignedDevices();
  }, [isUserReqSuccess]);

  return (
    <>
      {areThereUnassignedDevices && (
        <Alert variant="warning" className="unassigned-systems-alert">
          <span>Found devices which are NOT assigned to any organization.</span>
          <Button onClick={() => navigate("/devices/unassigned")}>{`Assign Now >`}</Button>
        </Alert>
      )}
    </>
  );
};

export default UnassignedDevicesAlert;
