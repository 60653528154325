import { useEffect, useRef, useState } from "react";
import DropDownIcon from "../../assets/icons/dropDownArrow.svg";
import { Dropdown, Form, Image } from "react-bootstrap";
import useOutsideAlerter from "../../hooks/outside-alerter";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ADMIN, SUCCESS } from "../../constants";
import { buildRequest } from "../../utils";

const OrgsDropdown = ({ selectedOrgId }) => {
  const dropDownRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const userState = useSelector(state => state.user);
  const { type: userStateType, data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isUserReqSuccess = userStateType === SUCCESS;

  const [orgs, setOrgs] = useState([]);
  const [isOrgDropdownVisible, setIsOrgDropdownVisible] = useState(false);

  const fetchOrganizations = () => {
    return buildRequest(`core/organizations`, 'GET')
      .then(resp => {
        setOrgs(resp);

        if (!searchParams.get("org_id")) {
          setSearchParams({org_id: resp && resp.length > 0 && resp[0].id});
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!isUserReqSuccess) {
      return;
    }

    if (isUserAdmin) {
      fetchOrganizations();
    }
  }, [isUserReqSuccess]);

  useOutsideAlerter(dropDownRef, (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOrgDropdownVisible(false);
    }
  });

  const handleChangeOrg = (orgId) => {
    setSearchParams({org_id: orgId});
    setIsOrgDropdownVisible(!isOrgDropdownVisible);
  };

  const orgDropdownItems = orgs.map(org => {
    const { id, name } = org;

    return (
      <Dropdown.Item key={id} onClick={() => handleChangeOrg(id)}>
        <Form.Check checked={id === selectedOrgId} type="checkbox" label={name} readOnly />
      </Dropdown.Item>
    );
  });

  const selectedOrg = orgs.find(org => org.id === selectedOrgId);
  const selectedOrgName = selectedOrg ? selectedOrg.name : "No org selected";

  return (
    <Dropdown
      className="header-dropdown"
      onClick={() => setIsOrgDropdownVisible(!isOrgDropdownVisible)}
      ref={dropDownRef}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {selectedOrgName}

        <Image
          src={DropDownIcon}
          style={{ transform: isOrgDropdownVisible ? 'rotate(180deg)' : ''}}
          width={20}
          height={10}
          alt="DropDown Icon"/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {orgDropdownItems}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default OrgsDropdown;
