import './index.scss';

import { useState } from "react";
import { Image } from "react-bootstrap";

import LeftBackArrow from "../../assets/icons/leftBackArrow.svg";
import RightNextArrow from "../../assets/icons/rightNextArrow.svg";

const Carousel = ({ imageUrls, pageSize, onImageClick }) => {
  const [currentPage, setCurrentPage] = useState(0);

  if (!imageUrls) {
    return;
  }

  const handleChangePage = (page) => {
    if (page >= 0 && page <= Math.floor(imageUrls.length / pageSize)) {
      setCurrentPage(page);
    }
  };

  const isFirstPage = !(imageUrls?.length > 0 && currentPage !== 0);
  const isLastPage = !(imageUrls?.length > 0 && currentPage !== Math.ceil(imageUrls.length / pageSize) - 1);
  const imageUrlsPage = imageUrls.slice(currentPage * pageSize, (currentPage * pageSize) + pageSize);

  return (
    <div className="carousel-wrapper">
      {!isFirstPage && (
        <div className={`carousel-navigation-back-btn`} onClick={() => handleChangePage(currentPage - 1)}>
          <img src={LeftBackArrow} alt={"left back arrow"} />
        </div>
      )}

      {imageUrls?.length > 0 &&
        imageUrlsPage.map((image, idx) => {
          return (
            <Image
              key={idx.toString()}
              src={image.url}
              alt="carousel image"
              className="carousel-image"
              onClick={() => onImageClick(image)} />
          );
        })
      }

      {!isLastPage &&  (
        <div className={`carousel-navigation-next-btn`} onClick={() => handleChangePage(currentPage + 1)}>
          <img src={RightNextArrow} alt={"right next arrow"} />
        </div>
      )}
    </div>
  );
};

Carousel.defaultProps = {
  pageSize: 3,
};

export default Carousel;
