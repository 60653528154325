import './index.scss';

import List from "../../components/list";
import CrossIcon from "../../assets/icons/crossIcon.svg";
import RightTick from "../../assets/icons/rightTick.svg";
import { useNavigate } from "react-router";

const transformDevices = (devices) => {
  return devices.map((device) => {
    return {
      ...device,
      status: device.status === 1 ? "Active" : "Inactive",
      health: device.status,
    };
  });
};

const DeviceListView = ({ visibleDevices }) => {
  const navigate = useNavigate();

  const handleNavigateToSensorConfig = (deviceId) => {
    navigate(`/logger_management/${deviceId}`);
  };

  const handleRenderItem = (item, columnId) => {
    if (columnId === "health") {
      return (
        <div
          className="list-body-cell health-column"
          key={`${item.id}-${columnId}`}
        >
          {item[columnId] === 1 ? (
            <img className={"healthy"} src={RightTick} />
          ) : (
            <img className="unhealthy" src={CrossIcon} />
          )}
        </div>
      );
    }

    if (columnId === "refrigerantType") {
      return (
        <div
          className={`list-body-cell ${columnId}-column ${
            item[columnId] || ""
          }`}
          key={`${item.id}-${columnId}`}
        >
          {item[columnId] || ""}
        </div>
      );
    }

    if (columnId === "status") {
      return (
        <div className="status-card">
          <div
            className={`list-body-cell ${columnId}-column ${
              item[columnId] || ""
            }`}
            key={`${item.id}-${columnId}`}
          >
            {item[columnId] || ""}
          </div>
        </div>
      );
    }

    if (columnId === "name") {
      return (
        <div
          className={`list-body-cell ${columnId}-column`}
          key={`${item.id}-${columnId}`}
        >
          <div
            className="device-name"
            onClick={() => handleNavigateToSensorConfig(item.id)}
          >
            {item[columnId] || ""}
          </div>
        </div>
      );
    }
  };

  const listColumns = [
    {
      id: "name",
      label: "Device Name",
      canSort: false,
    },
    {
      id: "refrigerantType",
      label: "Refrigerant Type",
      canSort: false,
    },
    {
      id: "status",
      label: "Status",
      canSort: false,
    },
    {
      id: "health",
      label: "Health",
      canSort: false,
    },
  ];

  return (
    <div className="device-list">
      {visibleDevices.length === 0 && (
        <div className="no-devices">No devices found</div>
      )}

      {visibleDevices.length > 0 && (
        <List
          className={"logger-list-container"}
          canSelectMultipleRows={false}
          columns={listColumns}
          items={transformDevices(visibleDevices)}
          renderItem={handleRenderItem}
        />
      )}
    </div>
  );
};

export default DeviceListView;
