import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import { getLineChartData, getLineChartOptions, getSelectedDatesKey, processData } from "../../chart-utils.js";
import BlockUi from "react-block-ui";

const FanCurrentChart = ({ selectedDates, measureNameGroup, onFetchMetrics }) => {
  const timeHistoryState = useSelector(state => state.timeHistory);
  const { data: deviceTimeHistory, loadingIds } = timeHistoryState;

  const isLoading = loadingIds.indexOf(measureNameGroup.id) !== -1;

  const selectedDatesKey = getSelectedDatesKey(selectedDates);
  const { timestamps = [], metrics = {} } = useMemo(
    () => (deviceTimeHistory[selectedDatesKey] || []).find(it => it.id === "misc")?.results || {},
    [selectedDates, deviceTimeHistory]
  );
  const fanCurrentSeries = useMemo(
    () => processData(timestamps, metrics, "Fan_Current", "float", true),
    [metrics]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const selectedDatesKey = getSelectedDatesKey(selectedDates);

    if (!deviceTimeHistory[selectedDatesKey] || !deviceTimeHistory[selectedDatesKey].find(it => it.id === "misc")) {
      onFetchMetrics(measureNameGroup);
    }
  }, [selectedDates, deviceTimeHistory]);

  return (
    <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
      <div className="chart-row">
        <div className="chart-line">
          <Line
            width={"100%"}
            height={"400px"}
            data={getLineChartData(["Fan Current"], [fanCurrentSeries])}
            options={getLineChartOptions("Fan Current (Amps)", "A", 0, 4, 2)} />
        </div>
      </div>
    </BlockUi>
  );
};

export default React.memo(FanCurrentChart);
