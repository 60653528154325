import "./index.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SubHeader = () => {
  const location = useLocation();
  const userState = useSelector(state => state.user);
  const { data: userStateData } = userState;
  const { firstName = "" } = userStateData;

  // Check if the current page is system or sites page
  const dashboard = location.pathname.includes('dashboard');

  return dashboard ? (
    <div className="sub-header">
      <div className="page-title">
        Welcome <span style={{color: '#478CD1'}}>{`${firstName}!`}</span>
      </div>
    </div>
  ) : null;
};

export default SubHeader;