import { Image } from "react-bootstrap";
import OnIcon from "../../assets/icons/onButton.svg";
import OffIcon from "../../assets/icons/offButton.svg";

const DemandMetricCard = ({ name, value }) => {
  return (
    <div className={`metric-detail-card ${value ? "greenTag": "redTag"}`}>
      <div className="detail-card-internal">
        <div className={`metric-detail-card-name ${value ? "greenColor" : "redColor"}`}>
          {name}
        </div>

        <Image src={value ? OnIcon : OffIcon} alt="on" className="detail-card-img" />
      </div>
    </div>
  );
};

export default DemandMetricCard;
