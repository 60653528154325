import "./index.scss";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { transformItemFiles } from "../../utils";
import DefaultLayout from "../../components/layouts/default";
import { Alert, Image } from "react-bootstrap";
import BlockUi from "react-block-ui";
import { ADMIN, DEVICE_TYPE_OPTIONS, ORG_ADMIN } from "../../constants";
import { useSelector } from "react-redux";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import EditIcon from "../../assets/icons/editIconWhite.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import TimeHistoryIcon from "../../assets/icons/clockWhiteIcon.svg";
import ConfirmationPopup from "../../components/popup/confirmation";
import AddEditDeviceModal from "../device-list/add-edit-device-modal";
import { deleteDeviceAction, fetchDeviceAction, fetchSystemAction, saveDeviceAction } from "../../actions";
import LoggerDetail from "./logger-detail";
import ThermostatDetail from "./thermostat-detail";

const DEFAULT_NEW_DEVICE = {
  name: "",
  externalId: "",
  unitType: DEVICE_TYPE_OPTIONS[1].id,
};

const DeviceDetail = () => {
  const navigate = useNavigate();
  const { id: deviceId } = useParams();

  const [device, setDevice] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [system, setSystem] = useState(null);
  const [isAddDeviceFormVisible, setIsAddDeviceFormVisible] = useState(false);
  const [addDeviceFormErrors, setAddDeviceFormErrors] = useState([]);
  const [newDevice, setNewDevice] = useState(DEFAULT_NEW_DEVICE);
  const [isConfirmDeleteDeviceVisible, setIsConfirmDeleteDeviceVisible] = useState(false);
  const [isDeviceReqLoading, setIsDeviceReqLoading] = useState(false);
  const [isDeviceSaveReqLoading, setIsDeviceSaveReqLoading] = useState(false);
  const [isDeleteDeviceReqLoading, setIsDeleteDeviceReqLoading] = useState(false);

  const userState = useSelector((state) => state.user);
  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;

  const {
    name: deviceName,
    thermostatDeviceName,
    status,
    unitType,
  } = device;

  const fetchDevice = () => {
    setIsDeviceReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");
      const transformedResp = transformItemFiles(resp);
      setDevice(transformedResp);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch device");
    };

    const onFinally = () => {
      setIsDeviceReqLoading(false);
    };

    fetchDeviceAction(deviceId, onSuccess, onError, onFinally);
  };

  const saveDevice = () => {
    setIsDeviceSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const newDevice = transformItemFiles({ ...device, ...resp });
      setDevice(newDevice);
      setNewDevice(newDevice);
      setIsAddDeviceFormVisible(false);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to save device");
    };

    const onFinally = () => {
      setIsDeviceSaveReqLoading(false);
    };

    saveDeviceAction(system.id, { ...newDevice }, onSuccess, onError, onFinally);
  };
  
  const deleteDevice = () => {
    setIsDeleteDeviceReqLoading(true);

    const deviceIds = [deviceId];

    const onSuccess = () => {
      setErrorMsg("");
      handleNavigateToSystem();
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg(["Failed to delete device"]);
    };

    const onFinally = () => {
      setIsDeleteDeviceReqLoading(false);
    };

    deleteDeviceAction(deviceIds, onSuccess, onError, onFinally);
  };

  const fetchSystem = () => {
    const onSuccess = (resp) => {
      setErrorMsg("");

      setSystem(resp);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch site");
    };

    fetchSystemAction(device.hvacSystemId, onSuccess, onError, () => {});
  };

  useEffect(() => {
    fetchDevice();
  }, []);

  useEffect(() => {
    if (device && Object.keys(device).length > 0 && !system) {
      fetchSystem();
    }
  }, [device]);

  const handleNavigateToOrg = () => {
    if (!system) {
      return;
    }

    navigate(`/?org_id=${system.organization.id}`);
  };

  const handleNavigateToSite = () => {
    if (!system) {
      return;
    }

    navigate(`/sites/${system.site.id}/systems`);
  };

  const handleNavigateToSystem = () => {
    navigate(`/systems/${system.id}`);
  };

  const handleNavigateToTimeHistory = () => {
    navigate(`/devices/${deviceId}/time-history`);
  };

  const handleEditDevice = (event, device) => {
    event.stopPropagation();
    setNewDevice(device);
    setIsAddDeviceFormVisible(true);
  };

  const handleDeleteDevice = (event) => {
    event.stopPropagation();

    setIsConfirmDeleteDeviceVisible(true);
  };

  const handleUpdateNewDevice = (updatedDevice) => {
    setNewDevice(updatedDevice);
  };

  const handleAddEditDeviceModalClose = () => {
    setAddDeviceFormErrors([]);
    setNewDevice(device);
    setIsAddDeviceFormVisible(false);
  };

  const breadCrumbOptions = [
    {
      id: 1,
      title: system ? system.organization.name : "...",
      onClick: handleNavigateToOrg,
    },
    {
      id: 2,
      title: system ? system.site.name : "...",
      onClick: handleNavigateToSite,
    },
    {
      id: 3,
      title: system ? system.name : "...",
      onClick: handleNavigateToSystem,
    },
    {
      id: 4,
      title: device ? device.name : "...",
    },
  ];

  const isLoading = (
    isDeviceReqLoading ||
    isDeviceSaveReqLoading ||
    isDeleteDeviceReqLoading
  );

  return (
    <DefaultLayout>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
        <div className="device-detail-container">
          <div className="device-detail-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions} />

            <div className="button-controller">
              {(isUserAdmin || isOrgAdmin) && (
                <>
                  <div className="edit-button-wrapper" onClick={(event) => handleEditDevice(event, device)}>
                    <div className="edit-button-text">Edit</div>
                    <Image src={EditIcon} alt="Edit Button" className="edit-icon" />
                  </div>

                  <div className="delete-button-wrapper" onClick={(event) => handleDeleteDevice(event)}>
                    <div className="delete-button-text">Delete</div>
                    <Image src={DeleteIcon} alt="Delete Button" className="delete-icon" />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="device-detail-controller-wrapper">
            <div className="device-name">
              {`${deviceName || "..."} ${thermostatDeviceName ? `(${thermostatDeviceName})` : ""}`}
            </div>

            <div className="device-detail-controller">
              <div className={`device-status ${status === 1 ? "active" : "inactive"}`}>
                {status === 1 ? "Active" : "Inactive"}
              </div>

              {(isUserAdmin || isOrgAdmin) && (
                <div className="time-history-wrapper" onClick={() => handleNavigateToTimeHistory()}>
                  <div className="time-history-text">Time History</div>
                  <Image src={TimeHistoryIcon} alt="Time History" className="time-history-icon" />
                </div>
              )}
            </div>
          </div>

          {unitType === DEVICE_TYPE_OPTIONS[0].id && (
            <LoggerDetail device={device} system={system} />
          )}

          {unitType === DEVICE_TYPE_OPTIONS[1].id && (
            <ThermostatDetail device={device} system={system} />
          )}
        </div>
      </BlockUi>

      <ConfirmationPopup
        show={isConfirmDeleteDeviceVisible}
        title={"Wait, wait, wait..."}
        content={"This will permanently delete the device. Continue?"}
        onNo={() => {
          setIsConfirmDeleteDeviceVisible(false);
        }}
        onYes={() => {
          setIsConfirmDeleteDeviceVisible(false);
          deleteDevice();
        }} />

      <AddEditDeviceModal
        isVisible={isAddDeviceFormVisible}
        newDevice={newDevice}
        addDeviceFormErrors={addDeviceFormErrors}
        onClose={handleAddEditDeviceModalClose}
        onSaveDevice={saveDevice}
        onUpdateNewDevice={handleUpdateNewDevice} />
    </DefaultLayout>
  );
};

export default DeviceDetail;
