import React, { useImperativeHandle, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { uniqueId } from 'lodash';

export function useUniqueId() {
  const idRefLazy = useRef(undefined);
  idRefLazy.current ??= uniqueId();
  return idRefLazy.current;
}

export const LazyLoader = React.forwardRef(
  ({ children, onLoad, onChange, ...rest }, ref) => {
    const id = useUniqueId();
    const [loaded, setLoaded] = useState(false);
    const [isInView, setIsInView] = useState(false);
    const innerRef = useRef(null);

    useImperativeHandle(ref, () => innerRef.current);

    useEffectOnce(() => {
      LazyLoader.addCallback(id, (entry) => {
        if (!loaded && entry.isIntersecting) {
          setLoaded(true);
          onLoad?.();
        }

        setIsInView(entry.isIntersecting);
        onChange?.(entry.isIntersecting);
      });

      const wrapperEl = innerRef.current;

      if (wrapperEl) {
        LazyLoader.observer.observe(wrapperEl);
      }

      return () => {
        delete LazyLoader.callbacks[id];
        wrapperEl && LazyLoader.observer.unobserve(wrapperEl);
        if (Object.keys(LazyLoader.callbacks).length === 0) {
          LazyLoader.observer.disconnect();
        }
      };
    });

    return (
      <div id={id} ref={innerRef} {...rest}>
        {isInView && (typeof children === 'function' ? children({ isInView }) : children)}
      </div>
    );
  }
);

LazyLoader.displayName = 'LazyLoader';
LazyLoader.callbacks = {};
LazyLoader.addCallback = (id, callback) => (LazyLoader.callbacks[id] = callback);
LazyLoader.observer = new IntersectionObserver(
  (entries) => {
    for (const entry of entries) {
      LazyLoader.callbacks[entry.target.id](entry);
    }
  },
  { rootMargin: '100px' }
);
