import "react-block-ui/style.css";
import "./index.scss";

import React, { useEffect, useState } from "react";
import { Alert, Image, Modal } from "react-bootstrap";
import { sortItems, transformItemFiles } from "../../utils";
import DefaultLayout from "../../components/layouts/default";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN, DEFAULT_MAP_ZOOM, ORG_ADMIN } from "../../constants";
import { useSelector } from "react-redux";
import ConfirmationPopup from "../../components/popup/confirmation";
import BlockUi from "react-block-ui";
import GridIcon from "../../assets/icons/gridIcon.svg";
import ListIcon from "../../assets/icons/listIcon.svg";
import EditWhiteIcon from "../../assets/icons/editIconWhite.svg";
import DeleteIcon from "../../assets/icons/deleteIcon.svg";
import BreadCrumbs from "../../components/breadcrumbs";
import HomeIcon from "../../assets/icons/homeIcon.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";
import SystemGridView from "./system-grid-view";
import SystemListView from "./system-list-view";
import AddEditSiteModal from "../dashboard/add-edit-site-modal";
import MapLocationModal from "../../components/map-location-modal";
import {
  deleteSystemAction,
  deleteSiteAction,
  fetchSystemsAction,
  fetchOrgsAction,
  fetchSiteAction,
  fetchSitesAction,
  saveSystemAction,
  saveSiteAction,
} from "../../actions";
import Carousel from "../../components/carousel";
import AddEditSystemModal from "./add-edit-system-modal";
import GoogleMap from "../../components/maps";
import MapMarker from "../../components/map-marker";

const VIEW_TYPES = {
  "grid": 0,
  "list": 1,
};

const DEFAULT_NEW_SITE = {
  name: "",
  latitude: 0,
  longitude: 0,
  address:"",
  files: [],
  organizationId: null,
};

const DEFAULT_NEW_SYSTEM = {
  name: "",
  brand: "",
  modelNumber: "",
  latitude: 0,
  longitude: 0,
  files: [],
  installedOn: "",
  size: 7.5,
};

const SystemList = () => {
  const navigate = useNavigate();
  const { id: siteId } = useParams();

  const [viewType, setViewType] = useState(VIEW_TYPES.list);
  const [site, setSite] = useState({ files: [] });
  const [systems, setSystems] = useState([]);
  const [visibleSystems, setVisibleSystems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isAddSystemFormVisible, setIsAddSystemFormVisible] = useState(false);
  const [addSystemFormErrors, setAddSystemFormErrors] = useState([]);
  const [newSystem, setNewSystem] = useState(DEFAULT_NEW_SYSTEM);
  const [tempSystem, setTempSystem] = useState(DEFAULT_NEW_SYSTEM);
  const [isConfirmDeleteSystemVisible, setIsConfirmDeleteSystemVisible] = useState(false);
  const [mapCoords, setMapCoords] = useState({});
  const [isSystemMapOpen, setIsSystemMapOpen] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isOrgsReqLoading, setIsOrgsReqLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [showCarouselImage, setShowCarouselImage] = useState(false);
  const [showCarouselImageFile, setShowCarouselImageFile] = useState();
  const [isAddSiteFormVisible, setIsAddSiteFormVisible] = useState(false);
  const [addSiteFormErrors, setAddSiteFormErrors] = useState([]);
  const [newSite, setNewSite] = useState(DEFAULT_NEW_SITE);
  const [tempSite, setTempSite] = useState(DEFAULT_NEW_SITE);
  const [isConfirmDeleteSiteVisible, setIsConfirmDeleteSiteVisible] = useState(false);
  const [isSystemListLoading, setIsSystemListLoading] = useState(false);
  const [isSystemSaveReqLoading, setIsSystemSaveReqLoading] = useState(false);
  const [isDeleteSystemReqLoading, setIsDeleteSystemReqLoading] = useState(false);
  const [isSiteListLoading, setIsSiteListLoading] = useState(false);
  const [isSiteSaveReqLoading, setIsSiteSaveReqLoading] = useState(false);
  const [isDeleteSiteReqLoading, setIsDeleteSiteReqLoading] = useState(false);
  const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);

  const userState = useSelector((state) => state.user);
  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;
  const currentUserOrgId = userStateData.organizationId;

  const fetchSystems = () => {
    setIsSystemListLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      const transformedSystems = resp.map((it) => transformItemFiles(it));
      setSystems(transformedSystems);
      setVisibleSystems(sortItems(transformedSystems));
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch systems");
    };

    const onFinally = () => {
      setIsSystemListLoading(false);
    };

    fetchSystemsAction(siteId, onSuccess, onError, onFinally);
  };

  const saveSystem = (updatedSystem) => {
    setIsSystemSaveReqLoading(true);

    const onSuccess = (resp) => {
      setErrorMsg("");

      let transformedSystems = [];
      const transformedSystem = transformItemFiles({ ...updatedSystem, ...resp });

      if (!updatedSystem.id) {
        transformedSystems = [transformedSystem, ...systems];
      } else {
        const systemIdx = systems.findIndex((it) => it.id === updatedSystem.id);

        transformedSystems = [
          ...systems.slice(0, systemIdx),
          transformedSystem,
          ...systems.slice(systemIdx + 1),
        ];
      }

      setSystems(transformedSystems);
      setVisibleSystems(sortItems(transformedSystems));
      setSearchTerm("");
      setNewSystem(DEFAULT_NEW_SYSTEM);
      setIsAddSystemFormVisible(false);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to save system");
    };

    const onFinally = () => {
      setIsSystemSaveReqLoading(false);
    };

    saveSystemAction(selectedSiteId || siteId, { ...updatedSystem }, onSuccess, onError, onFinally);
  };

  const deleteSystem = () => {
    setIsDeleteSystemReqLoading(true);

    const systemIds = [tempSystem.id];

    const onSuccess = () => {
      setErrorMsg("");

      const filteredSystems = systems.filter((it) => systemIds.indexOf(it.id) === -1);

      setSystems(filteredSystems);
      setVisibleSystems(sortItems(filteredSystems));
      setSearchTerm("");

      setTempSystem(DEFAULT_NEW_SYSTEM);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg(["Failed to delete system"]);
    };

    const onFinally = () => {
      setIsDeleteSystemReqLoading(false);
      setTempSystem(DEFAULT_NEW_SYSTEM);
    };

    deleteSystemAction(systemIds, onSuccess, onError, onFinally);
  };

  const fetchSites = () => {
    setIsSiteListLoading(true);

    const orgId = selectedOrgId ? selectedOrgId : currentUserOrgId;

    const onSuccess = resp => {
      setErrorMsg("");

      const transformedSites = resp.map(it => transformItemFiles(it));
      setSites(transformedSites);
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch sites");
    };

    const onFinally = () => {
      setIsSiteListLoading(false);
    };

    fetchSitesAction(orgId, onSuccess, onError, onFinally);
  };

  const saveSite = () => {
    setIsSiteSaveReqLoading(true);

    const onSuccess = resp => {
      setErrorMsg("");

      const site = transformItemFiles(resp);
      setSite(site);

      setIsAddSiteFormVisible(false);
      setNewSite(DEFAULT_NEW_SITE);
      setAddSiteFormErrors([]);
    };

    const onError = err => {
      console.log(err);
      setAddSiteFormErrors([err.data.message]);
    };

    const onFinally = () => {
      setIsSiteSaveReqLoading(false);
    };

    saveSiteAction(
      newSite,
      onSuccess,
      onError,
      onFinally
    );
  };

  const fetchSite = () => {
    const onSuccess = (resp) => {
      setErrorMsg("");
      const site = transformItemFiles(resp);
      setSite(site);
    };

    const onError = (err) => {
      console.log(err);
      setErrorMsg("Failed to fetch site");
    };

    fetchSiteAction(siteId, onSuccess, onError, () => {});
  };

  const fetchOrganizations = () => {
    setIsOrgsReqLoading(true);

    const onSuccess = resp => {
      setErrorMsg("");
      setOrgs(resp);
    };

    const onError = err => {
      console.log(err);
      setErrorMsg("Failed to fetch organizations");
    };

    const onFinally = () => {
      setIsOrgsReqLoading(false);
    };

    fetchOrgsAction(onSuccess, onError, onFinally);
  };

  const deleteSite = () => {
    setIsDeleteSiteReqLoading(true);

    const siteIds = [tempSite.id];

    const onSuccess = () => {
      setErrorMsg("");
      navigate("/");
    };

    const onError = err => {
      console.log(err);
      setErrorMsg(["Failed to delete site"]);
    };

    const onFinally = () => {
      setIsDeleteSiteReqLoading(false);
      setTempSite(DEFAULT_NEW_SITE);
    };

    deleteSiteAction(siteIds, onSuccess, onError, onFinally);
  };

  useEffect(() => {
    fetchSite();
    fetchSystems();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setVisibleSystems(
        sortItems(systems.filter((it) => it.name.toLowerCase().includes(searchTerm.toLowerCase())))
      );
    } else {
      setVisibleSystems(sortItems(systems));
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!selectedOrgId || !isAddSystemFormVisible)
      return;

    fetchSites();
  }, [selectedOrgId, isAddSystemFormVisible]);

  const handleOptionsAction = (event, item, system) => {
    if (item.id === 1) {
      return handleCloneSystem(event, system);
    } else if (item.id === 2) {
      return handleEditSystem(event, system);
    } else {
      return handleDeleteSystem(event, system);
    }
  };

  const handleSearch = (searchInput) => {
    setSearchTerm(searchInput);
  };

  const handleAddSystem = () => {
    setIsAddSystemFormVisible(true);

    if (!orgs || orgs.length === 0) {
      if (isUserAdmin) {
        fetchOrganizations();
      }
    }

    setSelectedOrgId(site.organization.id);
    setSelectedSiteId(site.id);
  };

  const handleEditSystem = (event, system) => {
    event.stopPropagation();

    setNewSystem(system);
    setIsAddSystemFormVisible(true);

    if (!orgs || orgs.length === 0) {
      if (isUserAdmin) {
        fetchOrganizations();
      }

      setSelectedOrgId(site.organization.id);
      setSelectedSiteId(site.id);
    }
  };

  const handleCloneSystem = (event, system) => {
    event.stopPropagation();

    const clonedSystem = { ...system, name: `${system.name} (Clone)` };
    delete clonedSystem.id;
    saveSystem(clonedSystem);
  };

  const handleDeleteSystem = (event, system) => {
    event.stopPropagation();

    setTempSystem(system);
    setIsConfirmDeleteSystemVisible(true);
  };

  const handleUpdateNewSystem = (field, val) => {
    setNewSystem({ ...newSystem, [field]: val });
  };

  const handleOpenMap = (coords) => {
    setMapCoords(coords);
    setIsSystemMapOpen(true);
  };

  const handleNavigateToOrg = () => {
    if (!site) {
      return;
    }

    navigate(`/?org_id=${site.organization.id}`);
  };

  const handleEditSite = (event, site) => {
    event.stopPropagation();

    setNewSite({ ...site, organizationId: parseInt(site.organizationId) });
    setIsAddSiteFormVisible(true);
  };

  const handleDeleteSite = (event) => {
    event.stopPropagation();

    setTempSite(site);
    setIsConfirmDeleteSiteVisible(true);
  };

  const handleAddEditSiteModalClose = () => {
    setAddSiteFormErrors([]);
    setNewSite(DEFAULT_NEW_SITE);
    setIsAddSiteFormVisible(false);
  };

  const handleAddEditSystemModalClose = () => {
    setAddSystemFormErrors([]);
    setNewSystem(DEFAULT_NEW_SYSTEM);
    setIsAddSystemFormVisible(false);
  };

  const handleShowFullImage = (image) => {
    setShowCarouselImage(true);
    setShowCarouselImageFile(image.url);
  };

  const isLoading = (
    isSystemListLoading ||
    isDeleteSystemReqLoading ||
    isSystemSaveReqLoading ||
    isOrgsReqLoading ||
    isSiteListLoading ||
    isSiteSaveReqLoading ||
    isDeleteSiteReqLoading
  );

  const breadCrumbOptions = [
    {
      id: 1,
      title: site ? site.organization?.name : "...",
      onClick: handleNavigateToOrg,
    },
    {
      id: 2,
      title: site ? site.name : "...",
    },
  ];

  const carouselPageSize = document.documentElement.clientWidth < 840 ? 1 : 3;

  return (
    <DefaultLayout
      pageTitle={"System list"}
      showSearchBar
      onSearch={(searchText) => handleSearch(searchText)}
    >
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

      <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
        <div className="main-content">
          <div className="site-detail-header">
            <BreadCrumbs
              homeIcon={HomeIcon}
              progressIcon={RightArrow}
              options={breadCrumbOptions} />

            <div className="button-controller">
              {(isUserAdmin || isOrgAdmin) && (
                <>
                  <div className="edit-button-wrapper" onClick={(event) => handleEditSite(event, site)}>
                    <div className="edit-button-text">Edit</div>
                    <Image src={EditWhiteIcon} alt="Edit Button" className="edit-icon" />
                  </div>

                  <div className="delete-button-wrapper" onClick={(event) => handleDeleteSite(event)}>
                    <div className="delete-button-text">Delete</div>
                    <Image src={DeleteIcon} alt="Delete Button" className="delete-icon" />
                  </div>
                </>
              )}
            </div>
          </div>

          <Carousel
            imageUrls={site?.imageUrls}
            pageSize={carouselPageSize}
            onImageClick={handleShowFullImage} />

          <div className="list-actions">
            <div className="title">{site ? site.name : ""}</div>

            <div className="view-type-selection-wrap">
              <div
                className={`view-type-option view-type-grid ${viewType === VIEW_TYPES.grid ? "selected" : ""}`}
                onClick={() => setViewType(VIEW_TYPES.grid)}>
                <img src={GridIcon} />
              </div>

              <div
                className={`view-type-option view-type-list ${viewType === VIEW_TYPES.list  ? "selected" : ""}`}
                onClick={() => setViewType(VIEW_TYPES.list)}>
                <img src={ListIcon} />
              </div>
            </div>

            {(isUserAdmin || isOrgAdmin) && (
              <div className="add-system-btn" onClick={handleAddSystem}>
                Add system
              </div>
            )}
          </div>

          <div className="site-detail-container">
            <div className="site-detail-list">
              <div className="site-detail-item">
                <span className="label">Address: </span>
                <span>{site && site.address ? site.address : "-"}</span>
              </div>
              <div className="site-detail-item">
                <span className="label">Organization: </span>
                <span>{site && site.organization ? site.organization.name : "-"}</span>
              </div>
            </div>

            <div className="map">
              <GoogleMap
                lat={site.latitude || 0}
                lng={site.longitude || 0}
                zoom={mapZoom}
                MapMarkerComponent={MapMarker}
              />
            </div>
          </div>

          <div className="system-list-wrap">
            {viewType === VIEW_TYPES.grid && (
              <SystemGridView
                visibleSystems={visibleSystems}
                onOpenMap={handleOpenMap}
                onOptionsAction={handleOptionsAction} />
            )}

            {viewType === VIEW_TYPES.list && (
              <SystemListView
                visibleSystems={visibleSystems}
                onOptionsAction={handleOptionsAction} />
            )}
          </div>

          <ConfirmationPopup
            show={isConfirmDeleteSystemVisible}
            title={"Wait, wait, wait..."}
            content={"This will permanently delete the system. Continue?"}
            onNo={() => {
              setIsConfirmDeleteSystemVisible(false);
              setTempSystem(DEFAULT_NEW_SYSTEM);
            }}
            onYes={() => {
              setIsConfirmDeleteSystemVisible(false);
              deleteSystem();
            }} />

          <ConfirmationPopup
            show={isConfirmDeleteSiteVisible}
            title={"Wait, wait, wait..."}
            content={"This will permanently delete the site. Continue?"}
            onNo={() => {
                setIsConfirmDeleteSiteVisible(false);
                setTempSite(DEFAULT_NEW_SITE);
            }}
            onYes={() => {
                setIsConfirmDeleteSiteVisible(false);
                deleteSite();
            }} />

          <Modal centered show={showCarouselImage} className="carousel-image">
            <Modal.Header closeButton onClick={() => setShowCarouselImage(false)} />

            <Modal.Body className="carousel-image-body">
              <Image src={showCarouselImageFile} alt="image" />
            </Modal.Body>
          </Modal>

          <AddEditSiteModal
            isVisible={isAddSiteFormVisible}
            newSite={newSite}
            addSiteFormErrors={addSiteFormErrors}
            onClose={handleAddEditSiteModalClose}
            onUpdateNewSite={setNewSite}
            onSaveSite={saveSite} />

          <AddEditSystemModal
            isVisible={isAddSystemFormVisible}
            newSystem={newSystem}
            addSystemFormErrors={addSystemFormErrors}
            orgs={orgs}
            sites={sites}
            selectedOrgId={selectedOrgId}
            selectedSiteId={selectedSiteId}
            onChangeOrgId={setSelectedOrgId}
            onChangeSiteId={setSelectedSiteId}
            onClose={handleAddEditSystemModalClose}
            onSaveSystem={() => saveSystem(newSystem)}
            onUpdateNewSystem={handleUpdateNewSystem}
            onSetNewSystem={setNewSystem} />

          <MapLocationModal
            title={"HVAC System Location"}
            isVisible={isSystemMapOpen}
            mapCoords={mapCoords}
            onClose={() => setIsSystemMapOpen(false)} />
        </div>
      </BlockUi>
    </DefaultLayout>
  );
};

export default SystemList;
