import { buildRequest } from '../../utils';

export const fetchOrgsAction = (onSuccess, onError, onFinally) => buildRequest('core/organizations', 'GET')
  .then(onSuccess)
  .catch(onError)
  .finally(onFinally);

export const saveOrgAction = (newOrg, onSuccess, onError, onFinally) => {
  return buildRequest(
    'core/organizations',
    newOrg.id ? 'PUT' : 'POST',
    newOrg,
    false,
  )
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};

export const deleteOrgAction = (orgIds, onSuccess, onError, onFinally) => {
  return buildRequest(
    'core/organizations',
    'DELETE',
    { orgIds },
    false,
  )
    .then(onSuccess)
    .catch(onError)
    .finally(onFinally);
};
