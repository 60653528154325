import './default.scss';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logoImg from '../../assets/logo-lg.png';
import NavBar, { NAV_BAR_ORIENTATIONS } from '../navbar';
import HeaderNavItem from '../navbar/header-nav-item';
import NavItem from '../navbar/nav-item';
import SubHeader from '../sub-header';
import { ADMIN, ORG_ADMIN } from '../../constants';
import DashboardIcon from '../../assets/icons/dashBoard.svg';
import UserManagementIcon from '../../assets/icons/userManagement.svg';
import ThermostatManagementIcon from '../../assets/icons/thermostatManagement.svg';
import SystemManagementIcon from '../../assets/icons/systemManagement.svg';
import SensorManagementIcon from '../../assets/icons/sensorManagement.svg';
import LoggerManagementIcon from '../../assets/icons/loggerManagement.svg';

const DefaultLayout = ({ isLoggedIn, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userState = useSelector((state) => state.user);
  const { data: userStateData } = userState;
  const isUserAdmin = userStateData.role === ADMIN;
  const isOrgAdmin = userStateData.role === ORG_ADMIN;

  const handleNavigate = (id) => {
    if (!isNavItemActive(id)) {
      navigate(`/${id}`);
    }
  };

  const isNavItemActive = (id) => location.pathname === `/${id}`;

  const navItems = [
    {
      id: 'branding',
      icon: logoImg,
      label: '',
      onClick: () => handleNavigate(''),
      Component: HeaderNavItem,
    },
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: DashboardIcon,
      onClick: handleNavigate,
      Component: NavItem,
      isActive: isNavItemActive('dashboard') || isNavItemActive(''),
    },
  ];

  if (isUserAdmin || isOrgAdmin) {
    navItems.push(
      {
        id: 'user_management',
        label: 'User Management',
        icon: UserManagementIcon,
        onClick: handleNavigate,
        Component: NavItem,
        isActive: isNavItemActive('user_management'),
      },
    );
  }

  if (isUserAdmin) {
    navItems.push(
      {
        id: 'logger_management',
        label: 'Logger Management',
        icon: LoggerManagementIcon,
        onClick: handleNavigate,
        Component: NavItem,
        isActive: isNavItemActive('logger_management'),
      },
    );

    navItems.push(
      {
        id: 'sensor_type_management',
        label: 'Sensor Type Management',
        icon: SensorManagementIcon,
        onClick: handleNavigate,
        Component: NavItem,
        isActive: isNavItemActive('sensor_type_management'),
      },
    );

    navItems.push(
      {
        id: 'thermostat_management',
        label: 'Thermostat Management',
        icon: ThermostatManagementIcon,
        onClick: handleNavigate,
        Component: NavItem,
        isActive: isNavItemActive('thermostat_management'),
      },
    );

    navItems.push(
      {
        id: 'system_management',
        label: 'System Management',
        icon: SystemManagementIcon,
        onClick: handleNavigate,
        Component: NavItem,
        isActive: isNavItemActive('system_management'),
      },
    );

    navItems.push(
      {
        id: 'org_management',
        label: 'Org Management',
        icon: SystemManagementIcon,
        onClick: handleNavigate,
        Component: NavItem,
        isActive: isNavItemActive('org_management'),
      },
    );
  }

  return (
    <div className="site-container">
      <div className="site-navbar-content">
        <NavBar
          items={navItems}
          orientation={NAV_BAR_ORIENTATIONS.vertical}
          isLoggedIn={isLoggedIn}/>

        <div className="site-content">
          <SubHeader />

          {children}
        </div>
      </div>
    </div>
  );
};

DefaultLayout.defaultProps = {
  isLoggedIn: true,
};

export default DefaultLayout;
