import "./index.scss";

import React, { useState } from "react";
import { Image, Navbar } from "react-bootstrap";
import LogoutIcon from "../../assets/icons/logout.svg";
import ConfirmationPopup from "../popup/confirmation";
import { useLocation, useNavigate } from "react-router-dom";

export const NAV_BAR_ORIENTATIONS = {
  horizontal: "h",
  vertical: "v",
};

const NavBar = ({ items, isLoggedIn }) => {
  const [isConfirmLogoutVisible, setIsConfirmLogoutVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleShowConfirmLogoutModal = (event) => {
    handleSetConfirmLogoutVisible(event, true);
  };

  const handleSetConfirmLogoutVisible = (event, value) => {
    event.stopPropagation();
    setIsConfirmLogoutVisible(value);
  };

  const handleLogout = () => {
    navigate(`/logout?next=${location.pathname}`);
  };

  const navItems = items.slice(1).map((item) => {
    const { id, label, icon, isActive, onClick } = item;

    if (id == "user_management") return null;

    return (
      <div
        key={id}
        style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
        className={`navbar-item-wrapper ${
          isActive ? "item-wrapper-active" : ""
        }`}
        onClick={() => onClick(id)}
      >
        <Image src={icon} alt={`${label} Icon`} style={{ maxWidth: "10px" }} />
        <Navbar.Text key={id}>{label}</Navbar.Text>
      </div>
    );
  });

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Navbar.Text className="brand" onClick={items[0].onClick}>
        <img className="icon" src={items[0].icon} alt="hvacvitals" />
        <span className="brand-label">{items[0].label}</span>
      </Navbar.Text>

      <Navbar.Toggle />

      <Navbar.Collapse>
        <div style={{ width: "100%" }}>{navItems}</div>

        <div style={{ width: "100%" }}>
          {items.slice(1).map((item) => {
            const { id, label, icon, isActive, onClick } = item;

            if (id != "user_management") return null;

            return (
              <div
                key={id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                }}
                className={`navbar-item-wrapper ${
                  isActive ? "item-wrapper-active" : ""
                }`}
                onClick={() => onClick(id)}
              >
                <Image
                  src={icon}
                  alt={`${label} Icon`}
                  style={{ maxWidth: "10px" }}
                />
                <Navbar.Text key={id}>{label}</Navbar.Text>
              </div>
            );
          })}

          {isLoggedIn && (
            <>
              <div
                className="logout-wrapper"
                onClick={handleShowConfirmLogoutModal}
              >
                <img
                  className="logout-icon"
                  src={LogoutIcon}
                  height={16}
                  width={16}
                  alt="Logout Icon"
                />
                <div className="logout-text">Logout</div>
              </div>

              <ConfirmationPopup
                show={isConfirmLogoutVisible}
                title={"Logout?"}
                content={"Are you sure you want to logout?"}
                onNo={(event) => handleSetConfirmLogoutVisible(event, false)}
                onYes={(event) => {
                  handleSetConfirmLogoutVisible(event, false);
                  handleLogout();
                }}
              />
            </>
          )}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
