import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { getLineChartData, getLineChartOptions, getSelectedDatesKey, processData } from "../../chart-utils.js";
import { useSelector } from "react-redux";
import BlockUi from "react-block-ui";

const CompressorCurrentChart = ({ selectedDates, measureNameGroup, visibleMetricNames }) => {
  const timeHistoryState = useSelector(state => state.timeHistory);
  const { data: deviceTimeHistory, loadingIds } = timeHistoryState;

  const isLoading = loadingIds.indexOf(measureNameGroup.id) !== -1;

  const selectedDatesKey = getSelectedDatesKey(selectedDates);
  const { timestamps = [], metrics = {} } = useMemo(
    () => (deviceTimeHistory[selectedDatesKey] || []).find(it => it.id === "duty_cycle")?.results || {},
    [selectedDates, deviceTimeHistory]
  );

  const compressorCurrentSeries = useMemo(
    () => processData(timestamps, metrics, "Compressor_Current", "float", true),
    [metrics]
  );
  const compressorCurrent2Series = useMemo(
    () => processData(timestamps, metrics, "Compressor_Current2", "float", true),
    [metrics]
  );

  const chartTitles = [];
  const chartSeries = [];

  if (visibleMetricNames.indexOf("Compressor_Current") !== -1) {
    chartTitles.push("Compressor Current");
    chartSeries.push(compressorCurrentSeries);
  }

  if (visibleMetricNames.indexOf("Compressor_Current2") !== -1) {
    chartTitles.push("Compressor Current 2");
    chartSeries.push(compressorCurrent2Series);
  }

  return (
    <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
      <div className="chart-row">
        <div className="chart-line">
          <Line
            width={"100%"}
            height={"400px"}
            data={getLineChartData(chartTitles, chartSeries)}
            options={getLineChartOptions("Compressor Current (Amps)", "A", 0, 20, 5)} />
        </div>
      </div>
    </BlockUi>
  );
};

export default React.memo(CompressorCurrentChart);
